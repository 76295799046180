.side-bar {
  width: 100%;
  max-width: 40rem;
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 20;
  transform: translateX(100%);
  transition: transform 0.4s var(--easing);
  display: grid;
  grid-template-columns: 99fr 1fr;
  grid-template-rows: min-content auto min-content;
  grid-template-areas:
    "heading close"
    "content content"
    "footer footer";
  height: 100vh;
  align-items: center;
  .side-bar--heading {
    grid-area: heading;
    padding: var(--padding);
    margin: 0;
    * {
      margin: 0;
    }
    h2 {
      font-size: 2rem;
    }
  }
  .side-bar--close {
    grid-area: close;
    width: min-content;
    padding: var(--padding);
  }
  .side-bar--content {
    grid-area: content;
    overflow: auto;
    height: 100%;
    padding-left: var(--padding);
    padding-right: var(--padding);
    padding-bottom: var(--padding);
  }
  .side-bar--content__submitting {
    pointer-events: none;
  }
  .side-bar--footer-buttons {
    grid-area: footer;
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    padding: var(--padding);
    height: min-content;
    background-color: var(--colour-primary-faded-more);
    .button {
      font-size: 1.1rem;
      min-width: 10rem;
      justify-content: center;
    }
  }
  .sidebar-form--section {
    padding: var(--padding);
  }
}
.side-bar.active {
  transform: translateX(0);
}
