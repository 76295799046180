@font-face {
  font-family: Nunito;
  src: local("Nunito Regular"), url(../resources/fonts/Nunito-VariableFont_wght.ttf);
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Nunito;
  src: local("Nunito Bold"), url(../resources/fonts/Nunito-VariableFont_wght.ttf);
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: Nunito;
  src: local("Nunito Italic"), url(../resources/fonts/Nunito-Italic-VariableFont_wght.ttf);
  font-style: italic;
  font-display: swap;
}

:root {
  font-family: Nunito, sans-serif;
  font-size: var(--font-size);
  font-size: clamp(var(--font-size), 50% + 0.4vw, 130%);
  color: var(--colour-content-text);
  line-height: 1.2;
  @media (max-width: $breakpoint-tablet) {
    font-size: 100%;
  }
}
* {
  box-sizing: border-box;
}
// *:focus {
//   outline: 20px solid purple !important;
//   z-index: 9999999999999 !important;
//   overflow: visible !important;
//   position: absolute !important;
// }

html,
body {
  height: 100%;
  * {
    min-width: 0;
  }
}
html.theme-dark {
  --colour-secondary: #cccccc;
}

body {
  margin: 0;
  padding: 0 0 0 0;
  background-color: var(--colour-background);
}

.display-none {
  display: none !important;
}
.tablet-only {
  @media (min-width: $breakpoint-tablet) {
    display: none !important;
  }
}
.app-only {
  display: none;
}
body.app {
  .app-only {
    display: block !important;
  }
}

.mobile-hide {
  @media (max-width: $breakpoint-tablet) {
    display: none !important;
  }
}

.diff--added {
  background: #7eba7e;
  color: #fff;
}
.diff--removed {
  background: #f7816c;
  color: #fff;
}
.flex {
  .diff--added,
  .diff--removed {
    padding: 4px;
    border-radius: var(--border-radius-small);
  }
}

#server-down {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--colour-buttons);
  color: var(--colour-buttons-text);
  z-index: 9999999999999;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  text-align: center;
}

h1,
.h1 {
  font-size: 2.67rem;
  font-weight: normal;
  color: var(--colour-content-headings);
  margin: 0 0 2.67rem 0;
  position: relative;
  @media (max-width: $breakpoint-medium) {
    font-size: 2rem;
  }
}
h1 {
  padding-bottom: 1.1rem;
}
h1:first-child::before {
  content: " ";
  width: 5rem;
  background-color: var(--colour-content-headings-line);
  height: 0.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
}
.h1__skeleton::before {
  display: none;
}

/* hide titles in app */
body.app {
  h1 {
    display: none !important;
  }
  .title-container-flex:has(> *:only-child),
  .title-container-flex:has(.title-container-flex--items:empty) {
    display: none;
  }
  .table + .title-container-flex,
  .table + .title-container-flex > h1,
  div.reports-thumbs + .title-container-flex,
  div.reports-thumbs + .title-container-flex > h1 {
    display: block !important;
    text-align: center !important;
    padding: 0;
    margin: 1rem auto !important;
    font-size: 1.5rem;
  }
  .table + .title-container-flex > h1::before,
  div.reports-thumbs + .title-container-flex > h1::before {
    display: none;
  }
}

// h1 > span:first-child::before {
//   background-color: rgba(0, 0, 0, 0.03);
//   border-radius: 0.25rem;
// }

/* legacy font styles, needed for importing html from old site */
.emphasisedtext {
  background-color: var(--colour-primary-against-white);
  font-weight: bold;
}
.texthighlight {
  background-color: var(--colour-primary-against-white);
  color: var(--colour-primary-against-white-text);
}
.largetext {
  font-size: 1.3rem;
}
.smalltext {
  font-size: 0.8rem;
}
.text-missing {
  background: var(--colour-rejected);
  color: #fff;
  padding: 0 0.25rem;
}

body.app {
  .title-container-flex {
    margin-top: 0;
    padding: 0 0 var(--padding) 0;
    padding-top: 0 !important;
    justify-content: space-between !important;

    .title-container-flex--items {
      justify-content: center;
      svg {
        display: block;
      }
      button.button {
        padding: 0.5rem;
        font-size: 1rem;
      }
    }
  }
}

.title-container-flex {
  display: flex;
  justify-content: space-between;
  align-items: center; //flex-start;
  padding: 0 0 1.57rem 0;
  column-gap: var(--padding);
  flex-wrap: wrap;
  row-gap: 1rem;
  margin-top: -1.1rem;

  @media (max-width: $breakpoint-medium) {
    justify-content: center;
  }

  .title-container-flex--items {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    column-gap: 1rem; //var(--padding);
    .button {
      padding: 0.25rem 0.7rem;
    }
    .select {
      min-width: 10rem;
      position: relative;
      z-index: 5;
    }
    .select__control {
      min-height: 100%;
    }
    > * {
      flex-shrink: 1;
    }
    .title-container-flex--item__grow {
      flex-shrink: 0;
      flex-grow: 1;
      flex-basis: max-content;
    }
    @media (max-width: $breakpoint-medium) {
      flex-wrap: wrap;
      row-gap: var(--padding);
      justify-content: center;
    }
  }
  .title-container-flex--items[data-disabled="true"] {
    opacity: 0.5;
    pointer-events: none;
  }
  .title-container-flex--items__grow {
    flex-grow: 1;
  }

  h1 {
    margin-bottom: 0;
    flex-shrink: 1;
    width: fit-content;
    padding-top: 1.1rem;
    animation: text-slide-in 0.6s forwards;
    display: inline-flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: $breakpoint-medium) {
      justify-self: center;
      animation: none;
      align-self: center;
      justify-content: center;
    }

    .title-profile-pic {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
    }
  }
  .title-help {
    margin-right: auto;
    flex-grow: 1;
    flex-shrink: 1;
    animation: pulsate-fwd 0.6s forwards;
    width: min-content;
    @media (max-width: $breakpoint-medium) {
      margin-right: 0;
      flex-grow: 0;
    }
  }
}

body.app .title-container-flex {
  .title-help {
    display: none;
  }
  .title-container-flex--items {
    align-items: center;
    width: 100%;
    gap: var(--padding-tight);
    .select {
      font-size: 0.8rem;
      flex-grow: 1;
    }
  }
}
body.app .title-container-flex .title-container-flex__sticky {
  position: sticky;
  top: -1.1rem;
  z-index: 2;
  background-color: var(--colour-background);
  // h1 {
  //   padding-bottom: 0;
  // }
  h1::before {
    display: none;
  }
}

.title-container-flex:not(:first-of-type) {
  margin-top: calc(2 * var(--padding));
}

.title-action-buttons {
  display: flex;
  gap: 0.5rem;
}
.title-action-buttons[data-loading="true"] {
  opacity: 0.5;
  pointer-events: none;
}

h2,
.h2 {
  font-size: 1.33rem;
  font-weight: normal;
  margin: 0 0 1rem 0;
  label {
    display: flex;
    gap: var(--padding-tight);
    width: fit-content;
  }
}

h3,
.h3 {
  font-size: 1.47rem;
}
p {
  margin-top: 1rem;
  margin-bottom: 1.2rem;
}
p:first-child {
  margin-top: 0 !important;
}
p:last-child {
  margin-bottom: 0 !important;
}
p:only-of-type {
  margin: 0 !important;
}

a,
button.button-link {
  font: inherit;
  color: var(--colour-links);
  text-decoration: underline;
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  align-items: center;
  > .icon,
  > .icon-small,
  > .icon > svg,
  > .icon-small {
    svg * {
      fill: var(--colour-links);
    }
  }
}
button.button-link {
  display: flex;
  text-align: inherit;
  * {
    color: var(--colour-links);
  }
}
button.button-link__signout {
  transform: scale(-1);
  path:first-child {
    transform: scale(-1);
    transform-origin: 78% 50%;
  }
}
a:hover,
button.button-link:hover {
  filter: brightness(1.2);
}
a:focus,
button.button-link:focus {
  outline: 1px dotted var(--colour-secondary-faded);
  outline-offset: 0.25rem;
}
button.button-link:disabled {
  opacity: 0.5;
  pointer-events: none;
}
// :root.theme-dark a:hover {
//   filter: brightness(0.8);
// }

hr {
  height: 1px;
  margin: 1rem 0;
  padding: 0;
  border: 0;
  border-top: 2px solid var(--colour-borders);
  color: transparent;
  background: transparent;
}

svg {
  overflow: visible;
}

.icon,
.icon > svg {
  width: 100%;
  min-width: 0.7rem;
  min-height: 0.7rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
}
.icon-small,
.icon-small > svg {
  width: 0.5rem;
}
.modal-bg {
  z-index: 9999999999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9999999999999;
  padding: calc(var(--padding) * 2 + 3.5rem) var(--padding) var(--padding) var(--padding);
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade-in 1s;
  color: #fff;
  > *:not(.close-button, .throbber, .modal-toolbar) {
    max-width: 100%;
    max-height: 100%;
  }
  > .modal-toolbar {
    position: absolute;
    top: var(--padding);
    left: var(--padding);
    height: 3.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    min-width: 10rem;
    .spinner {
      div {
        background: #fff;
      }
    }
  }
  > .throbber {
    width: 25%;
    max-width: 15rem;
    .throbber-triangle:first-of-type {
      background-color: var(--colour-primary-against-black);
    }
    .throbber-triangle:last-of-type {
      background-color: var(--colour-secondary-against-black);
    }
  }
  > .placeholder-image {
    max-width: 50%;
  }
  > .close-button {
    position: absolute;
    top: var(--padding);
    right: var(--padding);
  }
}
.svg-fill-primary {
  fill: var(--colour-primary) !important;
}
.svg-fill-secondary {
  fill: var(--colour-secondary) !important;
}
.svg-fill-grey {
  fill: var(--colour-primary) !important;
  filter: grayscale(1);
}
.svg-fill-light-grey {
  fill: var(--colour-borders) !important;
}
.svg-fill-bg {
  fill: var(--colour-content-background) !important;
}

.error-message.active {
  transform: translateY(0%);
}

.error-message {
  position: fixed;
  background: #d94e4e;
  color: #fff;
  bottom: 0;
  left: 0;
  padding: 2rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  z-index: 1500;
  width: 100%;
  transform: translateY(101%);
  transition: transform 0.3s var(--easing);
}
.fetch-error {
  position: absolute;
  z-index: 10;
  width: 33%;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2rem;
  .fetch-error--image {
    width: 90%;
    height: auto;
    animation: float 1s infinite ease-in-out alternate;
  }
  .fetch-error--status {
    font-weight: bold;
    color: var(--colour-content-headings);
    font-size: 1.5rem;
  }
  .fetch-error--error {
    font-weight: bold;
    color: var(--colour-content-text);
    font-size: 1.5rem;
    text-align: center;
  }
  .fetch-error--contact-us {
    text-align: center;
  }
  @media (max-width: $breakpoint-medium) {
    position: static;
    margin: 2rem auto;
    transform: none;
  }
}

svg *.fill-primary {
  fill: var(--colour-primary) !important;
}
svg *.fill-primary-faded {
  fill: var(--colour-primary-faded) !important;
}
svg *.stroke-outline {
  stroke: var(--colour-content-text) !important;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.action-icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > * {
    padding: 0.4rem !important;
    display: inline-flex;
    align-items: center;
  }
  > .button {
    margin-left: 0.4rem;
  }
}
.preview-panel {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: transform 1s var(--easing);
  background: var(--colour-background);
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: min-content auto;
  transform: translateX(100vw);
  z-index: 999999;
  .preview-panel--header {
    background-color: #000;
    color: #fff;
    padding: var(--padding-tight);
    border-bottom: 1px solid #fff;
  }
  .preview-panel--iframe {
    width: 100%;
    height: 100%;
    border: 0;
    overflow: auto;
    background: transparent;
  }
}
.preview-panel.active {
  transform: translateX(0);
}

.data-table {
  margin: var(--padding) 0;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  justify-content: start;
  align-items: start;
  gap: var(--padding);
  .data-table--label {
    font-weight: bold;
  }
  .data-table--divide {
    grid-column: 1 / span 2;
  }
  .data-table--divide:last-child {
    display: none;
  }
}

.editable-text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 0.5rem;
  position: relative;
  > span {
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 0;
  }
  > input {
    width: 100%;
    text-align: center;
  }
  > svg {
    transition: all 0.2s ease;
    opacity: 0;
    width: calc(16px + 0.5rem);
    height: calc(16px + 0.5rem);
    top: 0;
    right: 0;
    flex-shrink: 0;
    position: absolute;
    background: var(--colour-background);
    padding: 0.25rem;
    pointer-events: none;
  }
}
.editable-text:hover {
  > svg {
    opacity: 1;
  }
  > span {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dotted;
  }
}

.edit-icons {
  background-color: var(--colour-secondary-against-white);
  color: var(--colour-secondary-against-white-text);
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  > button,
  > .button-link,
  > * {
    color: var(--colour-secondary-against-white-text);
  }
}
.edit-icons--hoverable {
  opacity: 0;
  cursor: default;
  transition: all 0.2s ease-out;
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
}

.file-drop-target {
  outline: 2px dashed transparent;
}
.file-drop-target__accepted {
  outline-color: var(--colour-accepted);
  background-color: var(--colour-accepted-faded);
  * {
    opacity: 0.5;
  }
}
.file-drop-target__rejected {
  outline-color: var(--colour-rejected);
  background-color: var(--colour-rejected-faded);
  * {
    opacity: 0.5;
  }
}

.admin-menus-categories {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  column-gap: 1rem;
  margin-bottom: var(--padding);
  .admin-menus-category {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--colour-primary-against-white);
    color: var(--colour-primary-against-white-text);
    border-radius: var(--border-radius-small);
    padding: 0.5rem;
    column-gap: 0.25rem;
    cursor: default;
    transition: all 0.2s ease-out;
  }
  .admin-menus-category:hover {
    filter: brightness(1.2);
  }
  .admin-menus-category.active {
    outline: 0.2rem solid var(--colour-content-background);
    box-shadow: 0 0 0 0.35rem var(--colour-buttons);
  }
  .admin-menus-category--edit-icons {
    padding-right: 1rem;
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
  }
  .admin-menus-category--edit-icons:last-child {
    padding-right: 0;
  }
  > *:nth-last-child(2) {
    flex-grow: 1;
  }
  > .button__arrow {
    align-self: center;
  }
}
.admin-menus-selection-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  justify-content: space-between;
  .select {
    flex-grow: 1;
  }
  .admin-menus-selection-container--icons {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }
}
.admin-menus-menu-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 1rem;
  > * {
    flex-shrink: 1;
  }
}

.p-dialog-mask {
  z-index: 1103;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .p-dialog-header,
  .p-resizable-handle {
    display: none;
  }
  .confirm-dialog {
    width: 90%;
    max-width: 40rem;
    background: var(--colour-content-background);
    color: var(--colour-content-text);
    border-radius: var(--border-radius-small);
    padding: var(--padding);
    display: flex;
    flex-direction: column;
    row-gap: var(--padding);
    box-shadow: var(--shadow);
    animation: scale-in-center 0.2s forwards;
    .confirm-inputs {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    input {
      width: 100%;
      display: block;
    }
  }
  .p-dialog-footer {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    column-gap: var(--padding);
    flex-direction: row-reverse;
  }
}

.button.button__treestyle {
  font-size: 1rem;
  padding: 1.25rem;
  min-width: 20rem;
}
.draggable-tree {
  border: 1px solid var(--colour-borders);
  width: fit-content;
  min-width: 20rem;
  border-radius: var(--border-radius-small);
  box-shadow: var(--shadow);
  background-color: var(--colour-primary-against-white);
  color: var(--colour-primary-against-white-text);
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 0 1rem;
    li {
      margin: 0.25rem 0 0.25rem 0;
    }
  }
  > ul {
    margin-left: 0;
  }
  .p-treenode-content {
    min-width: 10rem;
    line-height: 2.5;
    border-radius: var(--border-radius-small);
    padding-left: 0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    .tree-node-template {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      > * {
        flex-shrink: 1;
        opacity: 0.5;
        > * {
          display: flex;
          align-items: center;
        }
      }
      > *:first-child {
        flex-grow: 1;
        flex-shrink: 0;
        opacity: 1;
        padding-right: 1rem;
      }
      > *:last-child {
        margin-right: 1.5rem;
      }
      .button-link {
        color: var(--colour-primary-against-white-text);
        width: auto;
      }
      .button {
        width: min-content;
      }
      button.button-link * {
        color: var(--colour-primary-against-white-text);
      }
    }
    .tree-node-template.tree-heading {
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
  .p-treenode-content:hover {
    background-color: rgba(255, 255, 255, 0.1);
    .tree-node-template {
      > * {
        opacity: 1;
      }
    }
  }
  .p-treenode-droppoint {
    height: 0.5rem;
  }
  .p-treenode-droppoint-active {
    border-bottom: 0.2rem dashed var(--colour-primary-against-white-text);
  }
  .p-treenode-dragover {
    filter: brightness(1.2);
    outline: 1px solid var(--colour-primary-against-white-text);
  }
  .p-treenode-leaf > .p-treenode-content .p-tree-toggler {
    visibility: hidden;
  }
  .p-tree-toggler {
    margin-right: 0.5rem;
  }
  .p-tree-toggler.p-link {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--colour-primary-against-white-text);
    border: none;
    border-radius: 50%;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    top: -0.15rem;
    display: inline-block;
  }
  .p-tree-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--colour-primary-against-white-text);
  }
  .p-tree-toggler-icon::before {
    content: url("data:image/svg+xml,%3Csvg src='arrow-down' class='svg icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.457 10.383'%3E%3Cpath id='Path_10218' data-name='Path 10218' d='M198.229,14.383a1.712,1.712,0,0,0,1.223-.511l7.669-7.881a1.175,1.175,0,0,0,0-1.648,1.146,1.146,0,0,0-1.631,0l-7.261,7.471-7.259-7.471a1.142,1.142,0,0,0-1.631,0,1.175,1.175,0,0,0,0,1.648L197,13.872a1.71,1.71,0,0,0,1.223.511Z' transform='translate(-189 -4)' fill='%23fff' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
    width: 100%;
    height: 100%;
    display: block;
  }
  .pi-chevron-down::before {
    transform: scaleY(-1);
  }
}

.notice {
  margin-bottom: var(--padding);
  background: var(--colour-primary-against-white);
  color: var(--colour-primary-against-white-text);
  font-size: 1.35rem;
  padding: var(--padding);
  border-radius: var(--border-radius-inner);
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: var(--padding);
  height: min-content;
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.notice__invert {
  background: var(--colour-content-background);
  color: var(--colour-primary-against-white);
  border: 0.25rem solid var(--colour-primary-against-white);
}
.placeholder-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  justify-content: center;
  max-width: 700px;
  width: 100%;
  margin: var(--padding) auto;
  @media (max-width: $breakpoint-tablet) {
    display: none;
  }
  .placeholder-image--caption {
    font-size: 1.3rem;
    text-align: center;
  }
}

.details-multi {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: var(--padding);
  .details {
    border: 1px solid var(--colour-borders);
    border-radius: var(--border-radius-inner);
    overflow: hidden;
    summary {
      padding: var(--padding) var(--padding) var(--padding) calc(var(--padding) + 1.5rem);
      cursor: pointer;
      background-color: var(--colour-secondary-faded-more);
      position: relative;
      list-style: none;
      font-size: 1.2rem;
    }
    summary:hover {
      font-weight: bold;
    }
    summary::-webkit-details-marker {
      display: none;
    }
    summary::before {
      content: "";
      border-width: 0.4rem;
      border-style: solid;
      border-color: transparent transparent transparent var(--colour-content-text);
      position: absolute;
      top: calc(var(--padding) + 0.15rem);
      left: var(--padding);
      transform: rotate(0);
      transform-origin: 0.2rem 50%;
      transition: 0.25s transform ease;
    }
    .detailsContent {
      padding: var(--padding);
      animation: none;
    }
    .detailsGridButtons {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: var(--padding-tight);
    }
  }
  .details[open] > summary::before {
    transform: rotate(90deg);
  }
}

.padded-top {
  padding-top: var(--padding);
}
.padded-top-large {
  padding-top: calc(var(--padding) * 3);
}
.flex {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
}
.flex__wide-first {
  width: 100%;
  justify-content: space-between;
  > * {
    flex-shrink: 1;
  }
  > *:first-child {
    flex-grow: 1;
  }
}
.flex__center {
  justify-content: center;
}
.flex__middle {
  align-items: center;
  h2 {
    margin: 0;
  }
}
.flex__right {
  justify-content: flex-end;
}
.flex__mb {
  margin-bottom: var(--padding);
}
.text-small {
  font-size: 0.9rem !important;
}
.ProseMirror {
  a[name]:not([href]) {
    display: inline-block;
    position: static;
    width: 100%;
    height: auto;
    border: 1px dashed var(--colour-borders);
    margin: 0;
    padding: 0;
    transition: all 0.2s ease-out;
    cursor: default;
    font: inherit;
    text-decoration: none;
    color: inherit;
  }
  a[name]:not([href]):hover {
    filter: none;
  }
  a[name]:not([href]).ProseMirror-selectednode {
    border-color: var(--colour-primary-against-white);
  }
  a[name]:not([href])::before {
    content: "🚩 " attr(title);
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem;
  }
}
a[name]:not([href]) {
  width: 0;
  height: 0;
  display: inline;
  overflow: hidden;
  position: absolute;
}

.user-symbol {
  display: inline-block;
  font-size: 0.8rem;
  position: absolute;
  padding: 0;
  font-weight: bold;
  line-height: 1;
  transform: translate3d(0.5rem, 0.25rem, 0);
  cursor: default;
}
