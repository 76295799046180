[data-simplebar] {
  flex-flow: column wrap;
  place-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.simplebar-wrapper {
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
  overflow: hidden;
}

.simplebar-mask {
  direction: inherit;
  z-index: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: auto !important;
  height: auto !important;
}

.simplebar-offset {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
}

.simplebar-content-wrapper {
  direction: inherit;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: auto;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  display: block;
  position: relative;
  box-sizing: border-box !important;
}

.simplebar-content-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.simplebar-content:before, .simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  pointer-events: none;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.simplebar-height-auto-observer-wrapper {
  float: left;
  z-index: -1;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
  width: 100%;
  max-width: 1px;
  height: 100%;
  max-height: 1px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  box-sizing: inherit !important;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  width: 1000%;
  min-width: 1px;
  height: 1000%;
  min-height: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.simplebar-track {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  min-height: 10px;
  position: absolute;
  left: 0;
  right: 0;
}

.simplebar-scrollbar:before {
  content: "";
  opacity: 0;
  background: #000;
  border-radius: 7px;
  transition: opacity .2s linear;
  position: absolute;
  left: 2px;
  right: 2px;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: .5;
  transition: opacity linear;
}

.simplebar-track.simplebar-vertical {
  width: 11px;
  top: 0;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  height: 11px;
  left: 0;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  width: auto;
  min-width: 10px;
  height: 7px;
  min-height: 0;
  top: 2px;
  left: 0;
  right: auto;
}

[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  left: 0;
  right: auto;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  opacity: 0;
  visibility: hidden;
  width: 500px;
  height: 500px;
  position: fixed;
  overflow: scroll hidden;
}

.simplebar-hide-scrollbar {
  visibility: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: fixed;
  left: 0;
  overflow-y: scroll;
}

#print-container, .print-only {
  display: none;
}

@media print {
  body.print-mode {
    height: -moz-fit-content;
    height: fit-content;
  }

  body.print-mode #root, body.print-mode #root * {
    display: none !important;
  }

  #print-container {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0;
    display: block;
  }

  #print-container * {
    color: #000;
  }

  #print-container h1 {
    color: #000;
    text-align: center;
    border: 0;
    margin: .5cm 0;
    padding: 0;
    font-size: 1.7rem;
  }

  #print-container h1:after, #print-container h1:before {
    display: none;
  }

  #print-container .print-only {
    display: block;
  }
}

:root {
  --colour-primary: #ccc;
  --colour-secondary: #666;
  --colour-primary-faded: #dfdfdf;
  --colour-primary-faded-more: #bfbfbf4d;
  --colour-secondary-faded: #9f9f9f;
  --colour-secondary-faded-more: #9f9f9f4d;
  --colour-primary-text: #000000b3;
  --colour-primary-faded-text: #000000b3;
  --colour-secondary-text: #fff;
  --colour-secondary-faded-text: #fff;
  --colour-primary-against-white: #999;
  --colour-secondary-against-white: #666;
  --colour-primary-against-black: #ccc;
  --colour-secondary-against-black: #666;
  --colour-primary-against-white-high-contrast: #141414;
  --colour-secondary-against-white-high-contrast: #575757;
  --colour-primary-against-black-high-contrast: #ccc-high-contrast;
  --colour-secondary-against-black-high-contrast: #a8a8a8;
  --colour-primary-against-white-text: #fff;
  --colour-secondary-against-white-text: #fff;
  --colour-primary-against-white-text-high-contrast: #fff;
  --colour-secondary-against-white-text-high-contrast: #fff;
  --colour-primary-against-black-text: #000000b3;
  --colour-secondary-against-black-text: #fff;
  --colour-primary-against-black-text-high-contrast: #000000b3;
  --colour-secondary-against-black-text-high-contrast: #000000b3;
  --colour-background: #f8fafc;
  --colour-input-background: #0000000f;
  --colour-input-header-background: #fff;
  --colour-input: var(--colour-text);
  --colour-buttons: var(--colour-secondary-against-white);
  --colour-buttons-text: var(--colour-secondary-against-white-text);
  --colour-buttons-alt: var(--colour-primary-against-white);
  --colour-buttons-alt-text: var(--colour-primary-against-white-text);
  --colour-links: #999;
  --colour-borders: #d5d5d5;
  --colour-content-background: #fff;
  --colour-content-text: #4c4d61;
  --colour-content-headings: var(--colour-secondary-against-white);
  --colour-content-headings-line: var(--colour-primary);
  --colour-home-button-background: #fff;
  --colour-home-button-text: var(--colour-primary-against-white);
  --colour-header-primary: var(--colour-primary-against-white);
  --colour-header-secondary: var(--colour-secondary-against-white);
  --colour-header-secondary-text: var(--colour-secondary-against-white-text);
  --colour-header-input-borders: #fff0;
  --colour-help-button-text: var(--colour-primary-text);
  --colour-search-icon: var(--colour-primary-against-white);
  --colour-toggle: var(--colour-secondary-against-white);
  --colour-table-row-odd: #00000013;
  --colour-table-row-even: #00000006;
  --colour-table-header: #00000026;
  --colour-page-standard: #fff0;
  --colour-page-non-standard: #c0e1ff;
  --colour-page-client: #d1b0e9;
  --colour-accepted: #58d47e;
  --colour-accepted-faded: #58d47e66;
  --colour-rejected: #ea7677;
  --colour-rejected-faded: #ea767766;
  --font-size: clamp(11px, 2vh, 15px);
  --font-size-small: .7rem;
  --border-radius-outer: 1rem;
  --border-radius-inner: .8rem;
  --border-radius-small: .4rem;
  --padding: clamp(1rem, 2vw, 1.5rem);
  --padding-tight: clamp(.5rem, 1.5vw, .75rem);
  --padding-large: 3.9rem;
  --mobile-section-gap: 2rem;
  --icon-size: clamp(.7rem, 100%, 1.5rem);
  --shadow: 0 .25rem .5rem #0003;
  --skeleton: #ebebeb;
  --skeleton-highlight: #f5f5f5;
  --easing: cubic-bezier(.075, .82, .165, 1);
  --throbber-easing: cubic-bezier(.785, .135, .15, .86);
  --colour-absence-holiday: #a8e6a8 !important;
  --colour-absence-sickness: #fd8c8c !important;
  --colour-absence-family: #d1b0e9 !important;
  --colour-absence-other: #c0e1ff !important;
  --colour-absence-rejected: #eaeaea !important;
}

:root.theme-high-contrast {
  --colour-primary: #141414;
  --colour-secondary: #141414;
  --colour-primary-faded: #6b6b6b;
  --colour-primary-text: #fff;
  --colour-primary-faded-text: #fff;
  --colour-secondary-text: #fff;
  --colour-secondary-faded: #6b6b6b;
  --colour-secondary-faded-text: #fff;
  --colour-primary-against-white: #141414;
  --colour-primary-against-black: #a8a8a8;
  --colour-secondary-against-white: #575757;
  --colour-secondary-against-black: #a8a8a8;
  --colour-primary-against-white-text: #fff;
  --colour-primary-against-black-text: #000000b3;
  --colour-header-secondary: #575757;
  --colour-header-secondary-text: #fff;
  --colour-links: #575757;
  --colour-content-text: #000;
  --colour-buttons: #575757;
  --colour-buttons-text: #fff;
  --colour-buttons-alt: #141414;
  --colour-buttons-alt-text: #fff;
  --colour-borders: #000;
  --shadow: none;
}

:root.theme-dark {
  --colour-primary: #666;
  --colour-primary-faded: #333;
  --colour-primary-faded-more: #666;
  --colour-secondary-faded: #1a1a1a;
  --colour-secondary-faded-more: #333;
  --colour-background: #1a1a1a;
  --colour-content-background: #000;
  --colour-content-text: #fffc;
  --colour-content-headings: var(--colour-secondary-against-black);
  --colour-content-headings-line: var(--colour-primary-against-black);
  --colour-secondary: #333;
  --colour-primary-text: #fff;
  --colour-primary-faded-text: #fff;
  --colour-secondary-text: #fff;
  --colour-secondary-faded-text: #fff;
  --colour-input-background: #fff3;
  --colour-input-header-background: #000;
  --colour-input: #fff;
  --colour-buttons: var(--colour-secondary-against-black);
  --colour-buttons-text: var(--colour-secondary-against-black-text);
  --colour-buttons-alt: var(--colour-primary-against-black);
  --colour-buttons-alt-text: var(--colour-primary-against-black-text);
  --colour-links: #ccc;
  --colour-home-button-background: #000;
  --colour-home-button-text: var(--colour-primary-against-black);
  --colour-borders: #ffffff26;
  --colour-header-primary: var(--colour-primary-against-black);
  --colour-header-secondary: var(--colour-secondary-against-black);
  --colour-header-secondary-text: var(--colour-secondary-against-black-text);
  --colour-header-input-borders: #ffffff4d;
  --colour-help-button-text: var(--colour-primary-against-black);
  --colour-search-icon: var(--colour-primary-against-black);
  --colour-toggle: var(--colour-secondary-against-black);
  --shadow: none;
  --skeleton: #1a1a1a;
  --skeleton-highlight: #111;
}

:root.theme-dark.theme-high-contrast {
  --colour-primary: #0a0a0a;
  --colour-secondary: #0a0a0a;
  --colour-primary-text: #fff;
  --colour-primary-faded-text: #fff;
  --colour-secondary-text: #fff;
  --colour-secondary-faded-text: #fff;
  --colour-content-text: #fff;
  --colour-links: #ccc;
  --colour-buttons: var(--colour-secondary-against-black-high-contrast);
  --colour-buttons-text: var(--colour-secondary-against-black-text-high-contrast);
  --colour-buttons-alt: var(--colour-primary-against-black-high-contrast);
  --colour-buttons-alt-text: var(--colour-primary-against-black-text-high-contrast);
  --colour-header-secondary: var(--colour-secondary-against-black-high-contrast);
  --colour-header-secondary-text: var(--colour-secondary-against-black-text-high-contrast);
  --colour-borders: #fff;
  --shadow: none;
}

:root.theme-large-text {
  --font-size: 15pt;
}

body.app {
  --padding: 1rem;
  --padding-tight: .5rem;
}

@font-face {
  font-family: Nunito;
  src: local(Nunito Regular), url("Nunito-VariableFont_wght.b8e8deb5.ttf");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Nunito;
  src: local(Nunito Bold), url("Nunito-VariableFont_wght.b8e8deb5.ttf");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: Nunito;
  src: local(Nunito Italic), url("Nunito-Italic-VariableFont_wght.20daa389.ttf");
  font-style: italic;
  font-display: swap;
}

:root {
  font-family: Nunito, sans-serif;
  font-size: var(--font-size);
  font-size: clamp(var(--font-size), 50% + .4vw, 130%);
  color: var(--colour-content-text);
  line-height: 1.2;
}

@media (max-width: 1000px) {
  :root {
    font-size: 100%;
  }
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

html *, body * {
  min-width: 0;
}

html.theme-dark {
  --colour-secondary: #ccc;
}

body {
  background-color: var(--colour-background);
  margin: 0;
  padding: 0;
}

.display-none {
  display: none !important;
}

@media (min-width: 1000px) {
  .tablet-only {
    display: none !important;
  }
}

.app-only {
  display: none;
}

body.app .app-only {
  display: block !important;
}

@media (max-width: 1000px) {
  .mobile-hide {
    display: none !important;
  }
}

.diff--added {
  color: #fff;
  background: #7eba7e;
}

.diff--removed {
  color: #fff;
  background: #f7816c;
}

.flex .diff--added, .flex .diff--removed {
  border-radius: var(--border-radius-small);
  padding: 4px;
}

#server-down {
  background: var(--colour-buttons);
  color: var(--colour-buttons-text);
  z-index: 2147483647;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-size: 2rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

h1, .h1 {
  color: var(--colour-content-headings);
  margin: 0 0 2.67rem;
  font-size: 2.67rem;
  font-weight: normal;
  position: relative;
}

@media (max-width: 1200px) {
  h1, .h1 {
    font-size: 2rem;
  }
}

h1 {
  padding-bottom: 1.1rem;
}

h1:first-child:before {
  content: " ";
  background-color: var(--colour-content-headings-line);
  width: 5rem;
  height: .5rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.h1__skeleton:before {
  display: none;
}

body.app h1 {
  display: none !important;
}

body.app .title-container-flex:has( > :only-child) {
  display: none;
}

body.app .title-container-flex:has(.title-container-flex--items:empty) {
  display: none;
}

body.app .table + .title-container-flex, body.app .table + .title-container-flex > h1, body.app div.reports-thumbs + .title-container-flex, body.app div.reports-thumbs + .title-container-flex > h1 {
  padding: 0;
  font-size: 1.5rem;
  text-align: center !important;
  margin: 1rem auto !important;
  display: block !important;
}

body.app .table + .title-container-flex > h1:before, body.app div.reports-thumbs + .title-container-flex > h1:before {
  display: none;
}

.emphasisedtext {
  background-color: var(--colour-primary-against-white);
  font-weight: bold;
}

.texthighlight {
  background-color: var(--colour-primary-against-white);
  color: var(--colour-primary-against-white-text);
}

.largetext {
  font-size: 1.3rem;
}

.smalltext {
  font-size: .8rem;
}

.text-missing {
  background: var(--colour-rejected);
  color: #fff;
  padding: 0 .25rem;
}

body.app .title-container-flex {
  padding: 0 0 var(--padding) 0;
  margin-top: 0;
  justify-content: space-between !important;
  padding-top: 0 !important;
}

body.app .title-container-flex .title-container-flex--items {
  justify-content: center;
}

body.app .title-container-flex .title-container-flex--items svg {
  display: block;
}

body.app .title-container-flex .title-container-flex--items button.button {
  padding: .5rem;
  font-size: 1rem;
}

.title-container-flex {
  justify-content: space-between;
  align-items: center;
  column-gap: var(--padding);
  flex-wrap: wrap;
  row-gap: 1rem;
  margin-top: -1.1rem;
  padding: 0 0 1.57rem;
  display: flex;
}

@media (max-width: 1200px) {
  .title-container-flex {
    justify-content: center;
  }
}

.title-container-flex .title-container-flex--items {
  justify-content: flex-end;
  align-items: stretch;
  column-gap: 1rem;
  display: flex;
}

.title-container-flex .title-container-flex--items .button {
  padding: .25rem .7rem;
}

.title-container-flex .title-container-flex--items .select {
  z-index: 5;
  min-width: 10rem;
  position: relative;
}

.title-container-flex .title-container-flex--items .select__control {
  min-height: 100%;
}

.title-container-flex .title-container-flex--items > * {
  flex-shrink: 1;
}

.title-container-flex .title-container-flex--items .title-container-flex--item__grow {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: max-content;
}

@media (max-width: 1200px) {
  .title-container-flex .title-container-flex--items {
    row-gap: var(--padding);
    flex-wrap: wrap;
    justify-content: center;
  }
}

.title-container-flex .title-container-flex--items[data-disabled="true"] {
  opacity: .5;
  pointer-events: none;
}

.title-container-flex .title-container-flex--items__grow {
  flex-grow: 1;
}

.title-container-flex h1 {
  flex-shrink: 1;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 0;
  padding-top: 1.1rem;
  animation: .6s forwards text-slide-in;
  display: inline-flex;
}

@media (max-width: 1200px) {
  .title-container-flex h1 {
    justify-content: center;
    place-self: center;
    animation: none;
  }
}

.title-container-flex h1 .title-profile-pic {
  object-fit: cover;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
}

.title-container-flex .title-help {
  flex-grow: 1;
  flex-shrink: 1;
  width: min-content;
  margin-right: auto;
  animation: .6s forwards pulsate-fwd;
}

@media (max-width: 1200px) {
  .title-container-flex .title-help {
    flex-grow: 0;
    margin-right: 0;
  }
}

body.app .title-container-flex .title-help {
  display: none;
}

body.app .title-container-flex .title-container-flex--items {
  align-items: center;
  gap: var(--padding-tight);
  width: 100%;
}

body.app .title-container-flex .title-container-flex--items .select {
  flex-grow: 1;
  font-size: .8rem;
}

body.app .title-container-flex .title-container-flex__sticky {
  z-index: 2;
  background-color: var(--colour-background);
  position: sticky;
  top: -1.1rem;
}

body.app .title-container-flex .title-container-flex__sticky h1:before {
  display: none;
}

.title-container-flex:not(:first-of-type) {
  margin-top: calc(2 * var(--padding));
}

.title-action-buttons {
  gap: .5rem;
  display: flex;
}

.title-action-buttons[data-loading="true"] {
  opacity: .5;
  pointer-events: none;
}

h2, .h2 {
  margin: 0 0 1rem;
  font-size: 1.33rem;
  font-weight: normal;
}

h2 label, .h2 label {
  gap: var(--padding-tight);
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

h3, .h3 {
  font-size: 1.47rem;
}

p {
  margin-top: 1rem;
  margin-bottom: 1.2rem;
}

p:first-child {
  margin-top: 0 !important;
}

p:last-child {
  margin-bottom: 0 !important;
}

p:only-of-type {
  margin: 0 !important;
}

a, button.button-link {
  font: inherit;
  color: var(--colour-links);
  cursor: pointer;
  background: none;
  border: 0;
  align-items: center;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}

a > .icon svg *, a > .icon-small svg *, a > .icon > svg svg *, a > .icon-small svg *, button.button-link > .icon svg *, button.button-link > .icon-small svg *, button.button-link > .icon > svg svg *, button.button-link > .icon-small svg * {
  fill: var(--colour-links);
}

button.button-link {
  text-align: inherit;
  display: flex;
}

button.button-link * {
  color: var(--colour-links);
}

button.button-link__signout {
  transform: scale(-1);
}

button.button-link__signout path:first-child {
  transform-origin: 78%;
  transform: scale(-1);
}

a:hover, button.button-link:hover {
  filter: brightness(1.2);
}

a:focus, button.button-link:focus {
  outline: 1px dotted var(--colour-secondary-faded);
  outline-offset: .25rem;
}

button.button-link:disabled {
  opacity: .5;
  pointer-events: none;
}

hr {
  border: 0;
  border-top: 2px solid var(--colour-borders);
  color: #0000;
  background: none;
  height: 1px;
  margin: 1rem 0;
  padding: 0;
}

svg {
  overflow: visible;
}

.icon, .icon > svg {
  width: 100%;
  min-width: .7rem;
  max-width: 1.5rem;
  min-height: .7rem;
  max-height: 1.5rem;
}

.icon-small, .icon-small > svg {
  width: .5rem;
}

.modal-bg {
  z-index: 2147483647;
  z-index: 2147483647;
  padding: calc(var(--padding) * 2 + 3.5rem) var(--padding) var(--padding) var(--padding);
  color: #fff;
  background: #000000e6;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  animation: 1s fade-in;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-bg > :not(:-webkit-any(.close-button, .throbber, .modal-toolbar)) {
  max-width: 100%;
  max-height: 100%;
}

.modal-bg > :not(:is(.close-button, .throbber, .modal-toolbar)) {
  max-width: 100%;
  max-height: 100%;
}

.modal-bg > .modal-toolbar {
  top: var(--padding);
  left: var(--padding);
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  min-width: 10rem;
  height: 3.5rem;
  display: flex;
  position: absolute;
}

.modal-bg > .modal-toolbar .spinner div {
  background: #fff;
}

.modal-bg > .throbber {
  width: 25%;
  max-width: 15rem;
}

.modal-bg > .throbber .throbber-triangle:first-of-type {
  background-color: var(--colour-primary-against-black);
}

.modal-bg > .throbber .throbber-triangle:last-of-type {
  background-color: var(--colour-secondary-against-black);
}

.modal-bg > .placeholder-image {
  max-width: 50%;
}

.modal-bg > .close-button {
  top: var(--padding);
  right: var(--padding);
  position: absolute;
}

.svg-fill-primary {
  fill: var(--colour-primary) !important;
}

.svg-fill-secondary {
  fill: var(--colour-secondary) !important;
}

.svg-fill-grey {
  filter: grayscale();
  fill: var(--colour-primary) !important;
}

.svg-fill-light-grey {
  fill: var(--colour-borders) !important;
}

.svg-fill-bg {
  fill: var(--colour-content-background) !important;
}

.error-message.active {
  transform: translateY(0%);
}

.error-message {
  color: #fff;
  text-align: center;
  z-index: 1500;
  transition: transform .3s var(--easing);
  background: #d94e4e;
  width: 100%;
  padding: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(101%);
}

.fetch-error {
  z-index: 10;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  width: 33%;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.fetch-error .fetch-error--image {
  width: 90%;
  height: auto;
  animation: 1s ease-in-out infinite alternate float;
}

.fetch-error .fetch-error--status {
  color: var(--colour-content-headings);
  font-size: 1.5rem;
  font-weight: bold;
}

.fetch-error .fetch-error--error {
  color: var(--colour-content-text);
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.fetch-error .fetch-error--contact-us {
  text-align: center;
}

@media (max-width: 1200px) {
  .fetch-error {
    margin: 2rem auto;
    position: static;
    transform: none;
  }
}

svg .fill-primary {
  fill: var(--colour-primary) !important;
}

svg .fill-primary-faded {
  fill: var(--colour-primary-faded) !important;
}

svg .stroke-outline {
  stroke: var(--colour-content-text) !important;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.action-icons {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.action-icons > * {
  align-items: center;
  display: inline-flex;
  padding: .4rem !important;
}

.action-icons > .button {
  margin-left: .4rem;
}

.preview-panel {
  transition: transform 1s var(--easing);
  background: var(--colour-background);
  z-index: 999999;
  grid-template-rows: min-content auto;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translateX(100vw);
}

.preview-panel .preview-panel--header {
  color: #fff;
  padding: var(--padding-tight);
  background-color: #000;
  border-bottom: 1px solid #fff;
}

.preview-panel .preview-panel--iframe {
  background: none;
  border: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.preview-panel.active {
  transform: translateX(0);
}

.data-table {
  margin: var(--padding) 0;
  justify-content: start;
  align-items: start;
  gap: var(--padding);
  grid-template-columns: 1fr 1fr;
  width: 100%;
  display: grid;
}

.data-table .data-table--label {
  font-weight: bold;
}

.data-table .data-table--divide {
  grid-column: 1 / span 2;
}

.data-table .data-table--divide:last-child {
  display: none;
}

.editable-text {
  justify-content: center;
  align-items: flex-start;
  column-gap: .5rem;
  display: flex;
  position: relative;
}

.editable-text > span {
  flex: 1 1 0;
}

.editable-text > input {
  text-align: center;
  width: 100%;
}

.editable-text > svg {
  opacity: 0;
  background: var(--colour-background);
  pointer-events: none;
  flex-shrink: 0;
  width: calc(16px + .5rem);
  height: calc(16px + .5rem);
  padding: .25rem;
  transition: all .2s;
  position: absolute;
  top: 0;
  right: 0;
}

.editable-text:hover > svg {
  opacity: 1;
}

.editable-text:hover > span {
  cursor: pointer;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

.edit-icons {
  background-color: var(--colour-secondary-against-white);
  color: var(--colour-secondary-against-white-text);
  justify-content: center;
  align-items: center;
  gap: .25rem;
  padding: .25rem;
  display: flex;
}

.edit-icons > button, .edit-icons > .button-link, .edit-icons > * {
  color: var(--colour-secondary-against-white-text);
}

.edit-icons--hoverable {
  opacity: 0;
  cursor: default;
  transition: all .2s ease-out;
  position: absolute;
  top: .2rem;
  right: .2rem;
}

.file-drop-target {
  outline: 2px dashed #0000;
}

.file-drop-target__accepted {
  outline-color: var(--colour-accepted);
  background-color: var(--colour-accepted-faded);
}

.file-drop-target__accepted * {
  opacity: .5;
}

.file-drop-target__rejected {
  outline-color: var(--colour-rejected);
  background-color: var(--colour-rejected-faded);
}

.file-drop-target__rejected * {
  opacity: .5;
}

.admin-menus-categories {
  margin-bottom: var(--padding);
  justify-content: space-between;
  align-items: stretch;
  column-gap: 1rem;
  display: flex;
}

.admin-menus-categories .admin-menus-category {
  background-color: var(--colour-primary-against-white);
  color: var(--colour-primary-against-white-text);
  border-radius: var(--border-radius-small);
  cursor: default;
  justify-content: flex-start;
  align-items: center;
  column-gap: .25rem;
  padding: .5rem;
  transition: all .2s ease-out;
  display: flex;
}

.admin-menus-categories .admin-menus-category:hover {
  filter: brightness(1.2);
}

.admin-menus-categories .admin-menus-category.active {
  outline: .2rem solid var(--colour-content-background);
  box-shadow: 0 0 0 .35rem var(--colour-buttons);
}

.admin-menus-categories .admin-menus-category--edit-icons {
  align-items: center;
  column-gap: .25rem;
  padding-right: 1rem;
  display: flex;
}

.admin-menus-categories .admin-menus-category--edit-icons:last-child {
  padding-right: 0;
}

.admin-menus-categories > :nth-last-child(2) {
  flex-grow: 1;
}

.admin-menus-categories > .button__arrow {
  align-self: center;
}

.admin-menus-selection-container {
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  display: flex;
}

.admin-menus-selection-container .select {
  flex-grow: 1;
}

.admin-menus-selection-container .admin-menus-selection-container--icons {
  flex-shrink: 1;
  align-items: center;
  column-gap: .5rem;
  display: flex;
}

.admin-menus-menu-container {
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 1rem;
  display: flex;
}

.admin-menus-menu-container > * {
  flex-shrink: 1;
}

.p-dialog-mask {
  z-index: 1103;
  background: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.p-dialog-mask .p-dialog-header, .p-dialog-mask .p-resizable-handle {
  display: none;
}

.p-dialog-mask .confirm-dialog {
  background: var(--colour-content-background);
  color: var(--colour-content-text);
  border-radius: var(--border-radius-small);
  padding: var(--padding);
  row-gap: var(--padding);
  box-shadow: var(--shadow);
  flex-direction: column;
  width: 90%;
  max-width: 40rem;
  animation: .2s forwards scale-in-center;
  display: flex;
}

.p-dialog-mask .confirm-dialog .confirm-inputs {
  flex-direction: column;
  row-gap: 1rem;
  display: flex;
}

.p-dialog-mask .confirm-dialog input {
  width: 100%;
  display: block;
}

.p-dialog-mask .p-dialog-footer {
  justify-content: flex-start;
  align-items: stretch;
  column-gap: var(--padding);
  flex-direction: row-reverse;
  display: flex;
}

.button.button__treestyle {
  min-width: 20rem;
  padding: 1.25rem;
  font-size: 1rem;
}

.draggable-tree {
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-small);
  box-shadow: var(--shadow);
  background-color: var(--colour-primary-against-white);
  color: var(--colour-primary-against-white-text);
  width: -moz-fit-content;
  width: fit-content;
  min-width: 20rem;
  padding: .5rem 1.5rem;
}

.draggable-tree ul {
  margin: 0 0 0 1rem;
  padding: 0;
  list-style: none;
}

.draggable-tree ul li {
  margin: .25rem 0;
}

.draggable-tree > ul {
  margin-left: 0;
}

.draggable-tree .p-treenode-content {
  border-radius: var(--border-radius-small);
  justify-content: space-between;
  align-items: center;
  min-width: 10rem;
  padding-left: .25rem;
  font-weight: normal;
  line-height: 2.5;
  display: flex;
}

.draggable-tree .p-treenode-content .tree-node-template {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.draggable-tree .p-treenode-content .tree-node-template > * {
  opacity: .5;
  flex-shrink: 1;
}

.draggable-tree .p-treenode-content .tree-node-template > * > * {
  align-items: center;
  display: flex;
}

.draggable-tree .p-treenode-content .tree-node-template > :first-child {
  opacity: 1;
  flex-grow: 1;
  flex-shrink: 0;
  padding-right: 1rem;
}

.draggable-tree .p-treenode-content .tree-node-template > :last-child {
  margin-right: 1.5rem;
}

.draggable-tree .p-treenode-content .tree-node-template .button-link {
  color: var(--colour-primary-against-white-text);
  width: auto;
}

.draggable-tree .p-treenode-content .tree-node-template .button {
  width: min-content;
}

.draggable-tree .p-treenode-content .tree-node-template button.button-link * {
  color: var(--colour-primary-against-white-text);
}

.draggable-tree .p-treenode-content .tree-node-template.tree-heading {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
}

.draggable-tree .p-treenode-content:hover {
  background-color: #ffffff1a;
}

.draggable-tree .p-treenode-content:hover .tree-node-template > * {
  opacity: 1;
}

.draggable-tree .p-treenode-droppoint {
  height: .5rem;
}

.draggable-tree .p-treenode-droppoint-active {
  border-bottom: .2rem dashed var(--colour-primary-against-white-text);
}

.draggable-tree .p-treenode-dragover {
  filter: brightness(1.2);
  outline: 1px solid var(--colour-primary-against-white-text);
}

.draggable-tree .p-treenode-leaf > .p-treenode-content .p-tree-toggler {
  visibility: hidden;
}

.draggable-tree .p-tree-toggler {
  margin-right: .5rem;
}

.draggable-tree .p-tree-toggler.p-link {
  color: var(--colour-primary-against-white-text);
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  position: relative;
  top: -.15rem;
}

.draggable-tree .p-tree-toggler:focus {
  outline-offset: 0;
  box-shadow: 0 0 0 .1rem var(--colour-primary-against-white-text);
  outline: 0;
}

.draggable-tree .p-tree-toggler-icon:before {
  content: url("data:image/svg+xml,%3Csvg src='arrow-down' class='svg icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.457 10.383'%3E%3Cpath id='Path_10218' data-name='Path 10218' d='M198.229,14.383a1.712,1.712,0,0,0,1.223-.511l7.669-7.881a1.175,1.175,0,0,0,0-1.648,1.146,1.146,0,0,0-1.631,0l-7.261,7.471-7.259-7.471a1.142,1.142,0,0,0-1.631,0,1.175,1.175,0,0,0,0,1.648L197,13.872a1.71,1.71,0,0,0,1.223.511Z' transform='translate(-189 -4)' fill='%23fff' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
  width: 100%;
  height: 100%;
  display: block;
}

.draggable-tree .pi-chevron-down:before {
  transform: scaleY(-1);
}

.notice {
  margin-bottom: var(--padding);
  background: var(--colour-primary-against-white);
  color: var(--colour-primary-against-white-text);
  padding: var(--padding);
  border-radius: var(--border-radius-inner);
  justify-content: stretch;
  align-items: center;
  gap: var(--padding);
  height: min-content;
  font-size: 1.35rem;
  display: flex;
}

@media (max-width: 1000px) {
  .notice {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}

.notice__invert {
  background: var(--colour-content-background);
  color: var(--colour-primary-against-white);
  border: .25rem solid var(--colour-primary-against-white);
}

.placeholder-image {
  margin: var(--padding) auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3rem;
  width: 100%;
  max-width: 700px;
  display: flex;
}

@media (max-width: 1000px) {
  .placeholder-image {
    display: none;
  }
}

.placeholder-image .placeholder-image--caption {
  text-align: center;
  font-size: 1.3rem;
}

.details-multi {
  justify-content: stretch;
  gap: var(--padding);
  flex-direction: column;
  display: flex;
}

.details-multi .details {
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-inner);
  overflow: hidden;
}

.details-multi .details summary {
  padding: var(--padding) var(--padding) var(--padding) calc(var(--padding)  + 1.5rem);
  cursor: pointer;
  background-color: var(--colour-secondary-faded-more);
  font-size: 1.2rem;
  list-style: none;
  position: relative;
}

.details-multi .details summary:hover {
  font-weight: bold;
}

.details-multi .details summary::-webkit-details-marker {
  display: none;
}

.details-multi .details summary:before {
  content: "";
  border-style: solid;
  border-width: .4rem;
  border-color: transparent transparent transparent var(--colour-content-text);
  top: calc(var(--padding)  + .15rem);
  left: var(--padding);
  transform-origin: .2rem;
  transition: transform .25s;
  position: absolute;
  transform: rotate(0);
}

.details-multi .details .detailsContent {
  padding: var(--padding);
  animation: none;
}

.details-multi .details .detailsGridButtons {
  gap: var(--padding-tight);
  grid-template-columns: repeat(6, 1fr);
  display: grid;
}

.details-multi .details[open] > summary:before {
  transform: rotate(90deg);
}

.padded-top {
  padding-top: var(--padding);
}

.padded-top-large {
  padding-top: calc(var(--padding) * 3);
}

.flex {
  justify-content: flex-start;
  gap: 1rem;
  display: flex;
}

.flex__wide-first {
  justify-content: space-between;
  width: 100%;
}

.flex__wide-first > * {
  flex-shrink: 1;
}

.flex__wide-first > :first-child {
  flex-grow: 1;
}

.flex__center {
  justify-content: center;
}

.flex__middle {
  align-items: center;
}

.flex__middle h2 {
  margin: 0;
}

.flex__right {
  justify-content: flex-end;
}

.flex__mb {
  margin-bottom: var(--padding);
}

.text-small {
  font-size: .9rem !important;
}

.ProseMirror a[name]:not([href]) {
  border: 1px dashed var(--colour-borders);
  cursor: default;
  font: inherit;
  color: inherit;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  text-decoration: none;
  transition: all .2s ease-out;
  display: inline-block;
  position: static;
}

.ProseMirror a[name]:not([href]):hover {
  filter: none;
}

.ProseMirror a[name]:not([href]).ProseMirror-selectednode {
  border-color: var(--colour-primary-against-white);
}

.ProseMirror a[name]:not([href]):before {
  content: "🚩 " attr(title);
  padding: .25rem .5rem;
  font-size: .8rem;
}

a[name]:not([href]) {
  width: 0;
  height: 0;
  display: inline;
  position: absolute;
  overflow: hidden;
}

.user-symbol {
  cursor: default;
  padding: 0;
  font-size: .8rem;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
  position: absolute;
  transform: translate3d(.5rem, .25rem, 0);
}

table {
  border-collapse: collapse;
  max-width: 100% !important;
}

table td, table th {
  padding: var(--padding-tight);
}

table td :first-child, table th :first-child {
  margin-top: 0;
}

table.table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
}

table.table thead th {
  padding: var(--padding-tight);
  border: 1px solid var(--colour-borders);
  text-align: start;
  font-size: 1.1rem;
}

@media (max-width: 700px) {
  table.table thead {
    display: none;
  }

  table.table tbody tr:has( > :nth-child(3)) {
    flex-direction: column;
    margin-bottom: 1rem;
    display: flex;
  }

  table.table tbody tr:has( > :nth-child(3)) td:empty {
    display: none;
  }
}

table.table tbody tr:hover > td {
  background-color: #0000;
}

table.table tbody tr:hover > td .action-icons, table.table tbody tr:hover > td .toggle-switch {
  opacity: 1;
}

table.table tbody tr.disabled > td:first-child {
  font-style: italic;
}

table.table tbody tr.disabled > td:first-child:before {
  transform: scale(1);
}

table.table tbody tr.table-row__alignTop td {
  vertical-align: top;
}

table.table td {
  padding: var(--padding-tight);
  border: 1px solid var(--colour-borders);
  text-align: start;
  color: var(--colour-content-text);
  background-clip: padding-box;
  background-color: var(--colour-content-background);
  position: relative;
}

table.table td .action-icons, table.table td .toggle-switch {
  opacity: .5;
}

table.table td .notice {
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 1rem;
  font-size: 1rem;
}

table.table td .notice svg {
  width: 1.5rem;
  height: 1.5rem;
}

table.table td .table-select-item-container {
  transition: column-gap .2s var(--easing);
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  display: flex;
}

table.table td .table-select-item-container > :first-child {
  transition: width .2s var(--easing);
}

table.table td .table-select-item-container i {
  font-size: .85rem;
}

table.table td:first-child:before {
  content: "";
  border-right: .5rem solid var(--colour-rejected);
  transition: all .2s var(--easing);
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -2px;
  transform: scale(0);
}

table.table td.compact {
  white-space: nowrap;
  text-align: end;
  width: 1rem;
}

table.table__2-col-split td {
  width: 50%;
}

.table-list {
  margin: 0;
  padding: 0;
  list-style-type: square;
  list-style-position: inside;
}

.table-list li {
  margin-bottom: .5rem;
  padding-left: 1rem;
  display: block;
  position: relative;
}

.table-list li:last-child {
  margin-bottom: 0;
}

.table-list li:before {
  content: "▪";
  position: absolute;
  left: 0;
}

.select-list-items-header {
  padding: 0 calc(var(--padding-tight)  + 1px) var(--padding-tight) calc(var(--padding-tight)  + 1px);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.select-list-items-header .select-list-items-header--selections {
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;
  display: flex;
}

.select-list-items-filters {
  margin-bottom: var(--padding);
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
  display: flex;
}

.select-list-items-filters > label {
  align-self: center;
}

.select-list-items-filters > :last-child {
  width: -moz-fit-content;
  width: fit-content;
  min-width: 300px;
  margin-left: auto;
}

@media (max-width: 700px) {
  .select-list-items-filters {
    gap: var(--padding-tight);
    flex-direction: column;
  }

  .select-list-items-filters > :last-child {
    width: 100%;
    margin-left: 0;
  }
}

.select-list-items-filters .select-list-items-filters--select {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.select-list-items-filters .select-list-items-filters--select .select {
  flex-grow: 1;
}

.ProseMirror table {
  border-collapse: collapse;
  table-layout: fixed;
  overflow: hidden;
  max-width: 100% !important;
}

.ProseMirror table td, .ProseMirror table th {
  border: 2px dashed var(--colour-borders);
  vertical-align: top;
  box-sizing: border-box;
  min-width: 1em;
  padding: .5rem;
  position: relative;
}

.ProseMirror table td > *, .ProseMirror table th > * {
  margin-bottom: 0;
}

.ProseMirror table th {
  background-color: var(--colour-secondary-against-white);
  color: var(--colour-secondary-against-white-text);
  font-weight: bold;
}

.ProseMirror table .selectedCell:after {
  z-index: 2;
  content: "";
  background-color: var(--colour-primary-faded-more);
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ProseMirror table .column-resize-handle {
  background: var(--colour-primary);
  pointer-events: none;
  width: 4px;
  position: absolute;
  top: 0;
  bottom: -2px;
  right: -2px;
}

table.content-table, .tablestyle_table {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

table.content-table td, table.content-table th, .tablestyle_table td, .tablestyle_table th {
  text-align: left;
  padding: .5rem;
}

table.content-table th, .tablestyle_table th {
  background-color: var(--colour-secondary-against-white);
  color: var(--colour-secondary-against-white-text);
  font-weight: bold;
}

table.content-table__grid td, table.content-table__grid th {
  border: 1px solid var(--colour-borders);
}

table.content-table__styled {
  box-shadow: var(--shadow);
}

table.content-table__styled tr:nth-child(2n) td {
  background: var(--colour-table-row-even);
}

table.content-table__styled tr:nth-child(odd) td {
  background: var(--colour-table-row-odd);
}

table.content-table__full-width {
  max-width: 100%;
  width: 100% !important;
}

table.content-table__centred {
  margin-left: auto;
  margin-right: auto;
}

.tableWrapper {
  max-width: 100%;
  overflow-x: auto;
}

@media (max-width: 700px) {
  .table-items-list tr {
    margin-bottom: var(--padding);
    grid-template-columns: 100fr 1fr;
    grid-template-areas: "content content"
                         "actions toggle";
    align-content: center;
    place-items: stretch stretch;
    display: grid;
  }

  .table-items-list tr > td {
    justify-content: flex-start;
    width: 100%;
    display: flex;
  }

  .table-items-list tr > td:first-child {
    border-bottom: 0;
    grid-area: content;
  }

  .table-items-list tr > td[data-content="actions"] {
    border-top: 0;
    border-right: 0;
    grid-area: actions;
    align-self: start;
    width: 100%;
    padding-top: 0;
  }

  .table-items-list tr > td[data-content="actions"]:last-child {
    border-right: 1px solid var(--colour-borders);
    grid-column-end: 5;
  }

  .table-items-list tr > td[data-content="toggle"] {
    border-top: 0;
    border-left: 0;
    grid-area: toggle;
    width: 100%;
  }
}

.table-items-list tr i {
  font-size: .85rem;
}

form[data-is-disabled="true"] {
  pointer-events: none;
  opacity: .8;
}

input, select {
  font: inherit;
  color: var(--colour-input);
  background-color: var(--colour-input-background);
  border: 0;
  padding: .8rem 1rem;
}

input {
  line-height: 1.5;
}

input::placeholder, select:required:invalid {
  color: var(--colour-input);
  opacity: .7;
}

input[type="text"]:focus, input[type="password"]:focus {
  outline: .2rem solid var(--colour-primary-faded);
}

input[type="text"]:disabled, input[type="password"]:disabled, textarea:disabled {
  opacity: .5;
}

.button-unstyled {
  font: inherit;
  color: inherit;
  text-align: inherit;
  background-color: #0000;
  border: 0;
  padding: 0;
}

.button-unstyled__link {
  cursor: pointer;
  color: var(--colour-links);
}

.button-unstyled__link > * {
  cursor: pointer;
}

.button-unstyled__help {
  color: var(--colour-buttons-text);
  background: var(--colour-buttons);
  aspect-ratio: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
}

.button-unstyled__flex {
  justify-content: flex-start;
  align-items: center;
  column-gap: .5rem;
  display: flex;
}

.button-unstyled__underline {
  text-decoration: underline;
}

.button {
  background-color: var(--colour-buttons);
  border-radius: var(--border-radius-small);
  color: var(--colour-buttons-text);
  cursor: pointer;
  white-space: nowrap;
  box-shadow: var(--shadow);
  border: 0;
  align-items: center;
  column-gap: .7rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: .5rem .7rem;
  font-family: inherit;
  font-size: .85rem;
  font-weight: bold;
  text-decoration: none;
  transition: all .2s ease-out;
  display: flex;
}

.button > span {
  min-width: auto;
}

.button > .icon, .button > .icon > svg {
  flex-grow: 1;
  max-height: 1.15rem;
}

.button > .icon *, .button > .icon > svg * {
  fill: var(--colour-buttons-text);
}

.button > .svg__no-fill * {
  fill: none;
}

.button > a {
  color: var(--colour-buttons-text);
  text-decoration: none;
}

button:hover, .button:hover {
  filter: brightness(1.2);
}

.button__alt {
  background-color: var(--colour-buttons-alt);
  color: var(--colour-buttons-alt-text);
}

.button__condensed {
  padding: .1rem;
}

.button__toolbar {
  justify-content: center;
  align-self: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: .25rem;
}

.button:focus {
  outline: .3rem solid var(--colour-secondary-faded);
}

.button__transparent {
  color: var(--colour-primary);
  font: inherit;
  background-color: #0000;
  border-radius: 0;
  box-shadow: none !important;
}

.button__transparent > .icon * {
  fill: var(--colour-primary);
}

.button__transparent:focus {
  outline: none;
}

.button__arrow .icon {
  transition: all .2s ease-out;
  transform: translateX(0);
}

.button__arrow:hover .icon {
  transform: translateX(.25rem);
}

.button__arrow:hover .icon.icon__scale {
  transform: scale(1.1);
}

@media (max-width: 700px) {
  .button__arrow-back {
    display: none;
  }
}

.button__arrow-back .icon {
  transition: all .2s ease-out;
  transform: translateX(0)scaleX(-1);
}

.button__arrow-back:hover .icon {
  transform: translateX(-.25rem)scaleX(-1);
}

.button__invert {
  background-color: var(--colour-content-background);
  border: 2px solid var(--colour-buttons);
  color: var(--colour-buttons);
}

.button__invert > .icon *, .button__invert > .icon > svg * {
  fill: var(--colour-buttons);
}

.button__invert > .svg__no-fill * {
  fill: none;
}

.button__action-button {
  padding: .25rem;
}

.button__right {
  margin-left: auto;
}

.button:disabled, .button:disabled:hover, .button__disabled, .button__disabled:hover {
  pointer-events: none;
  opacity: .5;
  cursor: default;
  filter: grayscale() !important;
}

.button:disabled svg, .button:disabled:hover svg, .button__disabled svg, .button__disabled:hover svg {
  opacity: .5;
}

a.link-button, a.link-button > button {
  text-decoration: none !important;
}

.button__fixed-small {
  justify-content: center;
  width: 7rem;
}

.button__fixed-med {
  justify-content: center;
  width: 10rem;
}

.tickbox {
  cursor: pointer;
  color: var(--colour-links);
  transition: filter .2s ease-out;
}

.tickbox input {
  display: none !important;
}

.tickbox svg path {
  transform-origin: 10px 16px;
  d: path("M6,13l0,0l0,0");
  opacity: 0;
}

.tickbox svg rect {
  fill: #0000;
  transition: all .2s ease-out;
}

.tickbox > input:checked + svg path {
  opacity: 1;
  stroke: #fff;
  animation: .2s ease-out forwards tickbox;
}

.tickbox > input:checked + svg rect {
  fill: var(--colour-links);
}

.tickbox[data-disabled="true"] {
  pointer-events: none;
  cursor: default;
  opacity: .5;
}

.select {
  z-index: 10;
}

.select .select__control {
  border-color: var(--colour-borders);
  background: var(--colour-content-background);
  border-radius: var(--border-radius-small);
}

.select .select__control--is-focused {
  border-color: var(--colour-primary-against-white);
  box-shadow: 0 0 0 1px var(--colour-primary-against-white);
}

.select .select__control:hover {
  border-color: var(--colour-primary);
}

.select .select__single-value {
  color: var(--colour-content-text);
  margin-left: 0;
}

.select__menu-portal {
  margin-top: -6px;
}

.select__menu-portal .select__option.test {
  outline: 4px solid pink;
  font-size: 5rem;
}

.select__menu-portal .select__option:hover {
  background-color: var(--colour-primary-against-white);
  color: #fff;
}

.select__menu-portal .select__option--is-focused {
  background-color: var(--colour-input-background);
  color: var(--colour-content-text);
}

.select__menu-portal .select__option--is-selected {
  background-color: var(--colour-secondary-against-white);
  color: #fff;
}

.select__menu-portal .select-placeholder {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.select-preview-option {
  white-space: nowrap;
  background: #0000001a;
  border-radius: .15rem;
  margin-right: .25rem;
  padding: .25rem .5rem;
  font-size: .85rem;
  display: inline-block;
}

:root.theme-dark .select .select__control--is-focused {
  border-color: var(--colour-primary-against-black);
  box-shadow: 0 0 0 1px var(--colour-primary-against-black);
}

:root.theme-dark .select-preview-option {
  background: #ffffff1a;
}

:root.theme-dark .select__menu-portal * {
  background: #000;
}

.tickbox:hover {
  filter: brightness(1.2);
}

.radio {
  cursor: pointer;
  color: var(--colour-links);
}

.radio input {
  display: none;
}

.radio svg circle.radio-button {
  transform-origin: 12px 12px;
  transform: scale(0);
}

.radio > input:checked + svg circle.radio-button {
  animation: .4s ease-out forwards scale-overshoot;
  transform: scale(1);
}

.radio[data-disabled="true"] {
  pointer-events: none;
  cursor: default;
  opacity: .5;
}

.radio:hover {
  filter: brightness(1.2);
}

textarea {
  color: var(--colour-input);
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-small);
  resize: vertical;
  font: inherit;
  background-color: #0000;
  width: 100%;
  min-height: 9rem;
  padding: 1rem;
}

textarea:focus {
  outline: .2rem solid var(--colour-primary-faded);
  border-color: #0000;
}

.form {
  gap: var(--padding);
  flex-direction: column;
  display: flex;
  position: relative;
}

.form .form-modal-message {
  padding: var(--padding);
  background-color: var(--colour-primary-against-white);
  color: var(--colour-primary-against-white-text);
  border-radius: var(--border-radius-inner);
  z-index: 10;
  text-align: center;
  animation: fade-in .4s var(--easing) forwards;
  width: 50%;
  font-size: 1.2rem;
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translate(-50%);
}

.form .form-section {
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  padding: var(--padding);
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-outer);
  gap: var(--padding);
  flex-direction: column;
  display: flex;
  position: relative;
}

.form .form-section .form-input-flex {
  justify-content: stretch;
  align-items: flex-start;
  gap: var(--padding);
  display: flex;
}

.form .form-section .form-input-flex > * {
  flex-grow: 1;
}

.form .form-section .form-input-flex .form-input-suffix {
  align-self: flex-end;
  font-style: italic;
}

.form .form-section .form-input-flex__tight {
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 1rem;
}

.form .form-section .form-input-flex__tight > * {
  margin-right: var(--padding);
  flex-grow: 0;
  flex-shrink: 1;
}

.form .form-section .form-input-flex__tighter {
  gap: 0;
}

.form .form-section label.form-input-flex__tight > * {
  margin-right: 0;
}

.form .form-section .form-input-flex:last-child input {
  margin-bottom: .2rem;
}

.form .form-section .form-input-flex-pair {
  align-items: center;
  column-gap: 1rem;
  display: flex;
}

.form .form-section .form-input-flex-pair > * {
  min-width: auto;
}

.form .form-section > h3:first-child {
  margin-top: 0;
}

.form .form-section .edit-icons {
  border-radius: var(--border-radius-small);
  opacity: 0;
  padding: .5rem;
  transition: opacity .2s ease-out;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.form .form-section[data-is-section="true"] {
  padding: 0 var(--padding);
  background-color: #0000;
  border-color: #0000;
}

.form .form-section:hover .edit-icons {
  opacity: 1;
  transition: none;
}

.form .form-item {
  gap: var(--padding-tight);
  flex-direction: column;
  display: flex;
}

.form .form-item .form-label-and-caption-container {
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  gap: .25rem;
  display: flex;
}

.form .form-item .form-label-and-caption-container .form-caption {
  flex-shrink: 1;
  font-size: .9rem;
  line-height: 1;
}

.form .form-item .form-label-container {
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  display: flex;
}

.form .form-item .form-label-container .title-action-buttons {
  flex-shrink: 1;
}

.form .form-item label {
  align-items: center;
  gap: .5rem;
  font-size: 1.2rem;
  display: flex;
}

.form .form-item label .label--required-marker {
  opacity: .5;
  width: 1rem;
  height: 1rem;
  margin-top: -.15rem;
}

.form .form-item .notice {
  margin-bottom: 0;
}

.form .form-item input[type="text"], .form .form-item input[type="password"] {
  border-bottom: 1px solid var(--colour-borders);
  background-color: #0000;
  width: 100%;
  margin-bottom: .2rem;
  padding: 0 0 .5rem;
  transition: border-color .1s ease-out;
}

.form .form-item input[type="text"]:focus, .form .form-item input[type="password"]:focus {
  border-bottom: .2rem solid var(--colour-primary-faded);
  outline: none;
  margin-bottom: 1px;
}

.form .form-item input[type="date"], .form .form-item input[type="number"] {
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-small);
  font: inherit;
  background-color: #0000;
  width: 100%;
}

.form .form-item .input-number-prefix-container {
  position: relative;
}

.form .form-item .input-number-prefix-container:before {
  content: "£";
  z-index: 2;
  font-weight: bold;
  position: absolute;
  top: .8rem;
  left: 1rem;
}

.form .form-item .input-number-prefix-container input[type="number"] {
  padding-left: 2rem;
}

.form .form-item input[type="date"]:focus, .form .form-item input[type="number"]:focus {
  outline: .2rem solid var(--colour-primary-faded);
  border-color: #0000;
}

.form .form-item .input-colour-picker {
  border-radius: var(--border-radius-inner);
  cursor: pointer;
  outline: 1px solid var(--colour-borders);
  width: 5rem;
  height: 5rem;
  transition: all .2s ease-out;
  display: inline-block;
  position: relative;
  left: 1px;
}

.form .form-item .input-colour-picker .input-colour-picker--swatch {
  content: "s";
  border: .5rem solid var(--colour-content-background);
  border-radius: var(--border-radius-inner);
  cursor: pointer;
  filter: none;
  width: 5rem;
  height: 5rem;
  transition: all .2s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.form .form-item .input-colour-picker .input-colour-picker--swatch:hover {
  filter: brightness(1.2);
}

.form .form-item .input-colour-picker .input-colour-picker--picker {
  z-index: 50;
  box-shadow: var(--shadow);
  cursor: default;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.form .form-item .input-colour-picker.disabled {
  pointer-events: none;
  opacity: .5;
}

.form .form-item .input-image-drop {
  border: 2px dashed var(--colour-borders);
  cursor: pointer;
  color: var(--colour-links);
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: .5rem;
  transition: all .2s ease-out;
  display: flex;
}

.form .form-item .input-image-drop img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.form .form-item .input-image-drop * {
  pointer-events: none;
}

.form .form-item .input-image-drop:hover > * {
  filter: brightness(1.2);
}

.form .form-item .input-image-drop__focused {
  border-color: var(--colour-primary-against-white);
}

.form .form-item .input-image-drop__accepted {
  border-color: var(--colour-accepted);
  background-color: var(--colour-accepted-faded);
}

.form .form-item .input-image-drop__rejected {
  border-color: var(--colour-rejected);
  color: var(--colour-rejected);
}

.form .form-item div.input-colour-picker__small {
  width: 2.5rem;
  height: 2.5rem;
}

.form .form-item[data-pending-approval="true"] {
  background-color: #f003;
  outline: 2px dashed red;
}

.form .form-buttons {
  justify-content: center;
  column-gap: var(--padding);
  align-items: center;
  display: flex;
}

.form .form-buttons button.button {
  justify-content: center;
  min-width: 12rem;
  font-size: 1.1rem;
}

.form:not(.drawer .form) h2, .form:not(.drawer .form) h3 {
  margin: 0;
  font-weight: bold;
}

.filter-form input[type="text"], .filter-form input[type="number"], .filter-form input[type="date"] {
  background-color: var(--colour-content-background);
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-small);
  font: inherit;
  padding: .5rem;
}

.form-user-error-tip {
  background-color: var(--colour-rejected);
  color: #fff;
  padding: var(--padding-tight);
  border-radius: var(--border-radius-small);
  animation: .5s ease-in-out both pulsate-fwd;
}

.icon-picker-container {
  border-radius: var(--border-radius-inner);
  outline: 1px solid var(--colour-borders);
  width: min-content;
  display: block;
}

.icon-picker {
  background-color: var(--colour-content-background);
  z-index: 10;
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-small);
  animation: dropdown-overshoot .4s var(--easing) forwards;
  flex-direction: column;
  width: 20rem;
  height: 20rem;
  margin-top: .5rem;
  padding: .5rem;
  display: flex;
  position: absolute;
  overflow: auto;
}

.icon-picker .icon-picker--title {
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-weight: bold;
}

.icon-picker .icon-picker--title:first-child {
  margin-top: 0;
}

.icon-picker .icon-picker--grid {
  grid-template-columns: repeat(5, 1fr);
  gap: .5rem;
  margin-bottom: .5rem;
  display: grid;
}

.icon-picker .icon-picker--grid .icon-picker--grid-item {
  color: var(--colour-content-text);
  aspect-ratio: 1;
  border: 1px solid var(--colour-borders);
  cursor: pointer;
  outline: .25rem solid #0000;
  justify-content: center;
  align-items: center;
  transition: outline .2s ease-out;
  display: flex;
}

.icon-picker .icon-picker--grid .icon-picker--grid-item > * {
  width: 32px;
  height: 32px;
}

.icon-picker .icon-picker--grid .icon-picker--grid-item:hover {
  outline: .25rem solid var(--colour-buttons);
}

.icon-picker .icon-picker--grid:last-child {
  margin-bottom: 0;
}

.item-icon {
  color: inherit !important;
}

.item-icon * {
  fill: none !important;
  stroke: currentColor !important;
}

.item-icon__fill-stroke * {
  fill: currentColor !important;
  stroke: none !important;
}

.richtext-container {
  gap: var(--padding);
  flex-direction: column;
  width: 100%;
  display: flex;
}

.drawer--content .richtext-container {
  padding-top: 0;
}

.richtext {
  flex-grow: 1;
  width: 100%;
}

.richtext .ProseMirror {
  outline: 0;
  min-height: 20rem;
}

.richtext img {
  max-width: calc(100% - 8px);
  display: block;
  height: auto !important;
  margin: 1rem auto !important;
}

.richtext img.ProseMirror-selectednode {
  outline: 2px dotted var(--colour-primary);
  outline-offset: 2px;
  opacity: .9;
  background-color: var(--colour-secondary);
}

.richtext-toolbar {
  z-index: 5;
  padding-top: var(--padding-tight);
  padding-bottom: var(--padding-tight);
  margin: 0;
  margin-bottom: var(--padding-tight);
  background-color: var(--colour-content-background);
  justify-content: flex-start;
  align-items: stretch;
  gap: .5rem;
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
}

.richtext-toolbar > * {
  flex-shrink: 1;
}

.richtext-toolbar > :nth-last-child(2) {
  margin-left: auto;
}

.drawer--content .richtext-toolbar {
  padding-bottom: 0;
  padding-top: var(--padding-tight);
  margin-bottom: 0;
  top: -2rem;
}

.drawer--content .richtext-toolbar > :nth-last-child(2) {
  margin-left: 0;
}

.absence-type-picker {
  justify-content: stretch;
  align-items: stretch;
  gap: var(--padding-tight);
  display: flex;
}

.absence-type-picker > label:first-child {
  background-color: var(--colour-absence-holiday);
}

.absence-type-picker > label:nth-child(2) {
  background-color: var(--colour-absence-sickness);
}

.absence-type-picker > label:nth-child(3) {
  background-color: var(--colour-absence-family);
}

.absence-type-picker > label:nth-child(4) {
  background-color: var(--colour-absence-other);
}

.absence-type-picker > label {
  cursor: pointer;
  border-radius: var(--border-radius-small);
  flex-direction: column;
  flex-basis: 25%;
  align-items: center;
  padding: .5rem;
  transition: filter .2s ease-out;
  display: flex;
  font-size: 1rem !important;
}

.absence-type-picker > label svg > :first-child {
  fill: #fff;
}

.absence-type-picker label:hover {
  filter: brightness(1.05);
}

.form-section.form-section__click-to-edit {
  gap: calc(var(--padding)  - 1rem);
  padding: 0;
}

.form-section.form-section__click-to-edit .form--click-to-edit {
  padding: .5rem calc(var(--padding)  - .5rem);
  margin-left: .5rem;
  margin-right: .5rem;
}

.form-section.form-section__click-to-edit .form-item:first-child {
  margin-top: calc(var(--padding)  - .5rem);
}

.form-section.form-section__click-to-edit .form-item:last-child {
  margin-bottom: calc(var(--padding)  - .5rem);
}

.form-section.form-section__click-to-edit > .form-item > h2 {
  padding: .5rem var(--padding);
  margin: 0;
}

.form--click-to-edit {
  justify-content: stretch;
  column-gap: var(--padding);
  cursor: pointer;
  align-items: stretch;
  transition: background-color .2s ease-out;
  display: flex;
}

.form--click-to-edit > .form-label-container {
  flex-grow: 1;
  flex-basis: 50%;
}

.form--click-to-edit > .form-label-container > label {
  width: 100%;
}

.form--click-to-edit .form--click-to-edit-input {
  border-bottom: 1px dotted var(--colour-borders);
  flex-wrap: wrap;
  flex-basis: 50%;
  justify-content: flex-start;
  align-items: flex-end;
  row-gap: .25rem;
  width: 50%;
  margin-bottom: -.25rem;
  padding-bottom: .25rem;
  display: flex;
}

@media (max-width: 700px) {
  .form--click-to-edit {
    margin-bottom: var(--padding);
    flex-direction: column;
  }

  .form--click-to-edit > .form--click-to-edit-input {
    margin-top: var(--padding);
    flex-basis: 100%;
    justify-content: flex-start;
    width: 100%;
  }
}

.form--click-to-edit__disabled {
  cursor: default;
}

.form--click-to-edit__disabled .form--click-to-edit-input {
  border-bottom: 1px solid var(--colour-borders);
}

.form-item:hover .form--click-to-edit {
  background-color: var(--colour-input-background);
  border-radius: var(--border-radius-small);
}

.form-item:hover .form--click-to-edit__disabled {
  background-color: #0000;
}

.form--field-currently-editing {
  border: .2rem solid var(--colour-secondary);
  padding: var(--padding);
  border-radius: var(--border-radius-small);
  animation: form-edit-input .2s var(--easing) forwards;
}

.form-submit-container {
  margin-top: var(--padding);
  justify-content: center;
  align-items: center;
  display: flex;
}

.form-submit-container .button {
  font-size: 1.2rem;
}

@keyframes slide-top {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes float {
  100% {
    transform: translateY(.5rem);
  }
}

@keyframes bounce-top {
  0% {
    opacity: 1;
    animation-timing-function: ease-in;
    transform: translateY(-45px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateY(-24px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateY(-12px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateY(-6px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateY(-4px);
  }

  25%, 55%, 75%, 87% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}

@keyframes bounce-right-squash {
  0% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  10% {
    animation-timing-function: ease-in;
    transform: translateX(150%)scaleY(100%);
  }

  40% {
    -webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
    clip-path: polygon(0 0, 100% 50%, 0 100%);
    animation-timing-function: ease-in;
    transform: translateX(75%)scaleY(20%);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateX(25%)scaleY(20%);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateX(12%)scaleY(20%);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateX(8%)scaleY(20%);
  }

  25%, 55%, 75%, 87%, 98% {
    animation-timing-function: ease-out;
    transform: translateX(0)scaleY(20%);
  }

  100% {
    -webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
    clip-path: polygon(0 0, 100% 50%, 0 100%);
    animation-timing-function: ease-out;
    transform: translateX(0)scaleY(20%);
  }
}

@keyframes restore-scale-y {
  0% {
    transform: scaleY(20%);
  }

  100% {
    transform: scaleY(100%);
  }
}

@keyframes heading-line {
  0% {
    opacity: 0;
    width: 0%;
    min-width: 0;
    animation-timing-function: ease-in-out;
  }

  10% {
    opacity: 1;
    width: 100%;
  }

  30% {
    width: 0%;
  }

  100% {
    width: 3rem;
  }
}

@keyframes heartbeat {
  from {
    transform-origin: center;
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  10% {
    animation-timing-function: ease-in;
    transform: scale(1.2);
  }

  17% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  33% {
    animation-timing-function: ease-in;
    transform: scale(1.3);
  }

  45% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}

@keyframes wobble-hor-top {
  0%, 100% {
    transform-origin: 50%;
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-.3rem)rotate(6deg);
  }

  30% {
    transform: translateX(.15rem)rotate(-6deg);
  }

  45% {
    transform: translateX(-.3rem)rotate(6deg);
  }

  60% {
    transform: translateX(.15rem)rotate(-6deg);
  }

  75% {
    transform: translateX(-.1rem)rotate(1.2deg);
  }
}

@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes skeleton-shine {
  0% {
    background-image: linear-gradient(90deg, #0000 0, #fff6 15rem, #0000 30rem);
    background-position: -10rem 0;
    background-repeat: no-repeat;
  }

  50% {
    background-position: 100vw 0;
  }

  51% {
    background-image: none;
  }
}

@keyframes skeleton-shine-small {
  0% {
    background-image: linear-gradient(90deg, #0000 0, #ffffff80 1rem, #0000 3rem);
    background-position: -4rem 0;
    background-repeat: no-repeat;
  }

  50% {
    background-image: linear-gradient(90deg, #0000 0, #ffffff80 1rem, #0000 3rem);
    background-position: calc(100% + 5rem) 0;
    background-repeat: no-repeat;
  }

  100% {
    background-image: linear-gradient(90deg, #0000 0, #ffffff80 1rem, #0000 3rem);
    background-position: calc(100% + 5rem) 0;
    background-repeat: no-repeat;
  }
}

@keyframes scale-overshoot {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes side-menu-dropdown {
  0% {
    transform-origin: 0 0;
    animation-timing-function: var(--throbber-easing);
    padding-top: 0;
    transform: scaleY(0);
  }

  100% {
    transform-origin: 0 0;
    transform: scaleY(1);
  }
}

@keyframes init-overflow {
  0% {
    overflow: hidden;
  }

  100% {
    overflow: auto;
  }
}

@keyframes side-menu-slide-in {
  0% {
    animation-timing-function: var(--easing);
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes header-slide-down {
  0% {
    animation-timing-function: var(--easing);
    transform: translateY(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes dropdown-overshoot {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    animation-timing-function: ease-in-out;
    transform: scaleY(0);
  }

  20% {
    opacity: 1;
  }

  60% {
    animation-timing-function: var(--throbber-easing);
    opacity: 1;
    transform: scaleY(1.2);
  }

  100% {
    transform-origin: 0 0;
    transform: scaleY(1);
  }
}

@keyframes tickbox {
  0% {
    d: path("M6,13l0,0l0,0");
    transform: scale(0);
  }

  50% {
    d: path("M6,13l5,4l0,0");
    transform: scale(1.2);
  }

  100% {
    d: path("M6,13l5,4L18,7");
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-shrink {
  0% {
    opacity: 0;
    animation-timing-function: var(--throbber-easing);
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes up-into-place {
  0% {
    animation-timing-function: var(--throbber-easing);
    transform: translateY(20rem);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes down-into-place {
  0% {
    animation-timing-function: var(--throbber-easing);
    transform: translateY(-20rem);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes scale-in-center {
  0% {
    animation-timing-function: var(--throbber-easing);
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes text-slide-in {
  0% {
    transform-origin: 0 0;
    filter: blur(1px);
    opacity: 0;
    animation-timing-function: var(--throbber-easing);
    transform: scaleX(.8);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    filter: 0;
    transform: scaleX(1);
  }
}

@keyframes form-edit-input {
  0% {
    transform-origin: 100% 0;
    animation-timing-function: var(--easing);
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulsate-fwd {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

body.app .header-top--profile-menu-tiles [data-label="Dashboard"] {
  display: flex;
}

.header-top--profile-menu {
  padding: var(--padding);
  background-color: var(--colour-primary-faded);
  border-radius: var(--border-radius-small);
  z-index: 10;
  box-shadow: var(--shadow);
  margin-top: .5rem;
  animation: .2s forwards fade-in;
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 500px !important;
}

.header-top--profile-menu h3 {
  color: var(--colour-primary-faded-text);
  margin: 1.5rem 0 .5rem;
}

.header-top--profile-menu h3:first-of-type {
  margin-top: 0;
}

.header-top--profile-menu .header-top--profile-menu-tiles {
  grid-template-columns: repeat(3, 1fr);
  gap: .5rem;
  display: grid;
}

.header-top--profile-menu .header-top--profile-menu-tiles > * {
  text-align: center;
  background-color: var(--colour-buttons);
  color: var(--colour-buttons-text);
  transition: all .2s var(--easing);
  border-radius: var(--border-radius-inner);
  white-space: normal;
  flex-direction: column;
  align-items: center;
  row-gap: .5rem;
  width: 100%;
  padding: .5rem;
  font-size: .85rem;
  font-weight: normal;
  text-decoration: none;
  display: flex;
}

.header-top--profile-menu .header-top--profile-menu-tiles > * * {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.header-top--profile-menu .header-top--profile-menu-tiles [data-label="Dashboard"] {
  display: none;
}

.header-top--profile-menu .header-top--profile-menu-tiles svg {
  width: 32px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
  display: inline-block;
}

.header-top--profile-menu .header-top--profile-menu--close, .header-top--profile-menu .header-top--profile-menu--controls {
  display: none;
}

@media (max-width: 500px) {
  .header-top--profile-menu {
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
    max-width: 100% !important;
  }
}

.navigation-bar-top--items {
  z-index: 9999;
}

.navigation-bar-top--items .header-top--profile-menu {
  transition: transform .2s var(--easing);
  padding-top: calc(var(--padding)  + 2rem);
  border-radius: 0;
  width: 100%;
  min-width: 100px;
  max-width: 600px;
  height: 100vh;
  margin: 0;
  display: none;
  position: fixed;
  top: 0;
  overflow: auto;
  transform: translateX(101%);
}

@media (min-width: 1000px) {
  .navigation-bar-top--items .header-top--profile-menu {
    display: none;
  }
}

.navigation-bar-top--items .header-top--profile-menu h3 {
  margin: 2rem 0 1rem;
}

.navigation-bar-top--items .header-top--profile-menu h3:first-of-type {
  margin-top: 0;
}

.navigation-bar-top--items .header-top--profile-menu .close-button {
  top: var(--padding);
  right: var(--padding);
  display: inline-block;
  position: absolute;
}

.navigation-bar-top--items .header-top--profile-menu .header-top--profile-menu--controls {
  justify-content: space-between;
  gap: var(--padding);
  margin-top: calc(var(--padding) * 2);
  border-top: 1px solid var(--colour-primary-faded-text);
  padding-top: calc(var(--padding) * 2);
  display: flex;
}

.navigation-bar-top--items .header-top--profile-menu .header-top--profile-menu--controls > :last-child {
  margin-left: auto;
}

.navigation-bar-top--items .header-top--profile-menu.active {
  display: block;
  transform: translateX(0);
}

div#root {
  min-height: 100%;
}

.app-container {
  background-color: var(--colour-background);
  grid-template-rows: min-content auto min-content;
  grid-template-areas: "header"
                       "main"
                       "footer";
  min-height: 100vh;
  display: grid;
  position: relative;
}

@media (max-width: 1000px) {
  .app-container {
    height: -moz-fit-content;
    height: fit-content;
  }
}

@media (min-width: 1200px) and (orientation: landscape) {
  .app-container__dashboard {
    height: 100vh;
  }
}

.app-container:before {
  pointer-events: none;
  content: "";
  z-index: 19;
  opacity: 0;
  background: #000;
  width: 100vw;
  height: 100vh;
  transition: opacity .5s ease-out;
  position: fixed;
}

.app-container.disabled:before {
  pointer-events: all;
  opacity: .7;
}

body, header, header > *, main, main > *, .main-content, footer, footer > * {
  transition: background-color .5s ease-out, color .5s ease-out;
}

:root.theme-dark .header-top--logo {
  filter: invert(1) grayscale(1) opacity(.5) drop-shadow(0 0 0 var(--colour-primary-against-black)) saturate(400%);
}

body.app header {
  flex-direction: column;
  justify-content: stretch;
  display: flex;
}

body.app header .navigation-bar-top {
  column-gap: var(--padding-tight);
  padding: var(--padding-tight);
  grid-template-columns: 1fr;
}

body.app header .navigation-bar-top .navigation-bar-top--items {
  justify-content: flex-start;
  align-items: center;
}

body.app header .navigation-bar-top .navigation-bar-top--items .header-top--logo-mobile {
  margin-left: 0;
}

body.app header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu {
  gap: 0;
}

body.app header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu span {
  display: none;
}

body.app header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > * {
  border-right: 0;
  padding: 0;
}

body.app header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > :first-child {
  padding-left: 0;
}

body.app header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu button {
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  display: inline-flex;
}

body.app header .navigation-bar-top .navigation-bar-top--menu * > .svg, body.app header .navigation-bar-top .navigation-bar-top--menu * > svg {
  width: 1.5rem !important;
  max-width: 1.5rem !important;
  height: 1.5rem !important;
  max-height: 1.5rem !important;
}

body.app header .navigation-bar-top--home, body.app header .navigation-bar-top--home-container {
  display: none;
}

body.app header .navigation-bar-top--toggle-dark.toggle-switch, body.app header .navigation-bar-top--toggle-contrast.toggle-switch {
  display: none !important;
}

body.app header .navigation-bar--app-title {
  background-color: var(--colour-buttons);
  padding: var(--padding-tight);
  border-top: 2px solid #fff;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1;
  display: flex;
  color: var(--colour-buttons-text) !important;
}

header {
  background-color: var(--colour-content-background);
  z-index: 20;
  grid-area: header;
  grid-template-areas: "header-items"
                       "nav-bar";
  display: grid;
  position: relative;
}

header .navigation-bar--app-title {
  display: none;
}

header .header-top {
  padding: var(--padding-tight) var(--padding);
  z-index: 99999;
  grid-area: header-items;
  justify-content: stretch;
  align-items: center;
  display: flex;
}

header .header-top .header-top--logo {
  flex-grow: 1;
  align-items: center;
  display: flex;
}

header .header-top .header-top--logo img {
  object-fit: contain;
  object-position: center center;
  width: calc(14rem + 1vw);
  max-width: 300px;
  height: auto;
  max-height: 100px;
  display: block;
}

header .header-top .header-top--items {
  flex-shrink: 1;
  align-items: stretch;
  column-gap: 1.25rem;
  max-height: 70px;
  padding: .4rem 0;
  display: flex;
}

header .header-top .header-top--items * {
  min-width: 0;
}

header .header-top .header-top--items .header-top--profile-menu-container {
  align-items: stretch;
  display: flex;
  position: relative;
}

header .header-top .header-top--items .header-top--profile-btn {
  color: var(--colour-header-primary);
  border-radius: var(--border-radius-small);
  width: -moz-fit-content;
  width: fit-content;
  padding-top: .2rem;
  padding-bottom: .2rem;
  font-size: .85rem;
  font-weight: bold;
}

header .header-top .header-top--items .header-top--profile-btn .header-top--profile-photo {
  object-fit: cover;
  background-color: var(--colour-primary-faded-more);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

header .header-top .header-top--items .header-top--profile-btn .header-top--profile-photo * {
  fill: var(--colour-header-primary);
}

header .header-top .header-top--items .header-top--profile-btn .header-top--profile-photo__svg {
  width: 3rem;
  height: 3rem;
  padding: .75rem;
}

header .header-top .header-top--items .header-top--profile-btn > .svg > * {
  fill: var(--colour-header-primary);
}

header .header-top .header-top--items .header-top--profile-btn > .icon {
  transition: all .2s ease-out;
}

header .header-top .header-top--items .header-top--profile-btn.active {
  background-color: var(--colour-buttons);
  color: var(--colour-buttons-text);
  box-shadow: var(--shadow);
}

header .header-top .header-top--items .header-top--profile-btn.active .header-top--profile-photo {
  background-color: var(--colour-secondary-faded-more);
}

header .header-top .header-top--items .header-top--profile-btn.active .header-top--profile-photo *, header .header-top .header-top--items .header-top--profile-btn.active > .svg > * {
  fill: var(--colour-buttons-text);
}

header .header-top .header-top--items .header-top--profile-btn.active > .icon {
  transform: scaleY(-1);
}

header .header-top .header-top--items .header-top--profile-menu__admin {
  padding: var(--padding-tight);
  white-space: nowrap;
  flex-direction: column;
  min-width: 0;
  display: flex;
}

header .header-top .header-top--items .header-top--profile-menu__admin .header-top--accounts-list {
  max-height: 50vh;
  overflow: auto;
}

header .header-top .header-top--items .header-top--profile-menu__admin .header-top--accounts-list a, header .header-top .header-top--items .header-top--profile-menu__admin .header-top--accounts-list button.button-link {
  color: var(--colour-primary-faded-text);
  width: 100%;
  padding: .3rem 1rem;
  text-decoration: none;
  display: block;
}

header .header-top .header-top--items .header-top--profile-menu__admin .header-top--accounts-list a:hover, header .header-top .header-top--items .header-top--profile-menu__admin .header-top--accounts-list button.button-link:hover {
  background-color: var(--colour-buttons);
  color: var(--colour-buttons-text);
}

header .header-top .header-top--items .header-top--profile-menu__admin .header-top--accounts-list hr {
  border-top: 1px solid var(--colour-primary-faded-text);
}

header .header-top .header-top--items .header-top--signout-btn {
  background: var(--colour-header-secondary);
  color: var(--colour-header-secondary-text);
  box-shadow: var(--shadow);
}

header .header-top .header-top--items .header-top--signout-btn > .svg > * {
  fill: var(--colour-header-secondary-text);
}

@media (max-width: 1200px), (min-width: 1000px) and (max-height: 1000px) {
  header .header-top {
    padding: .5rem var(--padding);
  }
}

@media (max-width: 1000px) {
  header .header-top {
    z-index: 10;
    background: var(--colour-secondary);
    padding: var(--padding);
    width: 100%;
    max-width: 300px;
    height: 100%;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
  }
}

header .navigation-bar-top {
  padding: .5rem var(--padding) .5rem 0;
  background-color: var(--colour-primary);
  grid-area: nav-bar;
  grid-template-columns: 1fr 4.7fr;
  display: grid;
}

@media (max-width: 1200px) {
  header .navigation-bar-top {
    grid-template-columns: min-content auto;
  }
}

header .navigation-bar-top .navigation-bar-top--home-container {
  padding-left: var(--padding);
  min-width: 17.5438vw;
  height: 100%;
}

header .navigation-bar-top .navigation-bar-top--home-container .navigation-bar-top--home {
  background-color: var(--colour-home-button-background);
  border: 1px solid var(--colour-header-input-borders);
  color: var(--colour-home-button-text);
  box-shadow: var(--shadow);
  text-transform: uppercase;
  column-gap: 1rem;
  height: 100%;
  position: relative;
  overflow: hidden;
}

header .navigation-bar-top .navigation-bar-top--home-container .navigation-bar-top--home span {
  margin-top: .2rem;
}

header .navigation-bar-top .navigation-bar-top--home-container .navigation-bar-top--home .svg * {
  fill: var(--colour-home-button-text);
  transition: all .2s ease-out;
}

@media (max-width: 1200px) {
  header .navigation-bar-top .navigation-bar-top--home-container .navigation-bar-top--home .svg {
    min-width: 1.5rem;
  }

  header .navigation-bar-top .navigation-bar-top--home-container .navigation-bar-top--home {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  header .navigation-bar-top .navigation-bar-top--home-container .navigation-bar-top--home > span {
    display: none;
  }
}

@media (max-width: 700px) {
  header .navigation-bar-top .navigation-bar-top--home-container .navigation-bar-top--home {
    padding-left: .5rem;
    padding-right: .5rem;
    display: none;
  }
}

header .navigation-bar-top .navigation-bar-top--home-container .navigation-bar-top--home:hover {
  color: var(--colour-home-button-background);
  filter: brightness();
}

header .navigation-bar-top .navigation-bar-top--home-container .navigation-bar-top--home:hover .svg * {
  fill: var(--colour-home-button-background);
}

header .navigation-bar-top .navigation-bar-top--home-container .navigation-bar-top--home:before {
  content: "";
  background-color: var(--colour-home-button-text);
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 0;
  transition: all .2s linear;
  position: absolute;
  bottom: 0;
  left: 0;
}

header .navigation-bar-top .navigation-bar-top--home-container .navigation-bar-top--home:hover:before {
  opacity: 1;
  height: 100%;
}

@media (max-width: 1200px) {
  header .navigation-bar-top .navigation-bar-top--home-container {
    min-width: 0;
  }
}

header .navigation-bar-top .navigation-bar-top--items {
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
  display: flex;
}

header .navigation-bar-top .navigation-bar-top--items .header-top--logo-mobile {
  background-color: var(--colour-content-background);
  border-radius: var(--border-radius-small);
  margin-left: var(--padding);
  flex-shrink: 1;
  align-self: stretch;
  align-items: center;
  padding: 2px;
  display: flex;
}

@media (max-width: 700px) {
  header .navigation-bar-top .navigation-bar-top--items .header-top--logo-mobile {
    margin-left: 0;
  }
}

header .navigation-bar-top .navigation-bar-top--items .header-top--logo-mobile > * {
  align-items: center;
  height: 100%;
  display: flex;
}

header .navigation-bar-top .navigation-bar-top--items .header-top--logo-mobile img {
  aspect-ratio: 4;
  object-fit: contain;
  object-position: center center;
  width: 25vw;
  height: auto;
  display: block;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu {
  margin-left: var(--padding-tight);
  padding: .25rem var(--padding) .25rem 0;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: stretch;
  transition: padding .2s ease-out;
  display: flex;
  position: relative;
}

@media (max-width: 1200px) {
  header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu {
    padding-left: 0;
    padding-right: 0;
  }

  header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > :first-child {
    display: none;
  }
}

@media (max-width: 1000px) {
  header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu {
    flex-grow: 0;
    margin-left: 0;
  }

  header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > * {
    flex-grow: 1;
  }

  header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > :nth-child(2) {
    padding-left: 0;
  }

  header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu * > .svg {
    width: 2rem;
  }
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > * {
  white-space: nowrap;
  color: var(--colour-primary-text);
  border: 0;
  border-right: 2px solid var(--colour-primary-text);
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  align-items: center;
  column-gap: .5rem;
  padding: 0 1rem;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  position: relative;
}

@media (max-width: 700px) {
  header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > * {
    flex-direction: column;
    justify-content: flex-end;
  }
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > button:after {
  content: "";
  background-color: var(--colour-secondary);
  transform-origin: top;
  width: 100%;
  height: .5rem;
  transition: all .2s ease-out;
  position: absolute;
  bottom: -1.25rem;
  left: 0;
  transform: scaleY(0);
}

@media (max-width: 1000px) {
  header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > button:after {
    display: none;
  }
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > button:hover {
  filter: none;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > button:hover:after {
  transform: scaleY(1);
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > button.active:after {
  background-color: var(--colour-background);
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  bottom: calc(-.75rem - 1px);
  transform: scaleX(.2)scaleY(1);
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > :first-child {
  cursor: default;
  border-right: 0;
  padding-left: 0;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > :last-child {
  border-right: 0;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > * > .svg {
  max-height: 1.5rem;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > * > .svg * {
  fill: var(--colour-primary-text);
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu > span {
  cursor: default;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--menu span {
  margin-top: .2rem;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--help {
  color: var(--colour-help-button-text);
  border: 2px solid var(--colour-help-button-text);
  box-shadow: var(--shadow);
  background-color: #0000;
  align-self: stretch;
  column-gap: 1rem;
  position: relative;
  overflow: hidden;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--help > .svg * {
  fill: var(--colour-help-button-text);
  transition: all .2s ease-out;
}

@media (max-width: 1200px) {
  header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--help {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--help > svg {
    display: none;
  }
}

@media (max-width: 1000px) {
  header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--help {
    display: none;
  }
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--help:before {
  content: "";
  background-color: var(--colour-home-button-background);
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 0;
  transition: all .2s linear;
  position: absolute;
  bottom: 0;
  left: 0;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--help:hover {
  color: var(--colour-home-button-text);
  filter: brightness();
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--help:hover > .svg * {
  fill: var(--colour-home-button-text);
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--help:hover:before {
  opacity: 1;
  height: 100%;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--search-container {
  background-color: var(--colour-input-header-background);
  border: 1px solid var(--colour-header-input-borders);
  border-radius: var(--border-radius-small);
  justify-content: flex-start;
  align-items: stretch;
  width: calc(100px + 8vw);
  display: flex;
  overflow: hidden;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--search-container > .svg {
  width: 100%;
  max-width: 1.5rem;
  max-height: none;
  margin-left: .5rem;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--search-container > .svg * {
  fill: var(--colour-search-icon) !important;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--search-container > input {
  background-color: #0000;
  border: 0;
  width: 100%;
  padding: 0 0 0 1rem;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--search-container > input:focus {
  outline: #0000;
}

@media (max-width: 1000px) {
  header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--search-container {
    display: none;
  }
}

header .navigation-bar-top .navigation-bar-top--items .toggle-switch {
  filter: none;
}

header .navigation-bar-top .navigation-bar-top--items .toggle-switch .toggle-switch--switch {
  background-color: var(--colour-input-header-background);
  border-color: #fff;
}

header .navigation-bar-top .navigation-bar-top--items .toggle-switch .toggle-switch--switch:before {
  background-color: var(--colour-secondary-against-white);
}

header .navigation-bar-top .navigation-bar-top--items .toggle-switch .toggle-switch.active .toggle-switch--switch {
  background-color: var(--colour-input-background);
}

header .navigation-bar-top .navigation-bar-top--items .toggle-switch .toggle-switch.active .toggle-switch--switch:before {
  background-color: var(--colour-secondary-against-white);
}

header .navigation-bar-top .navigation-bar-top--items .toggle-switch .toggle-switch--label {
  color: var(--colour-primary-text);
}

header .navigation-bar-top .navigation-bar-top--items .toggle-switch .svg * {
  fill: var(--colour-primary-text) !important;
  stroke: var(--colour-primary-text) !important;
}

@media (max-width: 1000px) {
  header .navigation-bar-top .navigation-bar-top--items .toggle-switch {
    display: none;
  }

  header .navigation-bar-top .navigation-bar-top--items .header-top--profile-menu .toggle-switch {
    display: flex;
  }
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--mobile-btn {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0 .5rem 0 0;
  display: none;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--mobile-btn > .svg {
  max-height: 2rem;
}

header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--mobile-btn > .svg * {
  fill: var(--colour-primary-text);
}

@media (max-width: 1000px) {
  header .navigation-bar-top .navigation-bar-top--items .navigation-bar-top--mobile-btn {
    display: inline-block;
  }
}

header .navigation-bar-top input {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

header .navigation-bar-top .drawer-overlay {
  z-index: 999998;
}

header .navigation-bar-top .drawer-container {
  z-index: 999999;
}

@media (max-width: 1000px) {
  header {
    box-shadow: var(--shadow);
    grid-template-columns: auto min-content;
    grid-template-areas: "nav-bar header-items";
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  header .header-top .header-top--logo, header .header-top .header-top--profile-btn > :nth-child(2), header .header-top .header-top--profile-btn > :nth-child(3), header .header-top .header-top--signout-btn {
    display: none;
  }

  header .button {
    padding: .5rem;
  }
}

header.header__admin {
  background: var(--colour-secondary);
}

header.header__admin > .header-top .header-top--items .header-top--profile-btn {
  color: #fff;
}

header.header__admin > .header-top .header-top--items .header-top--profile-btn > .svg > * {
  fill: #fff;
}

header.header__admin .navigation-bar-top {
  justify-content: flex-end;
  display: flex;
}

@media (max-width: 1600px) {
  :root.theme-high-contrast .navigation-bar-top--home-container {
    min-width: 0;
  }
}

:root.theme-dark .navigation-bar-top .toggle-switch--switch {
  border: 1px solid #fff;
}

:root.theme-dark .navigation-bar-top .toggle-switch--switch:before, :root.theme-dark .navigation-bar-top .toggle-switch.active .toggle-switch--switch:before {
  background-color: var(--colour-secondary-against-black);
}

:root {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light dark;
}

@media (prefers-color-scheme: dark) {
  :root {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
  }
}

:root.theme-dark {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
}

body.app main {
  padding-top: calc(5 * var(--padding-tight)  + max(6.25vw + 4px, 40px)  + 1rem + 3px);
  display: block;
}

main {
  grid-template: "navigation content" 1000fr
  / 1fr 4.4fr;
  grid-area: main;
  height: 100%;
  display: grid;
  overflow: hidden;
}

@media (max-width: 1000px) {
  main {
    padding-top: calc(2 * var(--padding-tight)  + 1rem + 4vw);
    display: block;
  }
}

@media (max-width: 700px) {
  main {
    padding-top: calc(2 * var(--padding-tight)  + 2.5rem);
    display: block;
  }
}

main .nav-side-bg-target {
  z-index: 0;
  pointer-events: auto;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

main .nav-side {
  background-color: var(--colour-primary-faded);
  padding: var(--padding) var(--padding);
  z-index: 10;
  grid-area: navigation;
  height: 100%;
  position: relative;
  overflow: auto;
}

@media (max-width: 1000px) {
  main .nav-side {
    z-index: 50;
    background-color: var(--colour-primary);
    border-right: 2px solid var(--colour-secondary);
    transition: transform .2s var(--easing);
    width: -moz-fit-content;
    width: fit-content;
    min-width: 50%;
    max-width: 80%;
    height: 100vh;
    padding-top: 4rem;
    animation: none;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-101%);
    box-shadow: 1rem 0 1rem #0000001a;
  }
}

main .nav-side .skeleton-line {
  margin-bottom: 1.5rem;
}

main .nav-side .skeleton-line:before {
  background-color: #fff3;
}

main .nav-side .nav-side--heading {
  color: var(--colour-primary-faded-text);
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: .75rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  display: inline-block;
  position: relative;
}

@media (max-width: 1200px) {
  main .nav-side .nav-side--heading {
    font-size: 1.2rem;
  }
}

main .nav-side .nav-side--heading:after {
  content: " ";
  background-color: var(--colour-buttons);
  height: .35rem;
  animation: 2s forwards heading-line;
  position: absolute;
  bottom: 0;
  left: 0;
}

main .nav-side .nav-side--close-button {
  position: fixed;
  top: -100rem;
  right: -100rem;
}

@media (max-width: 1000px) {
  main .nav-side .nav-side--close-button {
    top: 1rem;
    right: 1rem;
  }
}

main .nav-side .nav-side--logo {
  object-fit: contain;
  width: 100%;
  height: auto;
  min-height: 4rem;
  margin-bottom: 1rem;
}

main .nav-side .nav-side--menu {
  z-index: 1;
  padding-top: 2rem;
  padding-bottom: 1rem;
  animation: .35s forwards side-menu-dropdown;
  position: relative;
}

main .nav-side .nav-side--menu .nav-side--heading:after {
  width: 3rem;
  animation: none;
}

main .nav-side .nav-side--menu .nav-side--item {
  color: var(--colour-primary-faded-text);
  cursor: pointer;
  padding: .25rem 0;
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none;
  display: block;
  position: relative;
}

@media (min-width: 1200px) {
  main .nav-side .nav-side--menu .nav-side--item {
    padding: .25rem 0;
  }
}

@media (min-width: 1600px) {
  main .nav-side .nav-side--menu .nav-side--item {
    padding: .5rem 0;
  }
}

@media (min-width: 2000px) {
  main .nav-side .nav-side--menu .nav-side--item {
    padding: .75rem 0;
  }
}

main .nav-side .nav-side--menu .nav-side--item .nav-side--item-link {
  grid-template-columns: auto 1.5rem;
  align-items: center;
  column-gap: 1.25rem;
  transition: all .2s ease-out;
  display: grid;
  position: relative;
}

@media (max-width: 1600px) {
  main .nav-side .nav-side--menu .nav-side--item .nav-side--item-link {
    grid-template-columns: auto 15px;
  }
}

main .nav-side .nav-side--menu .nav-side--item .nav-side--item-link > .svg {
  flex-grow: 1;
  flex-shrink: 1;
  width: 1.5rem;
  height: 1.15rem;
  transition: all .2s ease-out;
  position: relative;
  top: 0;
  left: 0;
}

@media (max-width: 1600px) {
  main .nav-side .nav-side--menu .nav-side--item .nav-side--item-link > .svg {
    width: 15px;
    height: 10px;
  }
}

main .nav-side .nav-side--menu .nav-side--item .nav-side--item-link > .svg * {
  fill: var(--colour-primary-faded-text);
}

main .nav-side .nav-side--menu .nav-side--item .nav-side--item-link:before {
  content: "";
  right: calc(100% + var(--padding));
  background-color: var(--colour-primary);
  opacity: 0;
  width: .5rem;
  height: calc(100% + 1rem);
  transition: right .2s ease-out, background-color .5s ease-out;
  position: absolute;
  top: -.5rem;
}

@media (min-width: 1600px) {
  main .nav-side .nav-side--menu .nav-side--item .nav-side--item-link:before {
    height: calc(100% + 2rem);
    top: -1rem;
  }
}

@media (min-width: 2000px) {
  main .nav-side .nav-side--menu .nav-side--item .nav-side--item-link:before {
    height: calc(100% + 3.34rem);
    top: -1.67rem;
  }
}

main .nav-side .nav-side--menu .nav-side--item__heading {
  text-transform: none;
  color: var(--colour-primary-faded-text);
  cursor: default;
  text-transform: uppercase;
  border: none;
  border-bottom: 1px solid var(--colour-primary-faded-text);
  margin-bottom: 1rem;
  padding-top: 2rem;
  font-size: 1.2rem;
  font-weight: 900;
}

main .nav-side .nav-side--menu .nav-side--item__heading .nav-side--item-link:before {
  display: none;
}

main .nav-side .nav-side--menu .nav-side--item:focus {
  outline: 0;
}

main .nav-side .nav-side--menu .active {
  border-bottom: #0000;
}

main .nav-side .nav-side--menu .active > .nav-side--item-link {
  border: 0;
}

main .nav-side .nav-side--menu .active > .nav-side--item-link > .svg {
  transform: scaleY(-1);
}

main .nav-side .nav-side--menu .active .nav-side--item-link:before {
  right: calc(100% + var(--padding)  - .5rem + 1px);
  background-color: var(--colour-primary-faded-text);
  opacity: 1;
  animation: 1s both bounce-right-squash !important;
}

main .nav-side .nav-side--menu .active .nav-side--sub-menu {
  padding-top: var(--padding-tight);
  padding-bottom: var(--padding-tight);
  height: -moz-fit-content;
  height: fit-content;
}

@media (max-width: 1600px) {
  main .nav-side .nav-side--menu .active .nav-side--sub-menu {
    padding: .5rem 1rem;
  }
}

main .nav-side .nav-side--menu .active .nav-side--sub-menu:empty {
  display: none;
}

main .nav-side .nav-side--menu .nav-side--item:hover {
  filter: none;
}

main .nav-side .nav-side--menu .nav-side--item:hover .nav-side--item-link:before {
  right: calc(100% + var(--padding)  - .5rem + 1px);
  opacity: 1;
  animation: .2s both restore-scale-y;
}

main .nav-side .nav-side--menu .nav-side--item:last-child {
  border-bottom: 0;
}

main .nav-side .nav-side--menu .nav-side--sub-menu {
  border-radius: var(--border-radius-small);
  background-color: var(--colour-content-background);
  padding: 0 var(--padding-tight);
  cursor: default;
  z-index: 15;
  box-shadow: var(--shadow);
  transition: all .5s var(--easing);
  text-transform: none;
  flex-direction: column;
  width: 100%;
  height: 0;
  font-weight: normal;
  display: flex;
  position: absolute;
  top: 100%;
  overflow: hidden;
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item {
  color: var(--colour-links);
  cursor: pointer;
  border-bottom: 1px solid var(--colour-links);
  padding: .5rem 0;
  font-size: 1rem;
  position: relative;
}

@media (max-width: 1600px) {
  main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item {
    font-size: .8rem;
  }
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-item-link {
  color: var(--colour-links);
  align-items: center;
  column-gap: 1.25rem;
  text-decoration: none;
  display: flex;
  position: relative;
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-item-link > a, main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-item-link > button {
  width: 100%;
  display: block;
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-item-link > .svg {
  width: 1rem;
  height: 1rem;
  transition: all .2s ease-out;
  position: relative;
  top: 0;
  left: 0;
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-item-link > .svg * {
  fill: var(--colour-links);
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-item-link:focus {
  outline: 0;
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-menu {
  border-top-left-radius: var(--border-radius-small);
  border-top-right-radius: var(--border-radius-small);
  background-color: var(--colour-buttons);
  width: 100%;
  height: 0;
  padding: 0 1rem;
  transition: all .2s ease-out;
  position: static;
  overflow: hidden;
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-menu .nav-side--sub-item {
  color: var(--colour-buttons-text);
  border: 0;
  font-size: .9rem;
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-item-link {
  color: var(--colour-buttons-text);
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-item-link > a, main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-item-link > button {
  width: 100%;
  display: block;
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item:before {
  content: "";
  top: 0;
  right: calc(100% + var(--padding-tight)  - .4rem);
  background-color: var(--colour-primary-against-white);
  opacity: 0;
  width: .4rem;
  height: 100%;
  transition: all .2s ease-out;
  position: absolute;
  transform: translateX(-101%);
}

@media (max-width: 1600px) {
  main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item:before {
    right: calc(100% + .6rem);
  }
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item:hover:before {
  opacity: 1;
  transform: translateX(0);
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item:last-child {
  border: 0;
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item.active > .nav-side--sub-menu {
  height: -moz-fit-content;
  height: fit-content;
  margin-top: .5rem;
  padding: .5rem 1rem;
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item.active > .nav-side--sub-menu > .nav-side--sub-item:before {
  content: "";
  background-color: var(--colour-primary-against-white-text);
  opacity: 0;
  -webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  width: .4rem;
  height: .6rem;
  transition: all .2s ease-out;
  position: absolute;
  top: 50%;
  right: calc(100% + .6rem);
  transform: translate3d(-101%, -50%, 0);
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item.active > .nav-side--sub-menu > .nav-side--sub-item:hover:before {
  opacity: 1;
  transform: translate3d(0, -50%, 0);
}

main .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item.active .nav-side--sub-menu:empty, main .nav-side .nav-side--menu .nav-side--sub-menu:empty {
  display: none;
}

main .nav-side .nav-side--menu:first-of-type {
  padding-top: 0;
}

main .nav-side .nav-side--menu.inactive {
  z-index: 0;
  padding: 0;
  animation: none;
}

main .nav-side .nav-side--menu.inactive .nav-side--heading {
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  display: block;
}

main .nav-side .nav-side--menu.inactive .nav-side--heading:after {
  width: 2rem;
  transition: width .2s ease-out;
  animation: none;
}

main .nav-side .nav-side--menu.inactive .nav-side--heading:hover:after {
  width: 3rem;
}

main .nav-side .nav-side--menu.inactive .nav-side--item {
  display: none;
}

main .nav-side .button {
  margin-bottom: var(--padding);
  justify-content: stretch;
  gap: 0;
  width: 100%;
  padding: .5rem;
}

main .nav-side .button > :first-child {
  flex-shrink: 1;
  align-self: flex-start;
  margin-left: 0;
}

main .nav-side .button > span {
  flex-grow: 1;
}

@media (max-width: 1000px) {
  main .nav-side.active {
    transform: translateX(0);
  }
}

main .content-area {
  padding: var(--padding);
  grid-area: content;
  grid-template-rows: auto min-content;
  grid-template-columns: 1fr;
  min-height: 100%;
  display: grid;
  position: relative;
}

main .content-area .main-body-scrollable {
  padding: 0 var(--padding) var(--padding) var(--padding);
  box-sizing: border-box;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: auto;
}

main .content-area .main-body-scrollable > :first-child {
  padding-top: var(--padding);
}

main .content-area .main-body-scrollable > .throbber {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0)scale(.5);
}

main .content-area .main-body-scrollable > .form__columns {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media (max-width: 1200px) {
  main .content-area .main-body-scrollable > .form__columns {
    grid-template-columns: 1fr;
  }
}

main .content-area .main-body-scrollable[data-disabled="true"] {
  opacity: .5;
  pointer-events: none;
}

main .content-area .main-body-space-rows {
  flex-direction: column;
  row-gap: 1rem;
  display: flex;
}

main .content-area .main-body-space-rows > h2 {
  margin: 0;
}

main .content-area .main-body--bottom-buttons {
  margin-top: var(--padding);
  justify-content: center;
  align-items: center;
  column-gap: var(--padding-tight);
  display: flex;
}

main .content-area .main-body--bottom-buttons button {
  justify-content: center;
  min-width: 12rem;
}

main .content-area .main-body--bottom-buttons button.button__invert {
  min-width: 0;
}

main .content-area .main-content {
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  padding: var(--padding);
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-outer);
  grid-column-gap: var(--padding);
  grid-template-columns: 2.4fr 1fr;
  height: 100%;
  display: grid;
  position: relative;
  overflow: hidden;
}

main .content-area .main-content .main-content--content-section img {
  max-width: 100%;
  height: auto;
  margin: 1rem auto;
  display: block;
}

main .content-area .main-content .main-content--content-column {
  grid-template-rows: min-content auto;
  height: 100%;
  display: grid;
  overflow: hidden;
}

main .content-area .main-content .main-content--content-column > h1 {
  margin-left: 2rem;
}

@media (max-width: 1200px), (max-width: 1600px) and (max-height: 1000px), (max-height: 850px) {
  main .content-area .main-content .main-content--content-column > h1 {
    margin-left: 0;
  }
}

main .content-area .main-content .main-content--content-column .main-content--scrollbox {
  position: relative;
}

main .content-area .main-content .main-content--content-column .main-content--scrollbox .main-content--content-container {
  width: 100%;
  height: 100%;
  padding-left: 2rem;
  position: absolute;
  overflow: auto;
}

main .content-area .main-content .main-content--content-column .main-content--scrollbox .main-content--content-container .simplebar-track.simplebar-vertical {
  left: 0;
}

@media (max-width: 1200px), (max-width: 1600px) and (max-height: 1000px), (max-height: 850px) {
  main .content-area .main-content .main-content--content-column .main-content--scrollbox .main-content--content-container {
    padding-left: 0;
    position: static;
  }
}

main .content-area .main-content .main-content--content-column .main-content--scrollbox .main-content--content-container .main-content--content-content {
  row-gap: var(--padding);
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  display: flex;
}

@media (max-width: 1200px), (max-width: 1600px) and (max-height: 1000px), (max-height: 850px) {
  main .content-area .main-content .main-content--content-column {
    height: auto;
  }
}

main .content-area .main-content .main-content--image-column {
  justify-content: stretch;
  align-items: flex-end;
  gap: var(--padding);
  flex-direction: column;
  min-width: 250px;
  height: 100%;
  padding-bottom: 4rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

main .content-area .main-content .main-content--image-column .main-content--image {
  border-radius: var(--border-radius-inner);
  object-fit: cover;
  width: 100%;
  height: 50vh;
  max-height: 100%;
}

main .content-area .main-content .main-content--image-column .main-content--image[data-no-crop="true"] {
  object-fit: contain;
  height: auto;
}

@media (max-width: 1200px), (max-width: 1600px) and (max-height: 1000px), (max-height: 850px) {
  main .content-area .main-content .main-content--image-column {
    height: auto;
  }
}

main .content-area .main-content .main-content--download-btn {
  margin-left: auto;
  margin-top: var(--padding);
  margin-right: var(--padding);
}

main .content-area .main-content .main-content--image-column + .main-content--download-btn {
  bottom: var(--padding);
  right: var(--padding);
  margin-right: 0;
  position: absolute;
}

@media (max-width: 1200px), (max-width: 1600px) and (max-height: 1000px), (max-height: 850px) {
  main .content-area .main-content {
    row-gap: var(--mobile-section-gap);
    flex-direction: column;
    height: auto;
    display: flex;
  }

  main .content-area .main-content .main-content--image-column .main-content--image {
    max-height: 50vh;
  }
}

main .content-area .main-content__no-image {
  grid-template-rows: auto min-content;
  grid-template-columns: 1fr;
}

main .content-area .button {
  box-shadow: var(--shadow);
}

main .content-area .paging {
  padding: var(--padding) var(--padding) 0 var(--padding);
  column-gap: var(--padding);
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: "pagingLeft actionButton pagingRight";
  align-items: center;
  max-height: min-content;
  font-size: 1.2rem;
  font-weight: bold;
  display: grid;
}

@media (max-width: 1000px) {
  main .content-area .paging {
    grid-template-columns: repeat(2, auto);
    grid-template-areas: "actionButton actionButton"
                         "pagingLeft pagingRight";
  }
}

main .content-area .paging > :first-child {
  grid-area: pagingLeft;
}

main .content-area .paging > :nth-child(2):not(:empty) {
  grid-area: actionButton;
}

@media (max-width: 1000px) {
  main .content-area .paging > :nth-child(2):not(:empty) {
    margin-bottom: calc(var(--padding) * 2);
  }
}

main .content-area .paging > :last-child {
  grid-area: pagingRight;
}

main .content-area .paging > * {
  justify-self: center;
}

main .content-area .paging > a, main .content-area .paging > button.button-unstyled {
  color: var(--colour-content-headings);
  cursor: pointer;
  column-gap: .75rem;
  width: 100%;
  min-width: 0;
  text-decoration: none;
  transition: column-gap .5s ease-out;
  display: flex;
}

main .content-area .paging > a > svg, main .content-area .paging > button.button-unstyled > svg {
  transition: transform .2s ease-out;
  position: relative;
  top: .05rem;
}

main .content-area .paging > a > svg > *, main .content-area .paging > button.button-unstyled > svg > * {
  fill: var(--colour-content-headings);
  stroke: var(--colour-content-headings);
}

main .content-area .paging > a > svg:first-child, main .content-area .paging > button.button-unstyled > svg:first-child {
  transform: scaleX(-1);
}

main .content-area .paging > a > .paging--label, main .content-area .paging > button.button-unstyled > .paging--label {
  width: max-content;
  display: block;
}

@media (max-width: 1200px) {
  main .content-area .paging > a > .paging--label, main .content-area .paging > button.button-unstyled > .paging--label {
    font-size: .8rem;
  }
}

main .content-area .paging > :first-child {
  justify-self: start;
}

main .content-area .paging > :last-child {
  text-align: right;
  justify-content: flex-end;
  justify-self: end;
}

@media (max-width: 1200px), (max-width: 1600px) and (max-height: 1000px), (max-height: 850px) {
  main .content-area .paging {
    padding-left: var(--padding);
  }
}

@media (max-width: 1000px) {
  main .content-area .paging {
    margin-top: .5rem;
  }
}

main .content-area .paging button.button {
  font-size: 1.2rem;
}

main .content-area .paging > a:hover, main .content-area .paging > button.button-unstyled:hover {
  column-gap: 1rem;
}

main .content-area .paging > a:hover > svg:first-child, main .content-area .paging > button.button-unstyled:hover > svg:first-child {
  transform: translateX(-.5rem)scaleX(-1);
}

main .content-area .paging > a:hover > svg:last-child, main .content-area .paging > button.button-unstyled:hover > svg:last-child {
  transform: translateX(.5rem);
}

main .content-area .paging__padded-left {
  padding-left: calc(2rem + var(--padding));
}

@media (max-width: 1200px) {
  main .content-area .paging__padded-left {
    padding-left: var(--padding);
  }
}

main .content-area .paging > :disabled {
  opacity: .5;
  pointer-events: none;
  filter: grayscale();
}

@media (max-width: 1000px) {
  main .content-area {
    height: auto;
  }
}

main .columns {
  grid-column-gap: var(--padding);
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media (max-width: 1200px) {
  main .columns {
    grid-template-columns: 1fr;
  }
}

:root.theme-dark .main-content--image {
  filter: brightness(.8) saturate(80%);
}

:root.theme-dark .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-item-link {
  color: var(--colour-primary-against-white-text);
}

@media (min-width: 1000px) {
  main.menu-top {
    min-height: var(--top-menu-height);
    grid-template: "navigation" min-content
                   "content"
                   / 1fr;
    transition: min-height .2s ease-out;
  }

  main.menu-top .nav-side {
    padding: .5rem var(--padding);
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem 2rem;
    animation: .6s forwards header-slide-down;
    display: flex;
    overflow: visible;
  }

  main.menu-top .nav-side .skeleton-line {
    margin-bottom: 0;
  }

  main.menu-top .nav-side .nav-side--menu {
    padding: 0;
    animation: none;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--heading {
    cursor: pointer;
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--heading:after {
    display: none;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--heading:before {
    transform-origin: 0 0 0;
    width: 100%;
    height: .5rem;
    top: calc(100% + .5rem);
    bottom: auto;
    left: 0;
    right: 0;
    transform: scaleX(0);
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items {
    border-radius: var(--border-radius-small);
    background-color: var(--colour-content-background);
    padding: 0 var(--padding-tight);
    margin-left: calc(0px - var(--padding-tight));
    cursor: default;
    z-index: 15;
    box-shadow: var(--shadow);
    transition: all .5s var(--easing);
    text-transform: none;
    z-index: 15;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    flex-direction: column;
    width: max-content;
    margin-top: 0;
    font-weight: normal;
    animation: .25s forwards side-menu-dropdown;
    display: flex;
    position: absolute;
    top: calc(100% + .5rem);
    overflow: hidden;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--item {
    color: var(--colour-links);
    cursor: pointer;
    border-bottom: 1px solid var(--colour-links);
    text-transform: none;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 1.25rem;
    padding: 0;
    font-weight: normal;
    text-decoration: none;
    display: flex;
    position: relative;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--item > .svg {
    width: 1rem;
    height: 1rem;
    transition: all .2s ease-out;
    position: relative;
    top: 0;
    left: 0;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--item > .svg * {
    fill: var(--colour-links);
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--item .nav-side--item-link {
    column-gap: .5rem;
    padding: .5rem 0;
    position: static;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--item .nav-side--item-link > .svg {
    width: 1rem;
    height: 1rem;
    transition: all .2s ease-out;
    position: relative;
    top: 0;
    left: 0;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--item .nav-side--item-link > .svg * {
    fill: var(--colour-links);
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--item .nav-side--item-link:before {
    height: 100%;
    top: 0;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--item .svg {
    max-width: 1.2rem;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--item .svg {
    max-width: 1rem;
  }
}

@media (min-width: 1000px) {
  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--item:last-child {
    border-bottom: 0;
    padding-bottom: .5rem;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--item__heading {
    font-size: inherit;
    text-transform: uppercase;
    cursor: default;
    border-bottom: 0;
    padding-top: .5rem;
    padding-bottom: 0;
    font-weight: bold;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--sub-menu {
    box-shadow: none;
    z-index: 15;
    border-radius: 0;
    width: max-content;
    max-width: 15rem;
    margin-top: 0;
    padding: 0;
    font-size: 1rem;
    display: none;
    position: static;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--sub-menu > * {
    padding-left: 1rem;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--sub-menu .nav-side--sub-menu {
    max-width: 100%;
    padding: 0;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--sub-menu .nav-side--sub-menu .nav-side--sub-item {
    padding: .25rem 1rem;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--sub-menu .nav-side--sub-menu .nav-side--sub-item:first-child {
    margin-top: .25rem;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--sub-menu .nav-side--sub-menu .nav-side--sub-item:last-child {
    margin-bottom: .25rem;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--sub-menu .nav-side--sub-menu .nav-side--sub-item:before {
    left: 0;
    right: auto;
  }

  main.menu-top .nav-side .nav-side--menu .nav-side--menu-items .nav-side--item.active .nav-side--sub-menu {
    animation: .25s forwards side-menu-dropdown;
    display: block;
  }

  main.menu-top .nav-side .nav-side--menu .active .nav-side--item-link:before {
    background-color: var(--colour-primary);
    animation: none !important;
  }

  main.menu-top .nav-side .nav-side--menu.inactive .nav-side--heading {
    padding: 0;
  }

  main.menu-top .nav-side .nav-side--menu.inactive .nav-side--menu-items {
    height: 0;
    display: none;
  }
}

.image-thumbs-browse {
  gap: var(--padding);
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  display: grid;
}

.image-thumbs-browse .image-thumbs-browse--item-container {
  position: relative;
}

.image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item {
  filter: brightness();
  flex-direction: column;
  align-items: center;
  row-gap: .25rem;
  display: flex;
  position: relative;
}

.image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item .image-thumbs-browse--edit-icons {
  opacity: 0;
  cursor: default;
  transition: all .2s ease-out;
  position: absolute;
  top: .2rem;
  right: .2rem;
}

.image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item .image-thumbs-browse--caption {
  text-align: center;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
}

.image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item .image-thumbs-browse--caption > .editable-text {
  width: 100%;
}

.image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item > img {
  aspect-ratio: 3 / 2;
  object-fit: cover;
  background-color: #80808033;
  width: 100%;
  transition: all .2s ease-out;
}

.image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item > img.selected {
  outline: 4px solid var(--colour-secondary-against-white);
}

.image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item > img.clickable {
  cursor: pointer;
}

.image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item img.skeleton-image {
  aspect-ratio: 3 / 2;
}

.image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item > svg, .image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item img.skeleton-image.image-thumbs-browse--item-skeleton-small {
  aspect-ratio: 9 / 12;
  width: 50%;
  max-width: 8rem;
  padding: .5rem;
  transition: all .2s ease-out;
}

.image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item > img.skeleton-image.image-thumbs-browse--item-skeleton-small {
  border-radius: 1rem;
  margin: .5rem;
  padding: 0;
}

.image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item:hover img, .image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item:hover svg {
  filter: brightness(1.1);
}

.image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--item:hover > .image-thumbs-browse--edit-icons {
  opacity: 1;
}

.image-thumbs-browse .image-thumbs-browse--item-container .image-thumbs-browse--tags {
  position: absolute;
  top: .2rem;
  left: .2rem;
}

.image-thumbs-browse .image-thumbs-browse--item-container:focus {
  z-index: 1;
}

.image-thumbs-browse__files {
  grid-template-columns: repeat(5, 1fr);
}

.image-thumbs-browse__busy {
  opacity: .5;
  pointer-events: none;
}

.image-preview-container {
  z-index: 99999999;
  background: #000000e6;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  animation: 1s forwards fade-in;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.image-preview-container > .throbber {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0)scale(.5);
}

.image-preview-container > img {
  z-index: 2;
  object-fit: contain;
  width: auto;
  min-width: 15rem;
  max-width: 90vw;
  height: auto;
  min-height: 8rem;
  max-height: 90vh;
  animation: .5s fade-in;
  position: relative;
}

.article-edit-canvas {
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  border: 1px solid var(--colour-borders);
  box-shadow: var(--shadow);
  min-height: 10rem;
}

.article-edit-canvas .article-edit--section {
  border-bottom: 1px dotted var(--colour-borders);
  justify-content: space-between;
  align-items: flex-start;
  min-height: 26rem;
  padding: 3rem 0 3rem 5rem;
  display: flex;
}

.article-edit-canvas .article-edit--section .article-edit--section-content {
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  display: flex;
}

.article-edit-canvas .article-edit--section .article-edit--section-content > :first-child {
  flex: 75%;
}

.article-edit-canvas .article-edit--section .article-edit--section-content .ProseMirror {
  min-height: 20rem;
}

.article-edit-canvas .article-edit--section .article-edit--section-content .article-edit--section-content-text img {
  max-width: 100%;
  height: auto;
  margin: 1rem auto;
  display: block;
}

.article-edit-canvas .article-edit--section .article-edit--section-content .article-edit--section-content-image {
  aspect-ratio: 4 / 6;
  flex: 25%;
  max-width: min(25%, 500px);
  position: relative;
}

.article-edit-canvas .article-edit--section .article-edit--section-content .article-edit--section-content-image img {
  border-radius: var(--border-radius-inner);
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.article-edit-canvas .article-edit--section .article-edit--section-content .article-edit--section-content-image img[data-no-crop="true"] {
  object-fit: contain;
}

.article-edit-canvas .article-edit--section .article-edit--section-content .article-edit--section-content-image:hover .edit-icons--hoverable {
  opacity: 1;
}

.article-edit-canvas .article-edit--section .article-edit--section-actions {
  opacity: 1;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  width: 7rem;
  padding: 1rem 0;
  transition: opacity .2s ease-out;
  display: flex;
  position: sticky;
  top: 0;
}

.article-edit-canvas .article-edit--section p:first-child {
  margin-top: 0;
}

.article-edit-canvas .article-edit--section p:last-child {
  margin-bottom: 0;
}

.article-edit-canvas .article-edit--section__fixed-fade {
  padding-bottom: 0;
}

.article-edit-canvas .article-edit--section__fixed-fade .article-edit--section-content {
  --mask-image: linear-gradient(180deg, #000 70%, #0000 90%);
  -webkit-mask-image: var(--mask-image);
  -moz-mask-image: var(--mask-image);
  -webkit-mask-image: var(--mask-image);
  mask-image: var(--mask-image);
  column-gap: 0;
  min-height: 15rem;
  overflow: hidden;
}

.article-edit-canvas .article-edit--section:last-child {
  border-bottom: 0;
}

.article-edit-canvas .article-edit--section[data-moved="up"] {
  animation: .35s forwards up-into-place;
}

.article-edit-canvas .article-edit--section[data-moved="down"] {
  animation: .35s forwards down-into-place;
}

.employee-db-footer {
  margin-top: var(--padding-tight);
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  display: grid;
}

.employee-db-footer > :last-child, .employee-db-footer > :first-child {
  white-space: nowrap;
  font-size: 1.2rem;
  text-decoration: none;
}

.employee-db-footer > :last-child {
  text-align: right;
}

.employee-db-footer > :first-child > :before {
  content: "< ";
}

.employee-db-footer > :last-child > :after {
  content: " >";
}

body.app main .nav-side--logo {
  margin: var(--padding) auto 2rem auto;
  max-width: 200px;
  display: block;
}

body.app main .content-area, body.app main .content-area .main-body-scrollable {
  padding: 0 var(--padding-tight) var(--padding-tight) var(--padding-tight);
}

body.app .dashboard-container {
  grid-row-gap: var(--padding-tight) !important;
}

body.app .dashboard-container .dashboard--item.dashboard--item__hello {
  padding-top: 0;
}

.dashboard-container[data-no-absence="true"] {
  grid-template-rows: repeat(7, auto);
  grid-template-areas: "hello announce content-4"
                       "hello announce content-4"
                       "content-3 announce content-4"
                       "content-3 content-1 content-4"
                       "content-2 content-1 content-0"
                       "content-2 content-1 content-0"
                       "content-2 content-1 content-0";
}

@media (max-width: 1200px) {
  .dashboard-container[data-no-absence="true"] {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "hello content-1"
                         "announce content-1"
                         "announce content-2"
                         "announce content-2"
                         "content-3 content-2"
                         "content-3 content-4"
                         "content-0 content-4";
  }
}

@media (max-width: 700px) {
  .dashboard-container[data-no-absence="true"] {
    grid-row-gap: 1rem;
    grid-template-rows: repeat(8, auto);
    grid-template-columns: 1fr;
    grid-template-areas: "hello"
                         "content-0"
                         "announce"
                         "content-1"
                         "content-2"
                         "content-3"
                         "content-4";
  }
}

.dashboard-container {
  grid-column-gap: var(--padding);
  grid-row-gap: var(--padding);
  grid-template-rows: repeat(8, auto);
  grid-template-columns: repeat(3, 3.3fr);
  grid-template-areas: "hello content-1 content-4"
                       "content-0 content-1 content-4"
                       "content-0 content-1 content-4"
                       "content-0 content-1 holiday"
                       "announce content-2 holiday"
                       "announce content-2 holiday"
                       "announce content-3 holiday"
                       "announce content-3 holiday";
  height: 100%;
  animation: 0s 2.5s forwards init-overflow;
  display: grid;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .dashboard-container {
    grid-column-gap: var(--padding-tight);
    grid-row-gap: var(--padding-tight);
    grid-template-rows: repeat(6, auto);
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "hello content-3"
                         "content-0 content-3"
                         "content-0 content-3"
                         "announce content-4"
                         "content-2 holiday"
                         "content-1 holiday";
  }
}

@media (max-width: 700px) {
  .dashboard-container {
    grid-template-rows: repeat(8, auto);
    grid-template-columns: 1fr;
    grid-template-areas: "hello"
                         "content-0"
                         "announce"
                         "holiday"
                         "content-1"
                         "content-2"
                         "content-3"
                         "content-4";
  }
}

@media (max-height: 50vw), (max-height: 850px) {
  .dashboard-container {
    gap: 1rem;
  }
}

.dashboard-container .dashboard--item {
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-inner);
  padding: var(--padding);
  grid-row-gap: .5rem;
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  opacity: 0;
  align-content: stretch;
  align-items: center;
  width: 100%;
  animation: .5s forwards fade-in-shrink;
  display: grid;
}

@media (max-width: 1200px) {
  .dashboard-container .dashboard--item {
    grid-row-gap: 1rem;
    padding: 1.5rem;
  }
}

@media (max-height: 1000px) {
  .dashboard-container .dashboard--item {
    padding-top: .7rem;
    padding-bottom: .7rem;
  }
}

@media (max-width: 1000px) {
  .dashboard-container .dashboard--item {
    padding: var(--padding);
  }
}

.dashboard-container .dashboard--item .dashboard--heading {
  color: var(--colour-buttons);
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  padding-bottom: .7rem;
  position: relative;
}

.dashboard-container .dashboard--item .dashboard--heading:after {
  content: "";
  background-color: var(--colour-header-primary);
  width: 3rem;
  height: .35rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.dashboard-container .dashboard--item .dashboard--hello-container {
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 1rem;
  width: 100%;
  display: flex;
}

@media (max-width: 700px) {
  .dashboard-container .dashboard--item .dashboard--hello-container {
    align-items: center;
  }
}

.dashboard-container .dashboard--item .dashboard--hello-container .dashboard--heading {
  margin-bottom: 1rem;
  font-size: 1.6rem;
}

.dashboard-container .dashboard--item .dashboard--hello-container .dashboard--profile-pic {
  aspect-ratio: 1;
  color: var(--colour-buttons);
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 3rem;
  max-height: 3rem;
}

.dashboard-container .dashboard--item .dashboard--hello-container .dashboard--profile-pic .dashboard--profile-pic--img {
  border-radius: var(--border-radius-small);
  object-fit: cover;
  min-width: 45px;
  display: block;
}

.dashboard-container .dashboard--item .dashboard--hello-container .dashboard--profile-pic .dashboard--profile-pic--svg {
  background-color: var(--colour-secondary-faded-more);
  border-radius: var(--border-radius-small);
  min-width: 45px;
  padding: .5rem;
}

.dashboard-container .dashboard--item .dashboard--hello-container .dashboard--profile-pic * {
  aspect-ratio: 1;
  fill: var(--colour-buttons);
  width: 100%;
  max-width: 3rem;
  height: auto;
  max-height: 3rem;
}

@media (max-width: 700px) {
  .dashboard-container .dashboard--item .dashboard--hello-container .dashboard--profile-pic * {
    max-width: 7rem;
    max-height: 7rem;
  }

  .dashboard-container .dashboard--item .dashboard--hello-container .dashboard--profile-pic {
    border-radius: var(--border-radius-inner);
    max-width: 7rem;
    max-height: 7rem;
  }
}

.dashboard-container .dashboard--item a, .dashboard-container .dashboard--item .button-unstyled {
  color: var(--colour-buttons);
  align-self: stretch;
  align-items: center;
  gap: .5rem;
  min-width: 0;
  padding: .5rem 0;
  text-decoration: none;
  display: flex;
}

.dashboard-container .dashboard--item a > .icon *, .dashboard-container .dashboard--item a .icon-small *, .dashboard-container .dashboard--item .button-unstyled > .icon *, .dashboard-container .dashboard--item .button-unstyled .icon-small * {
  fill: var(--colour-buttons) !important;
}

.dashboard-container .dashboard--item a > .icon-small, .dashboard-container .dashboard--item .button-unstyled > .icon-small {
  min-width: .5rem;
  transition: transform .2s ease-out;
}

.dashboard-container .dashboard--item a:last-of-type, .dashboard-container .dashboard--item .button-unstyled:last-of-type {
  border-bottom: 0;
}

.dashboard-container .dashboard--item a:hover, .dashboard-container .dashboard--item .button-unstyled:hover {
  filter: none;
  font-weight: bold;
}

.dashboard-container .dashboard--item a:hover > .icon-small, .dashboard-container .dashboard--item .button-unstyled:hover > .icon-small {
  transform: translateX(.5rem);
}

.dashboard-container .dashboard--item > .button {
  height: -moz-fit-content;
  height: fit-content;
}

.dashboard-container .dashboard--item [data-sticky="true"] {
  font-weight: bold;
}

.dashboard-container .dashboard--item:nth-child(2) {
  animation-delay: .125s;
}

.dashboard-container .dashboard--item:nth-child(3) {
  animation-delay: .25s;
}

.dashboard-container .dashboard--item:nth-child(4) {
  animation-delay: .375s;
}

.dashboard-container .dashboard--item:nth-child(5) {
  animation-delay: .5s;
}

.dashboard-container .dashboard--item:nth-child(6) {
  animation-delay: .625s;
}

.dashboard-container .dashboard--item:nth-child(7) {
  animation-delay: .75s;
}

.dashboard-container .dashboard--item:nth-child(8) {
  animation-delay: .875s;
}

.dashboard-container .dashboard--item__alt {
  background-color: var(--colour-secondary);
  color: var(--colour-secondary-text);
  border: 0;
}

.dashboard-container .dashboard--item__alt .dashboard--heading {
  color: var(--colour-buttons-text);
}

.dashboard-container .dashboard--item__alt .button {
  border: 2px solid var(--colour-buttons-text);
}

.dashboard-container .dashboard--item__hello {
  background-color: #0000;
  border: 0;
  grid-area: hello;
  padding: 0;
}

@media (max-width: 1000px) {
  .dashboard-container .dashboard--item__hello {
    padding-top: var(--padding-tight);
  }
}

.dashboard-container .dashboard--item__content-0 {
  grid-area: content-0;
}

.dashboard-container .dashboard--item__content-1 {
  grid-area: content-1;
}

.dashboard-container .dashboard--item__content-2 {
  grid-area: content-2;
}

.dashboard-container .dashboard--item__content-3 {
  grid-area: content-3;
}

.dashboard-container .dashboard--item__content-4 {
  grid-area: content-4;
}

.dashboard-container .dashboard--item__holiday {
  grid-area: holiday;
}

.dashboard-container .dashboard--item__announce {
  grid-area: announce;
}

.dashboard-container .holiday-summary {
  grid-row-gap: 1rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  row-gap: 1rem;
  display: flex;
}

@media (max-width: 1000px) {
  .dashboard-container .holiday-summary {
    justify-content: space-between;
  }
}

.dashboard-container .holiday-summary .holiday-summary--info {
  grid-row-gap: .5rem;
  grid-template-columns: 3fr 2fr;
  display: grid;
}

.dashboard-container .holiday-summary .holiday-summary--info .holiday-summary--info-value {
  font-weight: bold;
}

.dashboard-container .holiday-summary .holiday-summary--buttons {
  flex-wrap: wrap;
  align-items: stretch;
  gap: 1rem;
  display: flex;
}

@media (max-height: 50vw), (max-height: 850px) {
  .dashboard-container .holiday-summary .holiday-summary--buttons {
    column-gap: var(--padding);
    flex-direction: row;
    align-items: center;
  }
}

.dashboard-container .holiday-summary .holiday-summary--buttons > a, .dashboard-container .holiday-summary .holiday-summary--buttons > .button__transparent {
  border-bottom: 0;
  padding: 0;
}

.dashboard-container .holiday-summary hr {
  margin: 0;
  display: none;
}

.dashboard-container[data-busy="true"] {
  animation: none;
}

.dashboard-container[data-busy="true"] .dashboard--item {
  opacity: 1;
  background-color: #0000;
  border: 0;
  animation: none;
  position: relative;
}

.holiday-summary--allocation {
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(4, 1fr) auto;
  width: 100%;
  display: grid;
}

.holiday-summary--allocation .holiday-summary--allocation-label {
  grid-column: 1 / 7;
}

.holiday-summary--allocation .holiday-summary--allocation-item {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: .5rem;
  display: flex;
}

@media (min-width: 1000px) and (max-height: 50vw), (max-height: 850px) {
  .holiday-summary--allocation .holiday-summary--allocation-item {
    grid-template-columns: repeat(5, 1fr) auto;
  }
}

.holiday-summary--allocation .holiday-summary--allocation-item .holiday-summary--allocation-symbol {
  background-color: var(--colour-buttons);
  color: var(--colour-buttons-text);
  cursor: default;
  border-radius: var(--border-radius-small);
  box-shadow: var(--shadow);
  justify-content: center;
  align-items: center;
  width: max(5ch, min(1rem + 5vw, 5.5ch));
  max-width: 100%;
  height: max(5ch, min(1rem + 5vw, 5vh - .7rem));
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
}

@media (min-width: 1600px) {
  .holiday-summary--allocation .holiday-summary--allocation-item .holiday-summary--allocation-symbol {
    width: 5rem;
    height: 3.5rem;
  }
}

@media (max-height: 50vw), (max-height: 850px) {
  .holiday-summary--allocation .holiday-summary--allocation-item .holiday-summary--allocation-symbol {
    width: 3rem;
    height: 2.5rem;
  }
}

@media (max-width: 700px) {
  .holiday-summary--allocation .holiday-summary--allocation-item .holiday-summary--allocation-symbol {
    width: 15vw;
    height: 15vw;
  }
}

.holiday-summary--allocation .holiday-summary--allocation-item .holiday-summary--allocation-symbol__holiday {
  background-color: var(--colour-absence-holiday);
}

.holiday-summary--allocation .holiday-summary--allocation-item .holiday-summary--allocation-symbol__sickness {
  background-color: var(--colour-absence-sickness);
}

.holiday-summary--allocation .holiday-summary--allocation-item .holiday-summary--allocation-symbol__family {
  background-color: var(--colour-absence-family);
}

.holiday-summary--allocation .holiday-summary--allocation-item .holiday-summary--allocation-symbol__other {
  background-color: var(--colour-absence-other);
}

.holiday-summary--allocation .holiday-summary--allocation-item .holiday-summary--allocation-symbol__remaining {
  border: 2px solid var(--colour-buttons);
  color: var(--colour-buttons);
  background-color: #0000;
}

.holiday-summary--allocation .holiday-summary--allocation-item .holiday-summary--allocation-item-label {
  font-size: .8rem;
  display: inline-block;
}

.holiday-summary--allocation .holiday-summary--allocation-item[data-loading="true"] .holiday-summary--allocation-symbol {
  opacity: .5;
  animation: 1.5s linear infinite forwards skeleton-shine-small;
}

.holiday-summary--allocation .holiday-summary--allocation-item:nth-child(6) {
  grid-column-start: 1;
}

.holiday-summary--allocation .holiday-summary--allocation-item:nth-child(6) .holiday-summary--allocation-item-label {
  display: none;
}

@media (min-width: 1000px) and (max-height: 50vw), (min-width: 1000px) and (max-height: 850px) {
  .holiday-summary--allocation .holiday-summary--allocation-item:nth-child(6) {
    grid-column-start: 5;
  }

  .holiday-summary--allocation .holiday-summary--allocation-item:nth-child(6) .holiday-summary--allocation-item-label {
    display: inline-block;
  }
}

.holiday-summary--allocation .holiday-summary--allocation-remaining-label {
  grid-column: 2 / 5;
  align-self: center;
  font-weight: bold;
}

@media (min-width: 1000px) and (max-height: 50vw), (min-width: 1000px) and (max-height: 850px) {
  .holiday-summary--allocation .holiday-summary--allocation-remaining-label {
    display: none;
  }
}

@media (min-width: 1000px) and (max-width: 1200px), (min-width: 700px) and (max-height: 850px) {
  .holiday-summary--allocation {
    grid-template-columns: repeat(5, 1fr) auto;
    column-gap: 4px;
  }

  .holiday-summary--allocation .holiday-summary--allocation-item .holiday-summary--allocation-symbol {
    width: 3rem;
    font-size: .85rem;
  }
}

@media (min-width: 1200px) and (max-height: 50vw) {
  .holiday-summary--allocation .holiday-summary--allocation-item .holiday-summary--allocation-symbol {
    width: 4rem;
    font-size: .9rem;
  }
}

@media (min-width: 1600px) {
  main.menu-top .dashboard-container {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "hello content-0 content-4 holiday"
                         "hello content-0 content-4 holiday"
                         "content-1 announce content-2 holiday"
                         "content-1 announce content-3 holiday";
  }
}

@media (max-width: 1200px) {
  main.menu-top .dashboard-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 700px) {
  main.menu-top .dashboard-container {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1200px) and (min-height: 1000px) {
  main.menu-top .dashboard-container .dashboard--hello-container {
    flex-direction: column;
    row-gap: 2rem;
  }

  main.menu-top .dashboard-container .dashboard--hello-container .dashboard--profile-pic {
    border-radius: 50%;
    max-width: 8rem;
    max-height: 8rem;
  }
}

@media (max-width: 1600px) {
  :root.theme-high-contrast .dashboard-container {
    grid-row-gap: var(--padding-tight);
    grid-template-rows: repeat(6, auto);
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "hello content-3"
                         "content-0 content-3"
                         "content-0 content-3"
                         "announce content-4"
                         "content-2 holiday"
                         "content-1 holiday";
  }
}

footer {
  padding: var(--padding-tight) var(--padding);
  background-color: var(--colour-secondary);
  color: var(--colour-secondary-text);
  grid-area: footer;
  justify-content: space-between;
  display: flex;
}

footer a {
  color: var(--colour-secondary-text);
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

footer .footer-links {
  column-gap: 1rem;
  display: flex;
}

@media (max-width: 1000px) {
  footer {
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }
}

.login-container {
  z-index: 1000;
  background-color: var(--colour-primary);
  opacity: 1;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  display: flex;
  position: fixed;
}

.login-container .login-container--panel {
  background: var(--colour-content-background);
  border-radius: var(--border-radius-inner);
  box-shadow: var(--shadow);
  opacity: 0;
  z-index: 1;
  padding-top: clamp(3rem, var(--padding), var(--padding));
  padding-bottom: clamp(3rem, var(--padding), var(--padding));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  width: min(45rem, 90vw);
  animation: 1s ease-out 1s forwards slide-top;
  display: flex;
  position: relative;
  overflow: hidden;
}

.login-container .login-container--panel .login-container--logo {
  width: 22vw;
  min-width: 200px;
  max-width: 300px;
}

.login-container .login-container--panel .login-container--logo img {
  object-fit: contain;
  width: 100%;
  height: auto;
  max-height: 200px;
}

@media (max-width: 700px) {
  .login-container .login-container--panel .login-container--logo img {
    width: 80%;
    height: auto;
    margin: 0 auto;
    display: block;
  }
}

.login-container .login-container--panel .login-container--title {
  text-align: center;
  margin: 1rem 0 0;
  padding: 0;
  font-size: max(1.2rem, min(1rem + 2vw, 2.47rem));
}

@media (max-width: 700px) {
  .login-container .login-container--panel .login-container--title {
    margin-bottom: 1rem;
  }
}

.login-container .login-container--panel .login-container--slides {
  transition: transform 2s var(--easing);
  grid-template-columns: repeat(3, 1fr);
  align-self: flex-start;
  align-items: center;
  width: 300%;
  display: grid;
}

.login-container .login-container--panel .login-container--slides .login-container--slide {
  padding-left: var(--padding);
  padding-right: var(--padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  display: flex;
}

.login-container .login-container--panel .login-container--slides .login-container--slide .login-container--heading {
  text-align: center;
  margin: 0 0 1rem;
  padding: 0;
}

.login-container .login-container--panel .login-container--slides .login-container--slide .login-container--input {
  width: max(250px, min(22rem + 1vw, min(100%, 400px)));
  min-width: 200px;
  max-width: 80%;
}

.login-container .login-container--panel .login-container--slides .login-container--slide .login-container--buttons-container {
  justify-content: space-between;
  align-items: center;
  width: max(250px, min(22rem + 1vw, min(100%, 400px)));
  margin-top: 1rem;
  display: flex;
}

.login-container .login-container--panel .login-container--slides .login-container--slide .login-container--buttons-container > button.button {
  padding: 1rem;
}

@media (max-width: 700px) {
  .login-container .login-container--panel .login-container--slides .login-container--slide .login-container--buttons-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
  }
}

.login-container .login-container--panel .login-container--slides .login-container--slide .login-container--buttons-container__wide {
  flex-direction: column;
  align-items: stretch;
  row-gap: 1rem;
}

.login-container .login-container--panel .login-container--slides .login-container--slide .login-container--buttons-container__wide > button {
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  font-size: 1rem;
  display: flex;
}

.login-container .login-container--panel .login-container--slides .login-container--slide .login-container--links {
  flex-direction: column;
  flex-shrink: 1;
  align-items: flex-start;
  row-gap: .5rem;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

@media (max-width: 700px) {
  .login-container .login-container--panel .login-container--slides .login-container--slide .login-container--links {
    align-items: center;
  }

  .login-container .login-container--panel .login-container--slide:first-child .login-container--heading {
    display: none;
  }
}

.login-container .login-container--panel .login-container--slides__slide2 {
  transform: translateX(-33.3333%);
}

.login-container .login-container--panel .login-container--slides__slide3 {
  transform: translateX(-66.6667%);
}

.login-container__admin .login-container--panel {
  background-color: var(--colour-secondary);
  color: var(--colour-secondary-text);
  border: .25rem solid var(--colour-secondary-text);
}

.login-container__admin .login-container--title, .login-container__admin .login-container--links a {
  color: var(--colour-secondary-text);
}

.login-container__admin .login-container--buttons-container button {
  background-color: var(--colour-primary);
  color: var(--colour-primary-text);
}

.login-container__admin .login-container--input {
  border: 1px solid var(--colour-primary);
}

.login-container:before {
  content: " ";
  filter: grayscale() saturate(400%) brightness(.8);
  opacity: .6;
  z-index: 0;
  background-image: url("login-bg.0080e860.webp");
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: all 1s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.login_container__loading:before {
  opacity: .5;
}

.login_container__loading .throbber {
  max-width: 20rem;
}

.side-bar {
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  z-index: 20;
  transition: transform .4s var(--easing);
  grid-template: "heading close" min-content
                 "content content"
                 "footer footer" min-content
                 / 99fr 1fr;
  align-items: center;
  width: 100%;
  max-width: 40rem;
  height: 100vh;
  display: grid;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.side-bar .side-bar--heading {
  padding: var(--padding);
  grid-area: heading;
  margin: 0;
}

.side-bar .side-bar--heading * {
  margin: 0;
}

.side-bar .side-bar--heading h2 {
  font-size: 2rem;
}

.side-bar .side-bar--close {
  padding: var(--padding);
  grid-area: close;
  width: min-content;
}

.side-bar .side-bar--content {
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-bottom: var(--padding);
  grid-area: content;
  height: 100%;
  overflow: auto;
}

.side-bar .side-bar--content__submitting {
  pointer-events: none;
}

.side-bar .side-bar--footer-buttons {
  padding: var(--padding);
  background-color: var(--colour-primary-faded-more);
  grid-area: footer;
  justify-content: space-evenly;
  align-items: stretch;
  height: min-content;
  display: flex;
}

.side-bar .side-bar--footer-buttons .button {
  justify-content: center;
  min-width: 10rem;
  font-size: 1.1rem;
}

.side-bar .sidebar-form--section {
  padding: var(--padding);
}

.side-bar.active {
  transform: translateX(0);
}

/*# sourceMappingURL=index.626e37eb.css.map */
