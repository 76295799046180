footer {
  grid-area: footer;
  padding: var(--padding-tight) var(--padding);
  background-color: var(--colour-secondary);
  color: var(--colour-secondary-text);
  display: flex;
  justify-content: space-between;
  a {
    color: var(--colour-secondary-text);
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  .footer-links {
    display: flex;
    column-gap: 1rem;
  }
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }
}
