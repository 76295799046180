.login-container {
  width: 100vw;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: var(--colour-primary);
  opacity: 1;
  .login-container--panel {
    width: min(45rem, 90vw);
    overflow: hidden;
    background: var(--colour-content-background);
    border-radius: var(--border-radius-inner);
    box-shadow: var(--shadow);
    opacity: 0;
    z-index: 1;
    position: relative;
    animation: slide-top 1s ease-out 1s;
    animation-fill-mode: forwards;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
    padding-top: clamp(3rem, var(--padding), var(--padding));
    padding-bottom: clamp(3rem, var(--padding), var(--padding));
    .login-container--logo {
      width: 22vw;
      max-width: 300px;
      min-width: 200px;
      img {
        width: 100%;
        height: auto;
        max-height: 200px;
        object-fit: contain;
        @media (max-width: $breakpoint-mobile) {
          width: 80%;
          height: auto;
          margin: 0 auto;
          display: block;
        }
      }
    }
    .login-container--title {
      font-size: clamp(1.2rem, calc(1rem + 2vw), 2.47rem);
      text-align: center;
      margin: 1rem 0 0 0;
      padding: 0;
      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 1rem;
      }
    }
    .login-container--slides {
      width: 300%;
      display: grid;
      align-self: flex-start;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      transition: transform 2s var(--easing);
      .login-container--slide {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 1rem;
        padding-left: var(--padding);
        padding-right: var(--padding);

        .login-container--heading {
          text-align: center;
          margin: 0 0 1rem 0;
          padding: 0;
        }
        .login-container--input {
          width: clamp(250px, calc(22rem + 1vw), min(100%, 400px));
          min-width: 200px;
          max-width: 80%;
        }
        .login-container--buttons-container {
          width: clamp(250px, calc(22rem + 1vw), min(100%, 400px));
          margin-top: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          > button.button {
            padding: 1rem;
          }
          @media (max-width: $breakpoint-mobile) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 2rem;
          }
        }
        .login-container--buttons-container__wide {
          flex-direction: column;
          align-items: stretch;
          row-gap: 1rem;
          > button {
            flex-grow: 1;
            width: 100%;
            display: flex;
            justify-content: center;
            font-size: 1rem;
          }
        }
        .login-container--links {
          flex-shrink: 1;
          width: fit-content;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          row-gap: 0.5rem;
          @media (max-width: $breakpoint-mobile) {
            align-items: center;
          }
        }
      }
    }
    .login-container--slide:first-child {
      .login-container--heading {
        @media (max-width: $breakpoint-mobile) {
          display: none;
        }
      }
    }
    .login-container--slides__slide2 {
      transform: translateX(-33.3333333333%);
    }
    .login-container--slides__slide3 {
      transform: translateX(-66.6666666666%);
    }
  }
}

.login-container__admin {
  .login-container--panel {
    background-color: var(--colour-secondary);
    color: var(--colour-secondary-text);
    border: 0.25rem solid var(--colour-secondary-text);
  }
  .login-container--title {
    color: var(--colour-secondary-text);
  }
  .login-container--links a {
    color: var(--colour-secondary-text);
  }
  .login-container--buttons-container button {
    background-color: var(--colour-primary);
    color: var(--colour-primary-text);
  }
  .login-container--input {
    border: 1px solid var(--colour-primary);
  }
}

.login-container::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(../resources/login-bg.webp);
  background-size: cover;
  filter: grayscale(1) saturate(400%) brightness(0.8);
  opacity: 0.6;
  z-index: 0;
  transition: all 1s ease-out;
}
.login_container__loading::before {
  opacity: 0.5;
}

.login_container__loading {
  .throbber {
    max-width: 20rem;
  }
}
