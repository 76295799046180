$colour-primary-faded: fadeColour($colour-primary);
$colour-secondary-faded: fadeColour($colour-secondary);

$colour-primary-high-contrast: makeColourContrast($colour-primary, #ffffff, "auto", "uber");
$colour-secondary-high-contrast: makeColourContrast($colour-secondary, #ffffff, "auto", "uber");
$colour-primary-faded-high-contrast: fadeColour($colour-primary-high-contrast);
$colour-secondary-faded-high-contrast: fadeColour($colour-secondary-high-contrast);

$colour-primary-dark-theme: darkenColour($colour-primary);
$colour-secondary-dark-theme: darkenColour($colour-secondary);
$colour-primary-dark-theme-high-contrast: darkenColour($colour-primary-high-contrast);
$colour-secondary-dark-theme-high-contrast: darkenColour($colour-secondary-high-contrast);

$colour-primary-text: contrastText($colour-primary);
$colour-primary-faded-text: contrastText($colour-primary-faded);
$colour-secondary-text: contrastText($colour-secondary);
$colour-secondary-faded-text: contrastText($colour-secondary-faded);
$colour-primary-text-high-contrast: contrastText($colour-primary-high-contrast, "true");
$colour-primary-faded-text-high-contrast: contrastText($colour-primary-faded-high-contrast, "uber");
$colour-secondary-text-high-contrast: contrastText($colour-secondary-high-contrast, "true");
$colour-secondary-faded-text-high-contrast: contrastText($colour-secondary-faded-high-contrast, "uber");
$colour-primary-text-dark-theme: contrastText($colour-primary-dark-theme);
$colour-primary-faded-text-dark-theme: contrastText($colour-primary-dark-theme);
$colour-secondary-text-dark-theme: contrastText($colour-secondary-dark-theme);
$colour-secondary-faded-text-dark-theme: contrastText($colour-secondary-dark-theme);
$colour-primary-text-dark-theme-high-contrast: contrastText($colour-primary-dark-theme, "true");
$colour-primary-faded-text-dark-theme-high-contrast: contrastText($colour-primary-dark-theme, "true");
$colour-secondary-text-dark-theme-high-contrast: contrastText($colour-secondary-dark-theme, "true");
$colour-secondary-faded-text-dark-theme-high-contrast: contrastText($colour-secondary-dark-theme, "true");

$colour-primary-against-white: makeColourContrast($colour-primary, #fff, "darker");
$colour-secondary-against-white: makeColourContrast($colour-secondary, #fff, "darker");
$colour-primary-against-black: makeColourContrast($colour-primary, #000, "lighter");
$colour-secondary-against-black: makeColourContrast($colour-secondary, #000, "lighter");
$colour-primary-against-white-high-contrast: makeColourContrast($colour-primary-high-contrast, #fff, "darker", "true");
$colour-secondary-against-white-high-contrast: makeColourContrast($colour-secondary, #fff, "darker", "true");
$colour-primary-against-black-high-contrast: makeColourContrast($colour-primary-high-contrast, #000, "lighter", "true");
$colour-secondary-against-black-high-contrast: makeColourContrast($colour-secondary, #000, "lighter", "true");

$colour-primary-against-white-text: contrastText($colour-primary-against-white);
$colour-secondary-against-white-text: contrastText($colour-secondary-against-white);
$colour-primary-against-black-text: contrastText($colour-primary-against-black);
$colour-secondary-against-black-text: contrastText($colour-secondary-against-black);
$colour-primary-against-white-text-high-contrast: contrastText($colour-primary-against-white-high-contrast, "true");
$colour-secondary-against-white-text-high-contrast: contrastText($colour-secondary-against-white-high-contrast, "true");
$colour-primary-against-black-text-high-contrast: contrastText($colour-primary-against-black-high-contrast, "true");
$colour-secondary-against-black-text-high-contrast: contrastText($colour-secondary-against-black-high-contrast, "true");

$colour-links-on-white: makeColourContrast($colour-primary, #fff, "darker");
$colour-links-on-black: makeColourContrast($colour-primary, #000, "lighter");
$colour-links-on-white-high-contrast: makeColourContrast($colour-primary, #fff, "darker", "true");
$colour-links-on-black-high-contrast: makeColourContrast($colour-primary, #000, "lighter", "true");

:root {
  --colour-primary: #{$colour-primary};
  --colour-secondary: #{$colour-secondary};

  --colour-primary-faded: #{$colour-primary-faded};
  --colour-primary-faded-more: #{rgba(fadeColour($colour-primary-against-white), 0.3)};
  --colour-secondary-faded: #{$colour-secondary-faded};
  --colour-secondary-faded-more: #{rgba(fadeColour($colour-secondary-against-white), 0.3)};

  --colour-primary-text: #{$colour-primary-text};
  --colour-primary-faded-text: #{$colour-primary-faded-text};
  --colour-secondary-text: #{$colour-secondary-text};
  --colour-secondary-faded-text: #{$colour-secondary-faded-text};

  --colour-primary-against-white: #{$colour-primary-against-white};
  --colour-secondary-against-white: #{$colour-secondary-against-white};
  --colour-primary-against-black: #{$colour-primary-against-black};
  --colour-secondary-against-black: #{$colour-secondary-against-black};

  --colour-primary-against-white-high-contrast: #{$colour-primary-against-white-high-contrast};
  --colour-secondary-against-white-high-contrast: #{$colour-secondary-against-white-high-contrast};
  --colour-primary-against-black-high-contrast: #{$colour-primary-against-black}-high-contrast;
  --colour-secondary-against-black-high-contrast: #{$colour-secondary-against-black-high-contrast};

  --colour-primary-against-white-text: #{$colour-primary-against-white-text};
  --colour-secondary-against-white-text: #{$colour-secondary-against-white-text};
  --colour-primary-against-white-text-high-contrast: #{$colour-primary-against-white-text-high-contrast};
  --colour-secondary-against-white-text-high-contrast: #{$colour-secondary-against-white-text-high-contrast};
  --colour-primary-against-black-text: #{$colour-primary-against-black-text};
  --colour-secondary-against-black-text: #{$colour-secondary-against-black-text};
  --colour-primary-against-black-text-high-contrast: #{$colour-primary-against-black-text-high-contrast};
  --colour-secondary-against-black-text-high-contrast: #{$colour-secondary-against-black-text-high-contrast};

  --colour-background: #f8fafc;
  --colour-input-background: rgba(0, 0, 0, 0.06);
  --colour-input-header-background: #ffffff;
  --colour-input: var(--colour-text);
  --colour-buttons: var(--colour-secondary-against-white);
  --colour-buttons-text: var(--colour-secondary-against-white-text);
  --colour-buttons-alt: var(--colour-primary-against-white);
  --colour-buttons-alt-text: var(--colour-primary-against-white-text);
  --colour-links: #{$colour-links-on-white};
  --colour-borders: #d5d5d5;

  --colour-content-background: #ffffff;
  --colour-content-text: #4c4d61;
  --colour-content-headings: var(--colour-secondary-against-white);
  --colour-content-headings-line: var(--colour-primary);
  --colour-home-button-background: #ffffff;
  --colour-home-button-text: var(--colour-primary-against-white);
  --colour-header-primary: var(--colour-primary-against-white);
  --colour-header-secondary: var(--colour-secondary-against-white);
  --colour-header-secondary-text: var(--colour-secondary-against-white-text);
  --colour-header-input-borders: rgba(255, 255, 255, 0);
  --colour-help-button-text: var(--colour-primary-text);
  --colour-search-icon: var(--colour-primary-against-white);
  --colour-toggle: var(--colour-secondary-against-white);
  --colour-table-row-odd: rgba(0, 0, 0, 0.075);
  --colour-table-row-even: rgba(0, 0, 0, 0.025);
  --colour-table-header: rgba(0, 0, 0, 0.15);

  --colour-absence-holiday: #a8e6a8 !important;
  --colour-absence-sickness: #fd8c8c !important;
  --colour-absence-family: #d1b0e9 !important;
  --colour-absence-other: #c0e1ff !important;
  --colour-absence-rejected: #eaeaea !important;

  --colour-page-standard: rgba(255, 255, 255, 0);
  --colour-page-non-standard: #c0e1ff;
  --colour-page-client: #d1b0e9;

  --colour-accepted: #58d47e;
  --colour-accepted-faded: #58d47e66;
  --colour-rejected: #ea7677;
  --colour-rejected-faded: #ea767766;

  --font-size: clamp(11px, 2vh, 15px);
  --font-size-small: 0.7rem;
  --border-radius-outer: 1rem;
  --border-radius-inner: 0.8rem;
  --border-radius-small: 0.4rem;
  --padding: clamp(1rem, 2vw, 1.5rem);
  --padding-tight: clamp(0.5rem, 1.5vw, 0.75rem);
  --padding-large: 3.9rem;
  --mobile-section-gap: 2rem;
  --icon-size: clamp(0.7rem, 100%, 1.5rem);
  --shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  --skeleton: #ebebeb;
  --skeleton-highlight: #f5f5f5;
  --easing: cubic-bezier(0.075, 0.82, 0.165, 1);
  --throbber-easing: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
:root.theme-high-contrast {
  --colour-primary: #{$colour-primary-high-contrast};
  --colour-secondary: #{$colour-secondary-high-contrast};
  --colour-primary-faded: #{$colour-primary-faded-high-contrast};
  --colour-primary-text: #{$colour-primary-text-high-contrast};
  --colour-primary-faded-text: #{$colour-primary-faded-text-high-contrast};
  --colour-secondary-text: #{$colour-secondary-text-high-contrast};
  --colour-secondary-faded: #{$colour-secondary-faded-high-contrast};
  --colour-secondary-faded-text: #{$colour-secondary-faded-text-high-contrast};
  --colour-primary-against-white: #{$colour-primary-against-white-high-contrast};
  --colour-primary-against-black: #{$colour-primary-against-black-high-contrast};
  --colour-secondary-against-white: #{$colour-secondary-against-white-high-contrast};
  --colour-secondary-against-black: #{$colour-secondary-against-black-high-contrast};
  --colour-primary-against-white-text: #{$colour-primary-against-white-text-high-contrast};
  --colour-primary-against-black-text: #{$colour-primary-against-black-text-high-contrast};
  --colour-header-secondary: #{$colour-secondary-against-white-high-contrast};
  --colour-header-secondary-text: #{$colour-secondary-against-white-text-high-contrast};
  --colour-links: #{$colour-links-on-white-high-contrast};
  --colour-content-text: #000000;
  --colour-buttons: #{$colour-secondary-against-white-high-contrast};
  --colour-buttons-text: #{$colour-secondary-against-white-text-high-contrast};
  --colour-buttons-alt: #{$colour-primary-against-white-high-contrast};
  --colour-buttons-alt-text: #{$colour-primary-against-white-text-high-contrast};
  --colour-borders: #000000;
  --shadow: none;
}
:root.theme-dark {
  --colour-primary: #{$colour-primary-dark-theme};
  --colour-primary-faded: #{darkenColour($colour-primary-dark-theme)};
  --colour-primary-faded-more: #{darkenColour($colour-primary-against-black)};
  --colour-secondary-faded: #{darkenColour($colour-secondary-dark-theme)};
  --colour-secondary-faded-more: #{darkenColour($colour-secondary-against-black)};
  --colour-background: #{darkenColour(darkenColour($colour-primary-dark-theme))};
  --colour-content-background: #000;
  --colour-content-text: rgba(255, 255, 255, 0.8);
  --colour-content-headings: var(--colour-secondary-against-black);
  --colour-content-headings-line: var(--colour-primary-against-black);
  --colour-secondary: #{$colour-secondary-dark-theme};

  --colour-primary-text: #{$colour-primary-text-dark-theme};
  --colour-primary-faded-text: #{$colour-primary-text-dark-theme};
  --colour-secondary-text: #{$colour-secondary-text-dark-theme};
  --colour-secondary-faded-text: #{$colour-secondary-text-dark-theme};

  --colour-input-background: rgba(255, 255, 255, 0.2);
  --colour-input-header-background: #000;
  --colour-input: #ffffff;
  --colour-buttons: var(--colour-secondary-against-black);
  --colour-buttons-text: var(--colour-secondary-against-black-text);
  --colour-buttons-alt: var(--colour-primary-against-black);
  --colour-buttons-alt-text: var(--colour-primary-against-black-text);
  --colour-links: #{$colour-links-on-black};
  --colour-home-button-background: #000000;
  --colour-home-button-text: var(--colour-primary-against-black);
  --colour-borders: rgba(255, 255, 255, 0.15);
  --colour-header-primary: var(--colour-primary-against-black);
  --colour-header-secondary: var(--colour-secondary-against-black);
  --colour-header-secondary-text: var(--colour-secondary-against-black-text);
  --colour-header-input-borders: rgba(255, 255, 255, 0.3);
  --colour-help-button-text: var(--colour-primary-against-black);
  --colour-search-icon: var(--colour-primary-against-black);
  --colour-toggle: var(--colour-secondary-against-black);
  --shadow: none;
  --skeleton: #1a1a1a;
  --skeleton-highlight: #111111;
}
:root.theme-dark.theme-high-contrast {
  --colour-primary: #{$colour-primary-dark-theme-high-contrast};
  --colour-secondary: #{$colour-secondary-dark-theme-high-contrast};
  --colour-primary-text: #{$colour-primary-text-dark-theme-high-contrast};
  --colour-primary-faded-text: #{$colour-primary-faded-text-dark-theme-high-contrast};
  --colour-secondary-text: #{$colour-secondary-text-dark-theme-high-contrast};
  --colour-secondary-faded-text: #{$colour-secondary-faded-text-dark-theme-high-contrast};
  --colour-content-text: #ffffff;
  --colour-links: #{$colour-links-on-black-high-contrast};
  --colour-buttons: var(--colour-secondary-against-black-high-contrast);
  --colour-buttons-text: var(--colour-secondary-against-black-text-high-contrast);
  --colour-buttons-alt: var(--colour-primary-against-black-high-contrast);
  --colour-buttons-alt-text: var(--colour-primary-against-black-text-high-contrast);
  --colour-header-secondary: var(--colour-secondary-against-black-high-contrast);
  --colour-header-secondary-text: var(--colour-secondary-against-black-text-high-contrast);
  --colour-borders: #ffffff;
  --shadow: none;
}
:root.theme-large-text {
  --font-size: 15pt;
}

body.app {
  --padding: 1rem;
  --padding-tight: 0.5rem;
}
