@import "sass-vars", "print", "theme", "functions", "vars", "general", "tables", "forms", "animations",
  "components/profileMenu";

div#root {
  min-height: 100%;
}

// @media (max-width: $breakpoint-medium),
// (max-width: $breakpoint-large) and (max-height: $breakpoint-shallow-height-normal),
// (max-height: $breakpoint-shallow-height-wide) {
// }

.app-container {
  display: grid;
  position: relative;
  grid-template-rows: min-content auto min-content;
  grid-template-areas: "header" "main" "footer";
  min-height: 100vh;
  background-color: var(--colour-background);
  @media (max-width: $breakpoint-tablet) {
    height: fit-content;
  }
}
.app-container__dashboard {
  @media (min-width: $breakpoint-medium) and (orientation: landscape) {
    height: 100vh;
  }
}
.app-container::before {
  pointer-events: none;
  content: "";
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #000;
  z-index: 19;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.app-container.disabled::before {
  pointer-events: all;
  opacity: 0.7;
}
body,
header,
header > *,
main,
main > *,
.main-content,
footer,
footer > * {
  transition:
    background-color 0.5s ease-out,
    color 0.5s ease-out;
}

@import "header", "main", "dashboard", "footer", "login", "sidebar";
@import "../../node_modules/simplebar/dist/simplebar.css";
// @import "../../node_modules/react-loading-skeleton/dist/skeleton.css";
