@import "../sass-vars";

body.app {
  .header-top--profile-menu-tiles {
    *[data-label="Dashboard"] {
      display: flex;
    }
  }
}

.header-top--profile-menu {
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 0.5rem;
  min-width: 500px !important;
  padding: var(--padding);
  background-color: var(--colour-primary-faded);
  border-radius: var(--border-radius-small);
  z-index: 10;
  box-shadow: var(--shadow);
  animation: fade-in 0.2s forwards;

  h3 {
    margin: 1.5rem 0 0.5rem 0;
    color: var(--colour-primary-faded-text);
  }
  h3:first-of-type {
    margin-top: 0;
  }
  .header-top--profile-menu-tiles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    > * {
      padding: 0.5rem;
      text-align: center;
      background-color: var(--colour-buttons);
      color: var(--colour-buttons-text);
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: center;
      text-decoration: none;
      transition: all 0.2s var(--easing);
      font-size: 0.85rem;
      width: 100%;
      font-weight: normal;
      border-radius: var(--border-radius-inner);
      white-space: normal;
      * {
        min-width: fit-content;
      }
    }
    *[data-label="Dashboard"] {
      display: none;
    }
    svg {
      width: 32px;
      height: 32px;
      max-width: 32px;
      max-height: 32px;
      display: inline-block;
    }
  }
  .header-top--profile-menu--close {
    display: none;
  }
  .header-top--profile-menu--controls {
    display: none;
  }
  @media (max-width: 500px) {
    min-width: fit-content !important;
    max-width: 100% !important;
  }
}
.navigation-bar-top--items {
  z-index: 9999;
  .header-top--profile-menu {
    position: fixed;
    top: 0;
    max-width: 600px;
    min-width: 100px;
    width: 100%;
    border-radius: 0;
    height: 100vh;
    overflow: auto;
    margin: 0;
    display: none;
    transform: translateX(101%);
    transition: transform 0.2s var(--easing);
    padding-top: calc(var(--padding) + 2rem);
    @media (min-width: $breakpoint-tablet) {
      display: none;
    }
    h3 {
      margin: 2rem 0 1rem 0;
    }
    h3:first-of-type {
      margin-top: 0;
    }
    .close-button {
      display: inline-block;
      position: absolute;
      top: var(--padding);
      right: var(--padding);
    }
    .header-top--profile-menu--controls {
      display: flex;
      justify-content: space-between;
      gap: var(--padding);
      margin-top: calc(var(--padding) * 2);
      border-top: 1px solid var(--colour-primary-faded-text);
      padding-top: calc(var(--padding) * 2);
      > *:last-child {
        margin-left: auto;
      }
    }
  }
  .header-top--profile-menu.active {
    transform: translateX(0);
    display: block;
  }
}
