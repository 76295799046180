:root {
  color-scheme: light dark;
  &.theme-dark {
    color-scheme: dark;
  }
}

body.app {
  main {
    display: block;
    padding-top: calc((5 * var(--padding-tight)) + max((6.25vw + 4px), 40px) + 1rem + 3px);
  }
}
main {
  display: grid;
  height: 100%;
  grid-area: main;
  grid-template-columns: 1fr 4.4fr;
  grid-template-rows: 1000fr 1fr;
  grid-template-areas: "navigation content";
  overflow: hidden;

  @media (max-width: $breakpoint-medium),
    (max-width: $breakpoint-large) and (max-height: $breakpoint-shallow-height-normal),
    (max-height: $breakpoint-shallow-height-wide) {
    // grid-template-rows: auto auto;
  }
  @media (max-width: $breakpoint-tablet) {
    display: block;
    padding-top: calc((2 * var(--padding-tight)) + (1rem + 4vw));
  }
  @media (max-width: $breakpoint-mobile) {
    display: block;
    padding-top: calc((2 * var(--padding-tight)) + (2.5rem));
  }

  .nav-side-bg-target {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    pointer-events: auto;
  }
  .nav-side {
    grid-area: navigation;
    background-color: var(--colour-primary-faded);
    height: 100%;
    overflow: auto;
    padding: var(--padding) var(--padding);
    position: relative;
    z-index: 10;
    @media (max-width: $breakpoint-tablet) {
      animation: none;
      width: fit-content;
      min-width: 50%;
      max-width: 80%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 50;
      background-color: var(--colour-primary);
      transform: translateX(-101%);
      box-shadow: 1rem 0 1rem rgba(0, 0, 0, 0.1);
      border-right: 2px solid var(--colour-secondary);
      transition: transform 0.2s var(--easing);
      padding-top: 4rem;
    }
    .skeleton-line {
      margin-bottom: 1.5rem;
    }
    .skeleton-line:before {
      background-color: rgba(255, 255, 255, 0.2);
    }
    .nav-side--heading {
      color: var(--colour-primary-faded-text);
      font-size: 1.5rem;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      padding-bottom: 1rem;
      margin-bottom: 0.75rem;
      margin-top: 0;

      @media (max-width: $breakpoint-medium) {
        font-size: 1.2rem;
      }
    }
    .nav-side--heading::after {
      content: " ";
      background-color: var(--colour-buttons);
      height: 0.35rem;
      position: absolute;
      bottom: 0;
      left: 0;
      animation: heading-line 2s forwards;
    }
    .nav-side--close-button {
      position: fixed;
      top: -100rem;
      right: -100rem;
      @media (max-width: $breakpoint-tablet) {
        top: 1rem;
        right: 1rem;
      }
    }
    .nav-side--logo {
      width: 100%;
      min-height: 4rem;
      height: auto;
      object-fit: contain;
      margin-bottom: 1rem;
    }
    .nav-side--menu {
      padding-bottom: 1rem;
      padding-top: 2rem;
      animation: side-menu-dropdown 0.35s forwards;
      position: relative;
      z-index: 1;
      .nav-side--heading::after {
        animation: none;
        width: 3rem;
      }
      .nav-side--item {
        display: block;
        text-decoration: none;
        font-size: 1.1rem;
        color: var(--colour-primary-faded-text);
        font-weight: bold;
        padding: 0.25rem 0;
        cursor: pointer;
        position: relative;
        @media (min-width: $breakpoint-medium) {
          padding: 0.25rem 0;
        }
        @media (min-width: $breakpoint-large) {
          padding: 0.5rem 0;
        }
        @media (min-width: $breakpoint-largest) {
          padding: 0.75rem 0;
        }
        .nav-side--item-link {
          display: grid;
          grid-template-columns: auto 1.5rem;
          align-items: center;
          column-gap: 1.25rem;
          // white-space: nowrap;
          transition: all 0.2s ease-out;
          position: relative;
          @media (max-width: $breakpoint-large) {
            grid-template-columns: auto 15px;
          }

          > .svg {
            width: 1.5rem;
            height: 1.15rem;
            flex-grow: 1;
            flex-shrink: 1;
            position: relative;
            left: 0;
            top: 0;
            transition: all 0.2s ease-out;
            @media (max-width: $breakpoint-large) {
              width: 15px;
              height: 10px;
            }
            * {
              fill: var(--colour-primary-faded-text);
            }
          }
        }
        .nav-side--item-link::before {
          content: "";
          position: absolute;
          width: 0.5rem;
          right: calc(100% + var(--padding));
          background-color: var(--colour-primary);
          transition:
            right 0.2s ease-out,
            background-color 0.5s ease-out;
          opacity: 0;
          height: calc(100% + 1rem);
          top: -0.5rem;
          @media (min-width: $breakpoint-large) {
            top: -1rem;
            height: calc(100% + 2rem);
          }
          @media (min-width: $breakpoint-largest) {
            top: -1.67rem;
            height: calc(100% + 1.67rem + 1.67rem);
          }
        }
      }
      .nav-side--item__heading {
        border: none;
        padding-top: 2rem;
        font-weight: 900;
        text-transform: none;
        font-size: 1.2rem;
        color: var(--colour-primary-faded-text);
        cursor: default;
        text-transform: uppercase;
        border-bottom: 1px solid var(--colour-primary-faded-text);
        margin-bottom: 1rem;
        .nav-side--item-link::before {
          display: none;
        }
      }
      .nav-side--item:focus {
        outline: 0;
      }
      .active {
        border-bottom: transparent;
        > .nav-side--item-link {
          border: 0;
          > .svg {
            transform: scaleY(-1);
          }
        }
        .nav-side--item-link::before {
          right: calc(100% + var(--padding) - 0.5rem + 1px);
          animation: bounce-right-squash 1s both !important;
          background-color: var(--colour-primary-faded-text);
          opacity: 1;
        }
        .nav-side--sub-menu {
          padding-top: var(--padding-tight);
          padding-bottom: var(--padding-tight);
          @media (max-width: $breakpoint-large) {
            padding: 0.5rem 1rem;
          }
          height: fit-content;
        }

        .nav-side--sub-menu:empty {
          display: none;
        }
      }
      .nav-side--item:hover {
        filter: none;
        .nav-side--item-link::before {
          animation: restore-scale-y 0.2s both;
          right: calc(100% + var(--padding) - 0.5rem + 1px);
          opacity: 1;
        }
      }
      .nav-side--item:last-child {
        border-bottom: 0;
      }
      .nav-side--sub-menu {
        display: flex;
        flex-direction: column;
        border-radius: var(--border-radius-small);
        background-color: var(--colour-content-background);
        height: 0;
        padding: 0 var(--padding-tight);
        cursor: default;
        position: absolute;
        overflow: hidden;
        top: 100%;
        width: 100%;
        z-index: 15;
        box-shadow: var(--shadow);
        transition: all 0.5s var(--easing);
        text-transform: none;
        font-weight: normal;
        .nav-side--sub-item {
          font-size: 1rem;
          color: var(--colour-links);
          padding: 0.5rem 0;
          position: relative;
          cursor: pointer;
          border-bottom: 1px solid var(--colour-links);
          position: relative;
          @media (max-width: $breakpoint-large) {
            font-size: 0.8rem;
          }
          .nav-side--sub-item-link {
            color: var(--colour-links);
            text-decoration: none;
            display: flex;
            align-items: center;
            column-gap: 1.25rem;
            position: relative;
            > a,
            > button {
              display: block;
              width: 100%;
            }
            > .svg {
              width: 1rem;
              height: 1rem;
              position: relative;
              left: 0;
              top: 0;
              transition: all 0.2s ease-out;
              * {
                fill: var(--colour-links);
              }
            }
          }
          .nav-side--sub-item-link:focus {
            outline: 0;
          }
          .nav-side--sub-menu {
            padding: 0 1rem;
            position: static;
            overflow: hidden;
            height: 0;
            transition: all 0.2s ease-out;
            width: 100%;
            border-top-left-radius: var(--border-radius-small);
            border-top-right-radius: var(--border-radius-small);
            background-color: var(--colour-buttons);
            .nav-side--sub-item {
              color: var(--colour-buttons-text);
              border: 0;
              font-size: 0.9rem;
              .nav-side--sub-item-link {
                color: var(--colour-buttons-text);
                > a,
                > button {
                  display: block;
                  width: 100%;
                }
              }
            }
          }
        }
        .nav-side--sub-item::before {
          position: absolute;
          content: "";
          width: 0.4rem;
          height: 100%;
          top: 0;
          right: calc(100% + var(--padding-tight) - 0.4rem);
          transform: translateX(-101%);
          background-color: var(--colour-primary-against-white);
          transition: all 0.2s ease-out;
          opacity: 0;
          @media (max-width: $breakpoint-large) {
            right: calc(100% + 1rem - 0.4rem);
          }
        }
        .nav-side--sub-item:hover::before {
          transform: translateX(0);
          opacity: 1;
        }
        .nav-side--sub-item:last-child {
          border: 0;
        }

        .nav-side--sub-item.active {
          > .nav-side--sub-menu {
            height: fit-content;
            margin-top: 0.5rem;
            padding: 0.5rem 1rem;
            > .nav-side--sub-item::before {
              position: absolute;
              content: "";
              width: 0.4rem;
              height: 0.6rem;
              top: 50%;
              transform: translate3d(-101%, -50%, 0);
              right: calc(100% + 0.6rem);
              background-color: var(--colour-primary-against-white-text);
              transition: all 0.2s ease-out;
              opacity: 0;
              clip-path: polygon(0 0, 100% 50%, 0 100%);
            }
            > .nav-side--sub-item:hover::before {
              opacity: 1;
              transform: translate3d(0, -50%, 0);
            }
          }
          .nav-side--sub-menu:empty {
            display: none;
          }
        }
      }
      .nav-side--sub-menu:empty {
        display: none;
      }
    }
    .nav-side--menu:first-of-type {
      padding-top: 0;
    }
    .nav-side--menu.inactive {
      animation: none;
      padding: 0;
      z-index: 0;
      .nav-side--heading {
        font-size: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 0;
        margin-top: 0;
        cursor: pointer;
        display: block;
      }
      .nav-side--heading::after {
        animation: none;
        width: 2rem;
        transition: width 0.2s ease-out;
      }
      .nav-side--heading:hover::after {
        width: 3rem;
      }
      .nav-side--item {
        display: none;
      }
    }
    .button {
      width: 100%;
      padding: 0.5rem;
      justify-content: stretch;
      gap: 0;
      margin-bottom: var(--padding);
      > *:first-child {
        margin-left: 0;
        align-self: flex-start;
        flex-shrink: 1;
      }
      > span {
        flex-grow: 1;
      }
    }
  }
  .nav-side.active {
    @media (max-width: $breakpoint-tablet) {
      transform: translateX(0);
    }
  }
  .content-area {
    grid-area: content;
    padding: var(--padding);
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto min-content;
    min-height: 100%;
    .main-body-scrollable {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 0 var(--padding) var(--padding) var(--padding);
      overflow: auto;
      box-sizing: border-box;
      scroll-behavior: smooth;
      > *:first-child {
        padding-top: var(--padding);
      }
      > .throbber {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) scale(0.5);
        z-index: 1;
      }
      > .form__columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media (max-width: $breakpoint-medium) {
          grid-template-columns: 1fr;
        }
      }
    }
    .main-body-scrollable[data-disabled="true"] {
      opacity: 0.5;
      pointer-events: none;
    }
    .main-body-space-rows {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      > h2 {
        margin: 0;
      }
    }
    .main-body--bottom-buttons {
      display: flex;
      margin-top: var(--padding);
      align-items: center;
      justify-content: center;
      column-gap: var(--padding-tight);
      button {
        min-width: 12rem;
        justify-content: center;
      }
      button.button__invert {
        min-width: 0;
      }
    }
    .main-content {
      background-color: var(--colour-content-background);
      color: var(--colour-content-text);
      height: 100%;
      overflow: hidden;
      padding: var(--padding);
      position: relative;
      // padding-left: var(--padding-large);
      border: 1px solid var(--colour-borders);
      border-radius: var(--border-radius-outer);
      display: grid;
      grid-template-columns: 2.4fr 1fr;
      grid-column-gap: var(--padding);
      .main-content--content-section {
        img {
          max-width: 100%;
          height: auto;
          margin: 1rem auto;
          display: block;
        }
      }
      .main-content--content-column {
        display: grid;
        height: 100%;
        overflow: hidden;
        grid-template-rows: min-content auto;
        > h1 {
          margin-left: 2rem;
          @media (max-width: $breakpoint-medium),
            (max-width: $breakpoint-large) and (max-height: $breakpoint-shallow-height-normal),
            (max-height: $breakpoint-shallow-height-wide) {
            margin-left: 0;
          }
        }
        .main-content--scrollbox {
          position: relative;
          .main-content--content-container {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: auto;
            padding-left: 2rem;

            .simplebar-track.simplebar-vertical {
              left: 0;
            }
            @media (max-width: $breakpoint-medium),
              (max-width: $breakpoint-large) and (max-height: $breakpoint-shallow-height-normal),
              (max-height: $breakpoint-shallow-height-wide) {
              position: static;
              padding-left: 0;
            }
            .main-content--content-content {
              display: flex;
              flex-direction: column;
              row-gap: var(--padding);
              justify-content: stretch;
              align-items: stretch;
            }
          }
        }
        @media (max-width: $breakpoint-medium),
          (max-width: $breakpoint-large) and (max-height: $breakpoint-shallow-height-normal),
          (max-height: $breakpoint-shallow-height-wide) {
          height: auto;
        }
      }

      .main-content--image-column {
        display: flex;
        height: 100%;
        min-width: 250px;
        overflow: hidden;
        flex-direction: column;
        justify-content: stretch;
        align-items: flex-end;
        gap: var(--padding);
        position: relative;
        padding-bottom: 4rem;
        .main-content--image {
          width: 100%;
          height: 50vh;
          max-height: 100%;
          border-radius: var(--border-radius-inner);
          object-fit: cover;
        }
        .main-content--image[data-no-crop="true"] {
          object-fit: contain;
          height: auto;
        }
        @media (max-width: $breakpoint-medium),
          (max-width: $breakpoint-large) and (max-height: $breakpoint-shallow-height-normal),
          (max-height: $breakpoint-shallow-height-wide) {
          height: auto;
        }
      }
      .main-content--download-btn {
        margin-left: auto;
        margin-top: var(--padding);
        margin-right: var(--padding);
      }
      .main-content--image-column + .main-content--download-btn {
        position: absolute;
        bottom: var(--padding);
        right: var(--padding);
        margin-right: 0;
      }

      @media (max-width: $breakpoint-medium),
        (max-width: $breakpoint-large) and (max-height: $breakpoint-shallow-height-normal),
        (max-height: $breakpoint-shallow-height-wide) {
        display: flex;
        flex-direction: column;
        row-gap: var(--mobile-section-gap);
        height: auto;
        .main-content--image-column {
          .main-content--image {
            max-height: 50vh;
          }
        }
      }
    }

    .main-content__no-image {
      grid-template-columns: 1fr;
      grid-template-rows: auto min-content;
    }
    .button {
      box-shadow: var(--shadow);
    }
    .paging {
      max-height: min-content;
      padding: var(--padding) var(--padding) 0 var(--padding);
      font-size: 1.2rem;
      font-weight: bold;
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-template-areas: "pagingLeft actionButton pagingRight";
      column-gap: var(--padding);
      align-items: center;
      @media (max-width: $breakpoint-tablet) {
        grid-template-columns: repeat(2, auto);
        grid-template-areas: "actionButton actionButton" "pagingLeft pagingRight";
      }
      > *:first-child {
        grid-area: pagingLeft;
      }
      > *:nth-child(2):not(:empty) {
        grid-area: actionButton;
        @media (max-width: $breakpoint-tablet) {
          margin-bottom: calc(var(--padding) * 2);
        }
      }
      > *:last-child {
        grid-area: pagingRight;
      }

      > * {
        justify-self: center;
      }
      > a,
      > button.button-unstyled {
        width: 100%;
        min-width: 0;
        text-decoration: none;
        color: var(--colour-content-headings);
        column-gap: 0.75rem;
        transition: column-gap 0.5s ease-out;
        cursor: pointer;
        display: flex;
        > svg {
          transition: transform 0.2s ease-out;
          position: relative;
          top: 0.05rem;
          > * {
            fill: var(--colour-content-headings);
            stroke: var(--colour-content-headings);
          }
        }
        > svg:first-child {
          transform: scaleX(-1);
        }
        > .paging--label {
          display: block;
          width: max-content;
          @media (max-width: $breakpoint-medium) {
            font-size: 0.8rem;
          }
        }
      }
      > *:first-child {
        justify-self: start;
      }
      > *:last-child {
        justify-self: end;
        text-align: right;
        justify-content: flex-end;
      }
      @media (max-width: $breakpoint-medium),
        (max-width: $breakpoint-large) and (max-height: $breakpoint-shallow-height-normal),
        (max-height: $breakpoint-shallow-height-wide) {
        padding-left: var(--padding);
      }
      @media (max-width: $breakpoint-tablet) {
        margin-top: 0.5rem;
      }
      button.button {
        font-size: 1.2rem;
      }
    }
    .paging > a:hover,
    .paging > button.button-unstyled:hover {
      column-gap: 1rem;
      > svg:first-child {
        transform: translateX(-0.5rem) scaleX(-1);
      }
      > svg:last-child {
        transform: translateX(0.5rem);
      }
    }
    .paging__padded-left {
      padding-left: calc(2rem + var(--padding));
      @media (max-width: $breakpoint-medium) {
        padding-left: var(--padding);
      }
    }

    .paging > *:disabled {
      opacity: 0.5;
      pointer-events: none;
      filter: grayscale(1);
    }
    @media (max-width: $breakpoint-tablet) {
      height: auto;
    }
  }
  .columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--padding);
    @media (max-width: $breakpoint-medium) {
      grid-template-columns: 1fr;
    }
  }
}
:root.theme-dark {
  .main-content--image {
    filter: brightness(0.8) saturate(80%);
  }
  .nav-side .nav-side--menu .nav-side--sub-menu .nav-side--sub-item .nav-side--sub-item-link {
    color: var(--colour-primary-against-white-text);
  }
}
// When side menu is positioned at top using toggle
@media (min-width: $breakpoint-tablet) {
  main.menu-top {
    min-height: var(--top-menu-height);
    transition: min-height 0.2s ease-out;
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto;
    grid-template-areas: "navigation" "content";
    .nav-side {
      display: flex;
      align-items: center;
      row-gap: 1rem;
      column-gap: 2rem;
      flex-wrap: wrap;
      padding: 0.5rem var(--padding);
      overflow: visible;
      animation: header-slide-down 0.6s forwards;
      .skeleton-line {
        margin-bottom: 0;
      }
      .nav-side--menu {
        padding: 0;
        animation: none;
        .nav-side--heading {
          padding: 0;
          margin: 0;
          font-size: 1rem;
          cursor: pointer;
        }
        .nav-side--heading::after {
          display: none;
        }
        .nav-side--heading::before {
          width: 100%;
          height: 0.5rem;
          top: calc(100% + 0.5rem);
          left: 0;
          bottom: auto;
          right: 0;
          transform: scaleX(0);
          transform-origin: 0 0 0;
        }
        .nav-side--menu-items {
          overflow: hidden;
          display: flex;
          flex-direction: column;
          border-radius: var(--border-radius-small);
          background-color: var(--colour-content-background);
          padding: 0 var(--padding-tight);
          margin-left: calc(0px - var(--padding-tight));
          cursor: default;
          position: absolute;
          top: calc(100% + 0.5rem);
          z-index: 15;
          box-shadow: var(--shadow);
          transition: all 0.5s var(--easing);
          text-transform: none;
          font-weight: normal;
          margin-top: 0;
          width: max-content;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          z-index: 15;
          animation: side-menu-dropdown 0.25s forwards;
          .nav-side--item {
            color: var(--colour-links);
            position: relative;
            cursor: pointer;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            column-gap: 1.25rem;
            border-bottom: 1px solid var(--colour-links);
            position: relative;
            text-transform: none;
            font-weight: normal;
            padding: 0;
            > .svg {
              width: 1rem;
              height: 1rem;
              position: relative;
              left: 0;
              top: 0;
              transition: all 0.2s ease-out;
              * {
                fill: var(--colour-links);
              }
            }
            .nav-side--item-link {
              position: static;
              column-gap: 0.5rem;
              padding: 0.5rem 0;
              > .svg {
                width: 1rem;
                height: 1rem;
                position: relative;
                left: 0;
                top: 0;
                transition: all 0.2s ease-out;
                * {
                  fill: var(--colour-links);
                }
              }
            }
            .nav-side--item-link::before {
              height: 100%;
              top: 0;
            }
            @media (max-width: $breakpoint-medium) {
              // font-size: 0.8rem;
            }
            .svg {
              max-width: 1.2rem;
              @media (max-width: $breakpoint-medium) {
                max-width: 1rem;
              }
            }
          }
          .nav-side--item:last-child {
            border-bottom: 0;
            padding-bottom: 0.5rem;
          }
          .nav-side--item__heading {
            font-size: inherit;
            border-bottom: 0;
            font-weight: bold;
            text-transform: uppercase;
            padding-bottom: 0;
            padding-top: 0.5rem;
            cursor: default;
          }
          .nav-side--sub-menu {
            position: static;
            margin-top: 0;
            width: max-content;
            max-width: 15rem;
            padding: 0;
            border-radius: 0;
            box-shadow: none;
            display: none;
            z-index: 15;
            font-size: 1rem;
            > * {
              padding-left: 1rem;
            }
            .nav-side--sub-menu {
              max-width: 100%;
              padding: 0;
              .nav-side--sub-item {
                padding: 0.25rem 1rem;
              }
              .nav-side--sub-item:first-child {
                margin-top: 0.25rem;
              }
              .nav-side--sub-item:last-child {
                margin-bottom: 0.25rem;
              }
              .nav-side--sub-item::before {
                right: auto;
                left: 0;
              }
            }
          }
          .nav-side--item.active {
            .nav-side--sub-menu {
              display: block;
              animation: side-menu-dropdown 0.25s forwards;
            }
          }
        }
        .active {
          .nav-side--item-link::before {
            background-color: var(--colour-primary);
            animation: none !important;
          }
        }
      }
      .nav-side--menu.inactive {
        .nav-side--heading {
          padding: 0;
        }
        .nav-side--menu-items {
          height: 0;
          display: none;
        }
      }
      @media (max-width: $breakpoint-medium) {
        // column-gap: 0.5rem;
      }
    }
  }
}
.image-thumbs-browse {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: var(--padding);
  .image-thumbs-browse--item-container {
    position: relative;
    .image-thumbs-browse--item {
      display: flex;
      flex-direction: column;
      row-gap: 0.25rem;
      align-items: center;
      position: relative;
      filter: brightness(1);
      .image-thumbs-browse--edit-icons {
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
        opacity: 0;
        transition: all 0.2s ease-out;
        cursor: default;
      }
      .image-thumbs-browse--caption {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        align-self: stretch;
        text-align: center;
        > .editable-text {
          width: 100%;
        }
      }
      > img {
        width: 100%;
        aspect-ratio: 3 / 2;
        object-fit: cover;
        background-color: rgba(128, 128, 128, 0.2);
        transition: all 0.2s ease-out;
      }
      > img.selected {
        outline: 4px solid var(--colour-secondary-against-white);
      }
      > img.clickable {
        cursor: pointer;
      }
      img.skeleton-image {
        aspect-ratio: 3 / 2;
      }
      > svg,
      img.skeleton-image.image-thumbs-browse--item-skeleton-small {
        width: 50%;
        padding: 0.5rem;
        aspect-ratio: 9 / 12;
        transition: all 0.2s ease-out;
        max-width: 8rem;
      }
      > img.skeleton-image.image-thumbs-browse--item-skeleton-small {
        border-radius: 1rem;
        padding: 0;
        margin: 0.5rem;
      }
    }
    .image-thumbs-browse--item:hover {
      img,
      svg {
        filter: brightness(1.1);
      }
      > .image-thumbs-browse--edit-icons {
        opacity: 1;
      }
    }
    .image-thumbs-browse--tags {
      position: absolute;
      top: 0.2rem;
      left: 0.2rem;
    }
  }
  .image-thumbs-browse--item-container:focus {
    z-index: 1;
  }
}
.image-thumbs-browse__files {
  grid-template-columns: repeat(5, 1fr);
}
.image-thumbs-browse__busy {
  opacity: 0.5;
  pointer-events: none;
}
.image-preview-container {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fade-in 1s;
  animation-fill-mode: forwards;
  > .throbber {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(0.5);
    z-index: 1;
  }
  > img {
    max-width: 90vw;
    max-height: 90vh;
    width: auto;
    height: auto;
    z-index: 2;
    position: relative;
    min-width: 15rem;
    min-height: 8rem;
    object-fit: contain;
    animation: fade-in 0.5s;
  }
}

.article-edit-canvas {
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  border: 1px solid var(--colour-borders);
  box-shadow: var(--shadow);
  min-height: 10rem;
  .article-edit--section {
    padding: 3rem 0 3rem 5rem;
    border-bottom: 1px dotted var(--colour-borders);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 26rem;
    .article-edit--section-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      column-gap: 4rem;
      > *:first-child {
        flex: 75%;
      }
      .ProseMirror {
        min-height: 20rem;
      }
      .article-edit--section-content-text {
        img {
          max-width: 100%;
          height: auto;
          margin: 1rem auto;
          display: block;
        }
      }
      .article-edit--section-content-image {
        position: relative;
        aspect-ratio: 4 / 6;
        max-width: min(25%, 500px);
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 25%;
        img {
          // max-height: 25rem;
          width: 100%;
          height: 100%;
          border-radius: var(--border-radius-inner);
          object-fit: cover;
        }
        img[data-no-crop="true"] {
          object-fit: contain;
        }
      }
      .article-edit--section-content-image:hover {
        .edit-icons--hoverable {
          opacity: 1;
        }
      }
    }
    .article-edit--section-actions {
      flex-shrink: 0;
      opacity: 1;
      width: 7rem;
      position: sticky;
      top: 0;
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 2rem;
      transition: opacity 0.2s ease-out;
    }
    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  .article-edit--section__fixed-fade {
    padding-bottom: 0;
    .article-edit--section-content {
      min-height: 15rem;
      overflow: hidden;
      --mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 90%);
      -webkit-mask-image: var(--mask-image);
      -moz-mask-image: var(--mask-image);
      mask-image: var(--mask-image);
      column-gap: 0;
    }
  }
  .article-edit--section:last-child {
    border-bottom: 0;
  }
  .article-edit--section[data-moved="up"] {
    animation: up-into-place 0.35s forwards;
  }
  .article-edit--section[data-moved="down"] {
    animation: down-into-place 0.35s forwards;
  }
}
.employee-db-footer {
  margin-top: var(--padding-tight);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  > *:last-child,
  > *:first-child {
    white-space: nowrap;
    font-size: 1.2rem;
    text-decoration: none;
  }
  > *:last-child {
    text-align: right;
  }
  > *:first-child > *::before {
    content: "< ";
  }
  > *:last-child > *::after {
    content: " >";
  }
}
body.app main {
  .nav-side--logo {
    max-width: 200px;
    margin: var(--padding) auto 2rem auto;
    display: block;
  }
  .content-area {
    padding: 0 var(--padding-tight) var(--padding-tight) var(--padding-tight);
  }
  .content-area .main-body-scrollable {
    padding: 0 var(--padding-tight) var(--padding-tight) var(--padding-tight);
  }
}
