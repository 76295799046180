body.app {
  .dashboard-container {
    grid-row-gap: var(--padding-tight) !important;
    .dashboard--item.dashboard--item__hello {
      padding-top: 0;
    }
  }
}

.dashboard-container[data-no-absence="true"] {
  grid-template-rows: repeat(7, auto);
  grid-template-areas:
    "hello      announce  content-4"
    "hello      announce  content-4"
    "content-3  announce  content-4"
    "content-3  content-1  content-4"
    "content-2  content-1 content-0"
    "content-2  content-1 content-0"
    "content-2  content-1 content-0";

  @media (max-width: $breakpoint-medium) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "hello      content-1"
      "announce   content-1"
      "announce   content-2"
      "announce   content-2"
      "content-3  content-2"
      "content-3  content-4"
      "content-0  content-4";
  }
  @media (max-width: $breakpoint-mobile) {
    grid-row-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, auto);
    grid-template-areas: "hello" "content-0" "announce" "content-1" "content-2" "content-3" "content-4";
  }
}

.dashboard-container {
  display: grid;
  grid-template-columns: repeat(3, 3.3fr);
  grid-template-rows: repeat(8, auto);
  grid-template-areas:
    "hello     content-1 content-4"
    "content-0 content-1 content-4"
    "content-0 content-1 content-4"
    "content-0 content-1 holiday"
    "announce  content-2 holiday"
    "announce  content-2 holiday"
    "announce  content-3 holiday"
    "announce  content-3 holiday";
  grid-column-gap: var(--padding);
  grid-row-gap: var(--padding);
  height: 100%;
  overflow: hidden;
  animation: init-overflow 0s 2.5s forwards;
  @media (max-width: $breakpoint-medium) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, auto);
    grid-column-gap: var(--padding-tight);
    grid-row-gap: var(--padding-tight);
    grid-template-areas:
      "hello        content-3"
      "content-0    content-3"
      "content-0    content-3"
      "announce     content-4"
      "content-2    holiday"
      "content-1    holiday";
  }
  // @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) and (max-height: $breakpoint-shallow-height-wide) {
  //   grid-template-columns: 3fr 3fr 3fr;
  //   grid-template-rows: repeat(4, auto);
  //   grid-template-areas:
  //     "content-0    announce    holiday"
  //     "content-0    announce    holiday"
  //     "content-4  announce    holiday"
  //     "content-4  announce    holiday";
  // }
  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, auto);
    grid-template-areas: "hello" "content-0" "announce" "holiday" "content-1" "content-2" "content-3" "content-4";
  }
  @media (max-height: $breakpoint-ultrawide-height), (max-height: $breakpoint-shallow-height-wide) {
    gap: 1rem;
  }
  .dashboard--item {
    border: 1px solid var(--colour-borders);
    border-radius: var(--border-radius-inner);
    padding: var(--padding);
    display: grid;
    width: 100%;
    grid-row-gap: 0.5rem;
    align-content: stretch;
    align-items: center;
    background-color: var(--colour-content-background);
    color: var(--colour-content-text);
    animation: fade-in-shrink 0.5s forwards;
    opacity: 0;
    @media (max-width: $breakpoint-medium) {
      padding: 1.5rem;
      grid-row-gap: 1rem;
    }
    // @media (max-height: $breakpoint-ultrawide-height), (max-height: $breakpoint-shallow-height-wide) {
    @media (max-height: $breakpoint-shallow-height-normal) {
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
    }

    @media (max-width: $breakpoint-tablet) {
      padding: var(--padding);
    }
    .dashboard--heading {
      color: var(--colour-buttons);
      position: relative;
      padding-bottom: 0.7rem;
      margin: 0;
      height: fit-content;
    }
    .dashboard--heading::after {
      content: "";
      width: 3rem;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: var(--colour-header-primary);
      height: 0.35rem;
    }
    .dashboard--hello-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      column-gap: 1rem;
      @media (max-width: $breakpoint-mobile) {
        align-items: center;
      }
      .dashboard--heading {
        font-size: 1.6rem;
        margin-bottom: 1rem;
      }
      .dashboard--profile-pic {
        flex-shrink: 1;
        flex-grow: 1;
        aspect-ratio: 1 / 1;
        color: var(--colour-buttons);
        max-width: 3rem;
        max-height: 3rem;
        .dashboard--profile-pic--img {
          display: block;
          min-width: 45px;
          border-radius: var(--border-radius-small);
          object-fit: cover;
        }
        .dashboard--profile-pic--svg {
          min-width: 45px;
          background-color: var(--colour-secondary-faded-more);
          padding: 0.5rem;
          border-radius: var(--border-radius-small);
        }
        * {
          width: 100%;
          height: auto;
          max-width: 3rem;
          max-height: 3rem;
          aspect-ratio: 1 / 1;
          fill: var(--colour-buttons);
          @media (max-width: $breakpoint-mobile) {
            max-width: 7rem;
            max-height: 7rem;
          }
        }
        @media (max-width: $breakpoint-mobile) {
          max-width: 7rem;
          max-height: 7rem;
          border-radius: var(--border-radius-inner);
        }
      }
    }
    a,
    .button-unstyled {
      color: var(--colour-buttons);
      text-decoration: none;
      display: flex;
      min-width: 0;
      gap: 0.5rem;
      align-items: center;
      align-self: stretch;
      // border-bottom: 2px solid var(--colour-borders);
      padding: 0.5rem 0;
      > .icon *,
      .icon-small * {
        fill: var(--colour-buttons) !important;
      }
      > .icon-small {
        transition: transform 0.2s ease-out;
        min-width: 0.5rem;
      }
    }
    a:last-of-type,
    .button-unstyled:last-of-type {
      border-bottom: 0;
    }
    a:hover,
    .button-unstyled:hover {
      filter: none;
      font-weight: bold;
      > .icon-small {
        transform: translateX(0.5rem);
      }
    }
    > .button {
      height: fit-content;
    }
    *[data-sticky="true"] {
      font-weight: bold;
    }
  }
  .dashboard--item:nth-child(2) {
    animation-delay: 0.125s;
  }
  .dashboard--item:nth-child(3) {
    animation-delay: 0.25s;
  }
  .dashboard--item:nth-child(4) {
    animation-delay: 0.375s;
  }
  .dashboard--item:nth-child(5) {
    animation-delay: 0.5s;
  }
  .dashboard--item:nth-child(6) {
    animation-delay: 0.625s;
  }
  .dashboard--item:nth-child(7) {
    animation-delay: 0.75s;
  }
  .dashboard--item:nth-child(8) {
    animation-delay: 0.875s;
  }
  .dashboard--item__alt {
    background-color: var(--colour-secondary);
    color: var(--colour-secondary-text);
    border: 0;
    .dashboard--heading {
      color: var(--colour-buttons-text);
    }
    .button {
      border: 2px solid var(--colour-buttons-text);
    }
  }
  .dashboard--item__hello {
    grid-area: hello;
    background-color: transparent;
    border: 0;
    padding: 0;
    // @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) and (max-height: $breakpoint-shallow-height-wide) {
    //   display: none;
    // }
    @media (max-width: $breakpoint-tablet) {
      padding-top: var(--padding-tight);
    }
  }
  .dashboard--item__content-0 {
    grid-area: content-0;
  }
  .dashboard--item__content-1 {
    grid-area: content-1;
    // @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) and (max-height: $breakpoint-shallow-height-wide) {
    //   display: none;
    // }
  }
  .dashboard--item__content-2 {
    grid-area: content-2;
    // @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) and (max-height: $breakpoint-shallow-height-wide) {
    //   display: none;
    // }
  }
  .dashboard--item__content-3 {
    grid-area: content-3;
    // @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) and (max-height: $breakpoint-shallow-height-wide) {
    //   display: none;
    // }
  }
  .dashboard--item__content-4 {
    grid-area: content-4;
  }
  .dashboard--item__holiday {
    grid-area: holiday;
  }
  .dashboard--item__announce {
    grid-area: announce;
  }
  .holiday-summary {
    grid-row-gap: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: space-evenly;
    align-items: stretch;
    @media (max-width: $breakpoint-tablet) {
      justify-content: space-between;
    }
    .holiday-summary--info {
      display: grid;
      grid-template-columns: 3fr 2fr;
      grid-row-gap: 0.5rem;
      .holiday-summary--info-value {
        font-weight: bold;
      }
    }

    .holiday-summary--buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: stretch;
      @media (max-height: $breakpoint-ultrawide-height), (max-height: $breakpoint-shallow-height-wide) {
        flex-direction: row;
        column-gap: var(--padding);
        align-items: center;
      }
      > a,
      > .button__transparent {
        border-bottom: 0;
        padding: 0;
      }
    }
    hr {
      margin: 0;
      display: none;
    }
  }
}
.dashboard-container[data-busy="true"] {
  animation: none;
  .dashboard--item {
    background-color: transparent;
    position: relative;
    animation: none;
    opacity: 1;
    border: 0;
  }
}

.holiday-summary--allocation {
  display: grid;
  grid-template-columns: repeat(4, 1fr) auto;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  width: 100%;
  .holiday-summary--allocation-label {
    grid-column: 1 / 7;
    @media (max-height: $breakpoint-ultrawide-height), (max-height: $breakpoint-shallow-height-wide) {
      // display: none;
    }
  }
  .holiday-summary--allocation-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 0.5rem;
    @media (min-width: $breakpoint-tablet) and (max-height: $breakpoint-ultrawide-height),
      (max-height: $breakpoint-shallow-height-wide) {
      grid-template-columns: repeat(5, 1fr) auto;
    }

    .holiday-summary--allocation-symbol {
      background-color: var(--colour-buttons);
      width: clamp(5ch, calc(1rem + 5vw), 5.5ch);
      max-width: 100%;
      height: clamp(5ch, calc(1rem + 5vw), calc(5vh - 0.7rem));
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--colour-buttons-text);
      cursor: default;
      border-radius: var(--border-radius-small);
      box-shadow: var(--shadow);
      // font-size: clamp(1.5rem, 20rem, 2rem);
      font-size: 1.2rem;
      font-weight: bold;
      @media (min-width: $breakpoint-large) {
        width: 5rem;
        height: 3.5rem;
      }

      @media (max-height: $breakpoint-ultrawide-height), (max-height: $breakpoint-shallow-height-wide) {
        width: 3rem;
        height: 2.5rem;
      }
      @media (max-width: $breakpoint-mobile) {
        width: 15vw;
        height: 15vw;
      }
    }
    .holiday-summary--allocation-symbol__holiday {
      background-color: var(--colour-absence-holiday);
    }
    .holiday-summary--allocation-symbol__sickness {
      background-color: var(--colour-absence-sickness);
    }
    .holiday-summary--allocation-symbol__family {
      background-color: var(--colour-absence-family);
    }
    .holiday-summary--allocation-symbol__other {
      background-color: var(--colour-absence-other);
    }
    .holiday-summary--allocation-symbol__remaining {
      background-color: transparent;
      border: 2px solid var(--colour-buttons);
      color: var(--colour-buttons);
    }
    .holiday-summary--allocation-item-label {
      font-size: 0.8rem;
      display: inline-block;
    }
  }

  .holiday-summary--allocation-item[data-loading="true"] {
    .holiday-summary--allocation-symbol {
      opacity: 0.5;
      animation: skeleton-shine-small 1.5s infinite forwards linear;
    }
  }
  .holiday-summary--allocation-item:nth-child(6) {
    grid-column-start: 1;
    .holiday-summary--allocation-item-label {
      display: none;
    }
    @media (min-width: $breakpoint-tablet) and (max-height: $breakpoint-ultrawide-height),
      (min-width: $breakpoint-tablet) and (max-height: $breakpoint-shallow-height-wide) {
      grid-column-start: 5;
      .holiday-summary--allocation-item-label {
        display: inline-block;
      }
    }
  }
  .holiday-summary--allocation-remaining-label {
    grid-column: 2 / 5;
    font-weight: bold;
    align-self: center;
    @media (min-width: $breakpoint-tablet) and (max-height: $breakpoint-ultrawide-height),
      (min-width: $breakpoint-tablet) and (max-height: $breakpoint-shallow-height-wide) {
      display: none;
    }
  }
  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-medium),
    (min-width: $breakpoint-mobile) and (max-height: $breakpoint-shallow-height-wide) {
    grid-template-columns: repeat(5, 1fr) auto;
    column-gap: 4px;
    .holiday-summary--allocation-item {
      .holiday-summary--allocation-symbol {
        width: 3rem;
        font-size: 0.85rem;
      }
    }
  }
  @media (min-width: $breakpoint-medium) and (max-height: $breakpoint-ultrawide-height) {
    .holiday-summary--allocation-item {
      .holiday-summary--allocation-symbol {
        width: 4rem;
        font-size: 0.9rem;
      }
    }
  }
}
// .holiday-summary--allocation-item:nth-child(6) {
//   @media (min-width: $breakpoint-tablet) and (max-height: $breakpoint-ultrawide-height),
//     (min-width: $breakpoint-tablet) and (max-height: $breakpoint-shallow-height-wide) {
//     margin-right: -1rem;
//   }
// }

main.menu-top {
  .dashboard-container {
    @media (min-width: $breakpoint-large) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, auto);
      grid-template-areas:
        "hello      content-0  content-4  holiday"
        "hello  content-0  content-4  holiday"
        "content-1  announce   content-2    holiday"
        "content-1  announce   content-3    holiday";
    }
    @media (max-width: $breakpoint-medium) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: $breakpoint-mobile) {
      grid-template-columns: 1fr;
    }
    .dashboard--hello-container {
      @media (min-width: $breakpoint-medium) and (min-height: $breakpoint-shallow-height-normal) {
        flex-direction: column;
        row-gap: 2rem;
        .dashboard--profile-pic {
          border-radius: 50%;
          max-width: 8rem;
          max-height: 8rem;
        }
      }
    }
  }
}
:root.theme-high-contrast {
  .dashboard-container {
    @media (max-width: $breakpoint-large) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(6, auto);
      grid-row-gap: var(--padding-tight);
      grid-template-areas:
        "hello        content-3"
        "content-0    content-3"
        "content-0    content-3"
        "announce     content-4"
        "content-2    holiday"
        "content-1    holiday";
    }
  }
}
