form[data-is-disabled="true"] {
  pointer-events: none;
  opacity: 0.8;
}

input,
select {
  font: inherit;
  color: var(--colour-input);
  background-color: var(--colour-input-background);
  border: 0;
  padding: 0.8rem 1rem;
}
input {
  line-height: 1.5;
}
input::placeholder,
select:required:invalid {
  color: var(--colour-input);
  opacity: 0.7;
}
input[type="text"]:focus,
input[type="password"]:focus {
  outline: 0.2rem solid var(--colour-primary-faded);
}
input[type="text"]:disabled,
input[type="password"]:disabled,
textarea:disabled {
  opacity: 0.5;
}
.button-unstyled {
  font: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  text-align: inherit;
  padding: 0;
}
.button-unstyled__link {
  cursor: pointer;
  color: var(--colour-links);
  > * {
    cursor: pointer;
  }
}
.button-unstyled__help {
  color: var(--colour-buttons-text);
  background: var(--colour-buttons);
  padding: 0;
  aspect-ratio: 1 / 1;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
}
.button-unstyled__flex {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  justify-content: flex-start;
}
.button-unstyled__underline {
  text-decoration: underline;
}
.button {
  background-color: var(--colour-buttons);
  border-radius: var(--border-radius-small);
  color: var(--colour-buttons-text);
  font-family: inherit;
  font-size: 0.85rem;
  padding: 0.5rem 0.7rem; // 1.35rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 0.7rem; //1.35rem;
  font-weight: bold;
  transition: all 0.2s ease-out;
  white-space: nowrap;
  text-decoration: none;
  width: fit-content;
  box-shadow: var(--shadow);
  text-decoration: none;
  > span {
    min-width: auto;
  }
  > .icon,
  > .icon > svg {
    max-height: 1.15rem;
    flex-grow: 1;
    * {
      fill: var(--colour-buttons-text);
    }
  }
  > .svg__no-fill {
    * {
      fill: none;
    }
  }
  > a {
    text-decoration: none;
    color: var(--colour-buttons-text);
  }
  svg {
    @media (max-width: $breakpoint-mobile) {
      // display: none;
    }
  }
}
button:hover,
.button:hover {
  filter: brightness(1.2);
}
.button__alt {
  background-color: var(--colour-buttons-alt);
  color: var(--colour-buttons-alt-text);
}
.button__condensed {
  padding: 0.1rem;
}
.button__toolbar {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.25rem;
  justify-content: center;
  align-self: center;
}
// :root.theme-dark {
//   button:hover,
//   .button:hover {
//     filter: brightness(0.8);
//   }
// }
.button:focus {
  outline: 0.3rem solid var(--colour-secondary-faded);
}
.button__transparent {
  background-color: transparent;
  color: var(--colour-primary);
  border-radius: 0;
  font: inherit;
  > .icon * {
    fill: var(--colour-primary);
  }
  box-shadow: none !important;
}
.button__transparent:focus {
  outline: none;
}
.button__arrow {
  .icon {
    transform: translateX(0);
    transition: all 0.2s ease-out;
  }
}
.button__arrow:hover {
  .icon {
    transform: translateX(0.25rem);
  }
  .icon.icon__scale {
    transform: scale(1.1);
  }
}
.button__arrow-back {
  @media (max-width: $breakpoint-mobile) {
    display: none;
  }
  .icon {
    transform: translateX(0) scaleX(-1);
    transition: all 0.2s ease-out;
  }
}
.button__arrow-back:hover {
  .icon {
    transform: translateX(-0.25rem) scaleX(-1);
  }
}
.button__invert {
  background-color: var(--colour-content-background);
  border: 2px solid var(--colour-buttons);
  color: var(--colour-buttons);
  > .icon,
  > .icon > svg {
    * {
      fill: var(--colour-buttons);
    }
  }
  > .svg__no-fill {
    * {
      fill: none;
    }
  }
}
.button__action-button {
  padding: 0.25rem;
}
.button__right {
  margin-left: auto;
}
.button:disabled,
.button:disabled:hover,
.button__disabled,
.button__disabled:hover {
  pointer-events: none;
  filter: grayscale(1) !important;
  opacity: (0.5);
  cursor: default;
  svg {
    opacity: 0.5;
  }
}
a.link-button,
a.link-button > button {
  text-decoration: none !important;
}
.button__fixed-small {
  width: 7rem;
  justify-content: center;
}
.button__fixed-med {
  width: 10rem;
  justify-content: center;
}

.tickbox {
  cursor: pointer;
  color: var(--colour-links);
  transition: filter 0.2s ease-out;
  input {
    display: none !important;
  }
  svg {
    path {
      transform-origin: 10px 16px;
      d: path("M6,13l0,0l0,0");
      opacity: 0;
    }
    rect {
      fill: transparent;
      transition: all 0.2s ease-out;
    }
  }
  > input:checked + svg {
    path {
      opacity: 1;
      animation: tickbox 0.2s ease-out forwards;
      stroke: #fff;
    }
    rect {
      fill: var(--colour-links);
    }
  }
}

.tickbox[data-disabled="true"] {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.select {
  z-index: 10;
  .select__control {
    border-color: var(--colour-borders);
    background: var(--colour-content-background);
    border-radius: var(--border-radius-small);
  }
  .select__control--is-focused {
    border-color: var(--colour-primary-against-white);
    box-shadow: 0 0 0 1px var(--colour-primary-against-white);
  }
  .select__control:hover {
    border-color: var(--colour-primary);
  }
  .select__single-value {
    color: var(--colour-content-text);
    margin-left: 0;
  }
}
.select__menu-portal {
  margin-top: -6px;
  .select__option.test {
    font-size: 5rem;
    outline: 4px solid pink;
  }
  .select__option:hover {
    background-color: var(--colour-primary-against-white);
    color: #fff;
  }
  .select__option--is-focused {
    background-color: var(--colour-input-background);
    color: var(--colour-content-text);
  }
  .select__option--is-selected {
    background-color: var(--colour-secondary-against-white);
    color: #fff;
  }
  .select-placeholder {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
.select-preview-option {
  display: inline-block;
  background: rgba(0, 0, 0, 0.1);
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
  margin-right: 0.25rem;
  white-space: nowrap;
  border-radius: 0.15rem;
}

:root.theme-dark {
  .select {
    .select__control--is-focused {
      border-color: var(--colour-primary-against-black);
      box-shadow: 0 0 0 1px var(--colour-primary-against-black);
    }
  }
  .select-preview-option {
    background: rgba(255, 255, 255, 0.1);
  }
  .select__menu-portal {
    * {
      background: #000;
    }
  }
}

.tickbox:hover {
  filter: brightness(1.2);
}

.radio {
  cursor: pointer;
  color: var(--colour-links);
  input {
    display: none;
  }
  svg {
    circle.radio-button {
      transform-origin: 12px 12px;
      transform: scale(0);
    }
  }
  > input:checked + svg {
    circle.radio-button {
      transform: scale(1);
      animation: scale-overshoot 0.4s ease-out forwards;
    }
  }
}
.radio[data-disabled="true"] {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
.radio:hover {
  filter: brightness(1.2);
}

textarea {
  width: 100%;
  color: var(--colour-input);
  background-color: transparent;
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-small);
  min-height: 9rem;
  padding: 1rem;
  resize: vertical;
  font: inherit;
}
textarea:focus {
  outline: 0.2rem solid var(--colour-primary-faded);
  border-color: transparent;
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--padding);
  position: relative;

  .form-modal-message {
    position: absolute;
    top: 5rem;
    left: 50%;
    width: 50%;
    transform: translate(-50%, 0);
    padding: var(--padding);
    background-color: var(--colour-primary-against-white);
    color: var(--colour-primary-against-white-text);
    border-radius: var(--border-radius-inner);
    z-index: 10;
    font-size: 1.2rem;
    text-align: center;
    animation: fade-in 0.4s var(--easing) forwards;
  }

  .form-section {
    background-color: var(--colour-content-background);
    color: var(--colour-content-text);
    padding: var(--padding);
    position: relative;
    border: 1px solid var(--colour-borders);
    border-radius: var(--border-radius-outer);
    display: flex;
    flex-direction: column;
    gap: var(--padding);
    .form-input-flex {
      display: flex;
      justify-content: stretch;
      align-items: flex-start;
      gap: var(--padding);
      > * {
        flex-grow: 1;
      }
      .form-input-suffix {
        align-self: flex-end;
        font-style: italic;
      }
    }
    .form-input-flex__tight {
      justify-content: flex-start;
      flex-wrap: wrap;
      row-gap: 1rem;
      > * {
        flex-grow: 0;
        flex-shrink: 1;
        margin-right: var(--padding);
      }
    }
    .form-input-flex__tighter {
      gap: 0;
    }
    label.form-input-flex__tight {
      > * {
        margin-right: 0;
      }
    }
    .form-input-flex:last-child {
      input {
        margin-bottom: 0.2rem;
      }
    }
    .form-input-flex-pair {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      > * {
        min-width: auto;
      }
    }
    > h3:first-child {
      margin-top: 0;
    }
    .edit-icons {
      padding: 0.5rem;
      border-radius: var(--border-radius-small);
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      opacity: 0;
      transition: opacity 0.2s ease-out;
    }
  }

  .form-section[data-is-section="true"] {
    border-color: transparent;
    background-color: transparent;
    padding: 0 var(--padding);
  }

  .form-section:hover .edit-icons {
    opacity: 1;
    transition: none;
  }
  .form-item {
    display: flex;
    flex-direction: column;
    gap: var(--padding-tight);

    .form-label-and-caption-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.25rem;
      flex-grow: 1;
      .form-caption {
        line-height: 1;
        font-size: 0.9rem;
        flex-shrink: 1;
      }
    }
    .form-label-container {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      align-items: flex-start;
      .title-action-buttons {
        flex-shrink: 1;
      }
    }
    label {
      font-size: 1.2rem;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      .label--required-marker {
        width: 1rem;
        height: 1rem;
        margin-top: -0.15rem;
        opacity: 0.5;
      }
    }
    label.label--edit {
    }
    .notice {
      margin-bottom: 0;
    }

    input[type="text"],
    input[type="password"] {
      width: 100%;
      background-color: transparent;
      border-bottom: 1px solid var(--colour-borders);
      padding: 0 0 0.5rem 0;
      transition: border-color 0.1s ease-out;
      margin-bottom: 0.2rem;
    }
    input[type="text"]:focus,
    input[type="password"]:focus {
      outline: none;
      border-bottom: 0.2rem solid var(--colour-primary-faded);
      margin-bottom: 1px;
    }

    input[type="date"],
    input[type="number"] {
      width: 100%;
      background-color: transparent;
      border: 1px solid var(--colour-borders);
      border-radius: var(--border-radius-small);
      font: inherit;
    }
    .input-number-prefix-container {
      position: relative;
    }
    .input-number-prefix-container::before {
      content: "£";
      position: absolute;
      z-index: 2;
      top: 0.8rem;
      left: 1rem;
      font-weight: bold;
    }
    .input-number-prefix-container input[type="number"] {
      padding-left: 2rem;
    }
    input[type="date"]:focus,
    input[type="number"]:focus {
      outline: 0.2rem solid var(--colour-primary-faded);
      border-color: transparent;
    }

    .input-colour-picker {
      display: inline-block;
      border-radius: var(--border-radius-inner);
      position: relative;
      width: 5rem;
      height: 5rem;
      left: 1px;
      cursor: pointer;
      outline: 1px solid var(--colour-borders);
      transition: all 0.2s ease-out;
      .input-colour-picker--swatch {
        content: "s";
        position: absolute;
        width: 5rem;
        height: 5rem;
        border: 0.5rem solid var(--colour-content-background);
        left: 0;
        top: 0;
        border-radius: var(--border-radius-inner);
        cursor: pointer;
        filter: none;
        transition: all 0.2s ease-out;
      }
      .input-colour-picker--swatch:hover {
        filter: brightness(1.2);
      }
      .input-colour-picker--picker {
        position: absolute;
        left: 50%;
        top: 100%;
        z-index: 50;
        box-shadow: var(--shadow);
        transform: translateX(-50%);
        cursor: default;
      }
    }
    .input-colour-picker.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    .input-image-drop {
      border: 2px dashed var(--colour-borders);
      max-width: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      color: var(--colour-links);
      transition: all 0.2s ease-out;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
      * {
        pointer-events: none;
      }
    }
    .input-image-drop:hover > * {
      filter: brightness(1.2);
    }
    .input-image-drop__focused {
      border-color: var(--colour-primary-against-white);
    }
    .input-image-drop__accepted {
      border-color: var(--colour-accepted);
      background-color: var(--colour-accepted-faded);
    }
    .input-image-drop__rejected {
      border-color: var(--colour-rejected);
      color: var(--colour-rejected);
    }

    div.input-colour-picker__small {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  .form-item[data-pending-approval="true"] {
    background-color: rgba(255, 0, 0, 0.2);
    outline: 2px dashed red;
  }
  .form-buttons {
    display: flex;
    justify-content: center;
    column-gap: var(--padding);
    align-items: center;
    button.button {
      min-width: 12rem;
      justify-content: center;
      font-size: 1.1rem;
    }
  }
}
.form:not(.drawer .form) {
  h2 {
    font-weight: bold;
    margin: 0;
  }
  h3 {
    font-weight: bold;
    margin: 0;
  }
}

.filter-form {
  input[type="text"],
  input[type="number"],
  input[type="date"] {
    background-color: var(--colour-content-background);
    border: 1px solid var(--colour-borders);
    border-radius: var(--border-radius-small);
    font: inherit;
    padding: 0.5rem;
  }
}

.form-user-error-tip {
  background-color: var(--colour-rejected);
  color: #fff;
  padding: var(--padding-tight);
  border-radius: var(--border-radius-small);
  animation: pulsate-fwd 0.5s ease-in-out 1 both;
}
.icon-picker-container {
  display: block;
  border-radius: var(--border-radius-inner);
  outline: 1px solid var(--colour-borders);
  width: min-content;
}
.icon-picker {
  width: 20rem;
  height: 20rem;
  background-color: var(--colour-content-background);
  overflow: auto;
  position: absolute;
  margin-top: 0.5rem;
  z-index: 10;
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-small);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  animation: dropdown-overshoot 0.4s var(--easing) forwards;
  .icon-picker--title {
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
  .icon-picker--title:first-child {
    margin-top: 0;
  }
  .icon-picker--grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    .icon-picker--grid-item {
      color: var(--colour-content-text);
      aspect-ratio: 1 / 1;
      border: 1px solid var(--colour-borders);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      outline: 0.25rem solid transparent;
      transition: outline 0.2s ease-out;
      > * {
        width: 32px;
        height: 32px;
      }
    }
    .icon-picker--grid-item:hover {
      outline: 0.25rem solid var(--colour-buttons);
    }
  }
  .icon-picker--grid:last-child {
    margin-bottom: 0;
  }
}

.item-icon {
  color: inherit !important;
  * {
    fill: none !important;
    stroke: currentColor !important;
  }
}
.item-icon__fill-stroke {
  * {
    fill: currentColor !important;
    stroke: none !important;
  }
}

.richtext-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--padding);
}
.drawer--content .richtext-container {
  padding-top: 0;
}
.richtext {
  width: 100%;
  flex-grow: 1;

  .ProseMirror {
    outline: 0;
    min-height: 20rem;
  }

  img {
    max-width: calc(100% - 8px);
    margin: 1rem auto !important;
    height: auto !important;
    display: block;
  }
  img.ProseMirror-selectednode {
    outline: 2px dotted var(--colour-primary);
    outline-offset: 2px;
    opacity: 0.9;
    background-color: var(--colour-secondary);
  }
}
.richtext-toolbar {
  position: sticky;
  top: 0;
  margin: 0;
  width: 100%;
  z-index: 5;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.5rem;
  padding-top: var(--padding-tight);
  padding-bottom: var(--padding-tight);
  margin-bottom: var(--padding-tight);
  // border-bottom: 1px solid var(--colour-borders);
  background-color: var(--colour-content-background);
  // removed animation as it glitches when re-rendering
  // animation: dropdown-overshoot 0.4s var(--easing) forwards;
  > * {
    flex-shrink: 1;
  }
  > :nth-last-child(2) {
    margin-left: auto;
  }
}
.drawer--content .richtext-toolbar {
  top: -2rem;
  padding-bottom: 0;
  margin-bottom: 0;
  padding-top: var(--padding-tight);
  > :nth-last-child(2) {
    margin-left: 0;
  }
}

.absence-type-picker {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  gap: var(--padding-tight);
  > label:nth-child(1) {
    background-color: var(--colour-absence-holiday);
  }
  > label:nth-child(2) {
    background-color: var(--colour-absence-sickness);
  }
  > label:nth-child(3) {
    background-color: var(--colour-absence-family);
  }
  > label:nth-child(4) {
    background-color: var(--colour-absence-other);
  }
  > label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    flex-basis: 25%;
    padding: 0.5rem;
    font-size: 1rem !important;
    transition: filter 0.2s ease-out;
    border-radius: var(--border-radius-small);
    svg > *:first-child {
      fill: #fff;
    }
  }
  label:hover {
    filter: brightness(1.05);
  }
}
.form-section.form-section__click-to-edit {
  gap: calc(var(--padding) - 1rem);
  padding: 0;
  .form--click-to-edit {
    padding: 0.5rem calc(var(--padding) - 0.5rem);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .form-item:first-child {
    margin-top: calc(var(--padding) - 0.5rem);
  }
  .form-item:last-child {
    margin-bottom: calc(var(--padding) - 0.5rem);
  }
  > .form-item > h2 {
    padding: 0.5rem var(--padding);
    margin: 0;
  }
}
.form--click-to-edit {
  display: flex;
  justify-content: stretch;
  column-gap: var(--padding);
  align-items: stretch;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  > .form-label-container {
    flex-grow: 1;
    flex-basis: 50%;
    > label {
      width: 100%;
    }
  }
  .form--click-to-edit-input {
    flex-basis: 50%;
    width: 50%;
    border-bottom: 1px dotted var(--colour-borders);
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-bottom: 0.25rem;
    margin-bottom: -0.25rem;
    flex-wrap: wrap;
    row-gap: 0.25rem;
  }
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    margin-bottom: var(--padding);
    > .form--click-to-edit-input {
      flex-basis: 100%;
      width: 100%;
      margin-top: var(--padding);
      justify-content: flex-start;
    }
  }
}

.form--click-to-edit__disabled {
  cursor: default;
  .form--click-to-edit-input {
    border-bottom: 1px solid var(--colour-borders);
  }
}
.form-item:hover {
  .form--click-to-edit {
    background-color: var(--colour-input-background);
    border-radius: var(--border-radius-small);
  }
  .form--click-to-edit__disabled {
    background-color: transparent;
  }
}
.form--field-currently-editing {
  border: 0.2rem solid var(--colour-secondary);
  padding: var(--padding);
  border-radius: var(--border-radius-small);
  animation: form-edit-input 0.2s var(--easing) forwards;
}
.form-submit-container {
  margin-top: var(--padding);
  display: flex;
  align-items: center;
  justify-content: center;
  .button {
    font-size: 1.2rem;
  }
}
