@keyframes slide-top {
  0% {
    transform: translateY(10rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes float {
  100% {
    transform: translateY(0.5rem);
  }
}

@keyframes bounce-top {
  0% {
    transform: translateY(-45px);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    transform: translateY(-24px);
    animation-timing-function: ease-in;
  }
  65% {
    transform: translateY(-12px);
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateY(-6px);
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(-4px);
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes bounce-right-squash {
  0% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
  10% {
    transform: translateX(150%) scaleY(100%);
    animation-timing-function: ease-in;
  }
  40% {
    transform: translateX(75%) scaleY(20%);
    animation-timing-function: ease-in;
    clip-path: polygon(0 0, 100% 50%, 0 100%);
  }
  65% {
    transform: translateX(25%) scaleY(20%);
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateX(12%) scaleY(20%);
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateX(8%) scaleY(20%);
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87%,
  98% {
    transform: translateX(0px) scaleY(20%);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateX(0px) scaleY(20%);
    animation-timing-function: ease-out;
    clip-path: polygon(0 0, 100% 50%, 0 100%);
  }
}

@keyframes restore-scale-y {
  0% {
    transform: scaleY(20%);
  }
  100% {
    transform: scaleY(100%);
  }
}

@keyframes heading-line {
  0% {
    opacity: 0;
    width: 0%;
    animation-timing-function: ease-in-out;
    min-width: 0;
  }
  10% {
    opacity: 1;
    width: 100%;
  }
  30% {
    width: 0%;
  }
  100% {
    width: 3rem;
  }
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(1.3);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}
@keyframes wobble-hor-top {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateX(-0.3rem) rotate(6deg);
  }
  30% {
    transform: translateX(0.15rem) rotate(-6deg);
  }
  45% {
    transform: translateX(-0.3rem) rotate(6deg);
  }
  60% {
    transform: translateX(0.15rem) rotate(-6deg);
  }
  75% {
    transform: translateX(-0.1rem) rotate(1.2deg);
  }
}
@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes skeleton-shine {
  0% {
    background-repeat: no-repeat;
    background-position: -5rem;
    background-position: -10rem 0;
    background-image: linear-gradient(90deg, transparent 0px, rgba(255, 255, 255, 0.4) 15rem, transparent 30rem);
  }
  50% {
    background-position: 100vw 0;
  }
  51% {
    background-image: none;
  }
}

@keyframes skeleton-shine-small {
  0% {
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, transparent 0px, rgba(255, 255, 255, 0.5) 1rem, transparent 3rem);
    background-position: -4rem 0;
  }
  50% {
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, transparent 0px, rgba(255, 255, 255, 0.5) 1rem, transparent 3rem);
    background-position: calc(100% + 5rem) 0;
  }
  100% {
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, transparent 0px, rgba(255, 255, 255, 0.5) 1rem, transparent 3rem);
    background-position: calc(100% + 5rem) 0;
  }
}

@keyframes scale-overshoot {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes side-menu-dropdown {
  0% {
    transform: scaleY(0);
    transform-origin: 0 0;
    animation-timing-function: var(--throbber-easing);
    padding-top: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0 0;
  }
}

@keyframes init-overflow {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: auto;
  }
}

@keyframes side-menu-slide-in {
  0% {
    transform: translateX(-100%);
    animation-timing-function: var(--easing);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes header-slide-down {
  0% {
    transform: translateY(-100%);
    animation-timing-function: var(--easing);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes dropdown-overshoot {
  0% {
    transform: scaleY(0);
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  60% {
    transform: scaleY(1.2);
    animation-timing-function: var(--throbber-easing);
    opacity: 1;
  }
  100% {
    transform-origin: 0 0;
    transform: scaleY(1);
  }
}

@keyframes tickbox {
  0% {
    d: path("M6,13l0,0l0,0");
    transform: scale(0);
  }
  50% {
    d: path("M6,13l5,4l0,0");
    transform: scale(1.2);
  }
  100% {
    d: path("M6,13l5,4L18,7");
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-shrink {
  0% {
    opacity: 0;
    transform: scale(1.05);
    animation-timing-function: var(--throbber-easing);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes up-into-place {
  0% {
    transform: translateY(20rem);
    animation-timing-function: var(--throbber-easing);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes down-into-place {
  0% {
    transform: translateY(-20rem);
    animation-timing-function: var(--throbber-easing);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes scale-in-center {
  0% {
    transform: scale(0);
    animation-timing-function: var(--throbber-easing);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes text-slide-in {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    filter: blur(1);
    opacity: 0;
    animation-timing-function: var(--throbber-easing);
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
    filter: 0;
  }
}

@keyframes form-edit-input {
  0% {
    transform: scale(0);
    transform-origin: 100% 0%;
    animation-timing-function: var(--easing);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulsate-fwd {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
