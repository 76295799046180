#print-container {
  display: none;
}
.print-only {
  display: none;
}

@media print {
  body.print-mode {
    height: fit-content;
    #root {
      display: none !important;
      * {
        display: none !important;
      }
    }
  }
  #print-container {
    * {
      color: #000;
    }
    display: block;
    width: 100%;
    height: fit-content;
    padding: 0;
    h1 {
      font-size: 1.7rem;
      margin: 0.5cm 0 0.5cm 0;
      padding: 0;
      border: 0;
      color: #000;
      text-align: center;
    }
    h1::after,
    h1::before {
      display: none;
    }
    .print-only {
      display: block;
    }
  }
}
