table {
  max-width: 100% !important;
  border-collapse: collapse;
  td,
  th {
    padding: var(--padding-tight);
    *:first-child {
      margin-top: 0;
    }
  }
}
table.table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  thead {
    th {
      padding: var(--padding-tight);
      border: 1px solid var(--colour-borders);
      text-align: start;
      font-size: 1.1rem;
    }
    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
  }

  tbody {
    tr:has(> :nth-child(3)) {
      @media (max-width: $breakpoint-mobile) {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        td:empty {
          display: none;
        }
      }
    }

    tr:hover {
      > td {
        background-color: transparent;
        .action-icons,
        .toggle-switch {
          opacity: 1;
        }
      }
    }
    tr.disabled {
      > td:first-child {
        font-style: italic;
      }
      > td:first-child::before {
        transform: scale(1);
      }
    }
    tr.table-row__alignTop {
      td {
        vertical-align: top;
      }
    }
  }

  td {
    position: relative;
    padding: var(--padding-tight);
    border: 1px solid var(--colour-borders);
    background-clip: padding-box;
    text-align: start;
    color: var(--colour-content-text);
    background-color: var(--colour-content-background);
    .action-icons,
    .toggle-switch {
      opacity: 0.5;
    }
    .notice {
      margin-bottom: 0;
      margin-top: 1rem;
      padding: 1rem;
      font-size: 1rem;
      gap: 1rem;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .table-select-item-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      transition: column-gap 0.2s var(--easing);
      > *:first-child {
        transition: width 0.2s var(--easing);
      }
      i {
        font-size: 0.85rem;
      }
    }
  }
  td:first-child::before {
    content: "";
    position: absolute;
    top: 0;
    left: -2px;
    width: 1px;
    height: 100%;
    border-right: 0.5rem solid var(--colour-rejected);
    transform: scale(0);
    transition: all 0.2s var(--easing);
  }
  td.compact {
    width: 1rem;
    white-space: nowrap;
    text-align: end;
  }
}
table.table__2-col-split {
  td {
    width: 50%;
  }
}
.table-list {
  list-style-type: square;
  list-style-position: inside;
  margin: 0;
  padding: 0;
  li {
    position: relative;
    display: block;
    padding-left: 1rem;
    margin-bottom: 0.5rem;
  }
  li:last-child {
    margin-bottom: 0;
  }

  li:before {
    content: "\25AA";
    position: absolute;
    left: 0;
  }
}

.select-list-items-header {
  padding: 0 calc(var(--padding-tight) + 1px) var(--padding-tight) calc(var(--padding-tight) + 1px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .select-list-items-header--selections {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 1rem;
  }
}

.select-list-items-filters {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: var(--padding);
  > label {
    align-self: center;
  }
  > *:last-child {
    margin-left: auto;
    width: fit-content;
    min-width: 300px;
  }
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    gap: var(--padding-tight);
    > *:last-child {
      margin-left: 0;
      width: 100%;
    }
  }
  .select-list-items-filters--select {
    .select {
      flex-grow: 1;
    }
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    max-width: 100% !important;
    overflow: hidden;
    td,
    th {
      min-width: 1em;
      border: 2px dashed var(--colour-borders);
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
      padding: 0.5rem;
      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      background-color: var(--colour-secondary-against-white);
      color: var(--colour-secondary-against-white-text);
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: var(--colour-primary-faded-more);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background: var(--colour-primary);
      pointer-events: none;
    }
  }
}
table.content-table,
.tablestyle_table {
  margin-top: 1rem;
  margin-bottom: 1rem;
  td,
  th {
    padding: 0.5rem;
    text-align: left;
  }
  th {
    font-weight: bold;
    background-color: var(--colour-secondary-against-white);
    color: var(--colour-secondary-against-white-text);
  }
}
table.content-table__grid {
  td,
  th {
    border: 1px solid var(--colour-borders);
  }
}
table.content-table__styled {
  box-shadow: var(--shadow);
  tr:nth-child(even) td {
    background: var(--colour-table-row-even);
  }
  tr:nth-child(odd) td {
    background: var(--colour-table-row-odd);
  }
}

table.content-table__full-width {
  width: 100% !important;
  max-width: 100%;
}
table.content-table__centred {
  margin-left: auto;
  margin-right: auto;
}

.tableWrapper {
  overflow-x: auto;
  max-width: 100%;
}

.table-items-list tr {
  @media (max-width: $breakpoint-mobile) {
    display: grid;
    grid-template-columns: 100fr 1fr;
    grid-template-areas: "content content" "actions toggle";
    align-items: stretch;
    justify-items: stretch;
    align-content: center;
    margin-bottom: var(--padding);
    > td {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
    > td:first-child {
      grid-area: content;
      border-bottom: 0;
    }
    > td[data-content="actions"] {
      grid-area: actions;
      width: auto;
      border-top: 0;
      border-right: 0;
      width: 100%;
      padding-top: 0;
      align-self: start;
    }
    > td[data-content="actions"]:last-child {
      border-right: 1px solid var(--colour-borders);
      grid-column-end: 5;
    }
    > td[data-content="toggle"] {
      grid-area: toggle;
      border-top: 0;
      border-left: 0;
      width: 100%;
    }
  }
  i {
    font-size: 0.85rem;
  }
}
