:root.theme-dark {
  .header-top--logo {
    filter: invert(1) grayscale(1) opacity(0.5) drop-shadow(0 0 0 var(--colour-primary-against-black)) saturate(400%);
  }
}

body.app {
  header {
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    .navigation-bar-top {
      column-gap: var(--padding-tight);
      grid-template-columns: 1fr;
      padding: var(--padding-tight);
      .navigation-bar-top--items {
        align-items: center;
        justify-content: flex-start;
        .header-top--logo-mobile {
          margin-left: 0;
        }
        .navigation-bar-top--menu {
          gap: 0;
          span {
            display: none;
          }
          > * {
            padding: 0;
            border-right: 0;
          }
          > *:first-child {
            padding-left: 0;
          }
          button {
            width: 2rem;
            height: 2rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .navigation-bar-top--menu * > .svg,
      .navigation-bar-top--menu * > svg {
        max-width: 1.5rem !important;
        max-height: 1.5rem !important;
        width: 1.5rem !important;
        height: 1.5rem !important;
      }
    }

    .navigation-bar-top--home,
    .navigation-bar-top--home-container {
      display: none;
    }
    .navigation-bar-top--toggle-dark.toggle-switch,
    .navigation-bar-top--toggle-contrast.toggle-switch {
      display: none !important;
    }

    .navigation-bar--app-title {
      background-color: var(--colour-buttons);
      color: var(--colour-buttons-text) !important;
      padding: var(--padding-tight);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.1rem;
      font-weight: bold;
      line-height: 1;
      border-top: 2px solid #fff;
    }
  }
}

header {
  grid-area: header;
  background-color: var(--colour-content-background);
  display: grid;
  grid-template-areas: "header-items" "nav-bar";
  // animation: header-slide-down 0.4s var(--easing) forwards;
  position: relative;
  z-index: 20;

  .navigation-bar--app-title {
    display: none;
  }
  .header-top {
    padding: var(--padding-tight) var(--padding);
    display: flex;
    justify-content: stretch;
    align-items: center;
    grid-area: header-items;
    z-index: 99999;
    .header-top--logo {
      flex-grow: 1;
      display: flex;
      align-items: center;
      img {
        width: calc(14rem + 1vw);
        max-width: 300px;
        max-height: 100px;
        object-fit: contain;
        object-position: center center;
        height: auto;
        display: block;
      }
    }
    .header-top--items {
      flex-shrink: 1;
      display: flex;
      align-items: stretch;
      max-height: 70px;
      column-gap: 1.25rem;
      padding: 0.4rem 0;
      * {
        min-width: 0;
      }
      .header-top--profile-menu-container {
        position: relative;
        display: flex;
        align-items: stretch;
      }
      .header-top--profile-btn {
        color: var(--colour-header-primary);
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        border-radius: var(--border-radius-small);
        font-size: 0.85rem;
        font-weight: bold;
        width: fit-content;
        .header-top--profile-photo {
          width: 3rem;
          height: 3rem;
          object-fit: cover;
          border-radius: 50%;
          background-color: var(--colour-primary-faded-more);
          * {
            fill: var(--colour-header-primary);
          }
        }
        .header-top--profile-photo__svg {
          padding: 0.75rem;
          width: 3rem;
          height: 3rem;
        }
        > .svg > * {
          fill: var(--colour-header-primary);
        }
        > .icon {
          transition: all 0.2s ease-out;
        }
      }
      .header-top--profile-btn.active {
        background-color: var(--colour-buttons);
        color: var(--colour-buttons-text);
        box-shadow: var(--shadow);

        .header-top--profile-photo {
          background-color: var(--colour-secondary-faded-more);
          * {
            fill: var(--colour-buttons-text);
          }
        }
        > .svg > * {
          fill: var(--colour-buttons-text);
        }
        > .icon {
          transform: scaleY(-1);
        }
      }
      .header-top--profile-menu__admin {
        padding: var(--padding-tight);
        min-width: 0;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        .header-top--accounts-list {
          max-height: 50vh;
          overflow: auto;
          a,
          button.button-link {
            display: block;
            color: var(--colour-primary-faded-text);
            text-decoration: none;
            padding: 0.3rem 1rem;
            width: 100%;
          }
          a:hover,
          button.button-link:hover {
            background-color: var(--colour-buttons);
            color: var(--colour-buttons-text);
          }
          hr {
            border-top: 1px solid var(--colour-primary-faded-text);
          }
        }
      }
      .header-top--signout-btn {
        background: var(--colour-header-secondary);
        color: var(--colour-header-secondary-text);
        box-shadow: var(--shadow);
        > .svg > * {
          fill: var(--colour-header-secondary-text);
        }
      }
    }
    @media (max-width: $breakpoint-medium),
      (min-width: $breakpoint-tablet) and (max-height: $breakpoint-shallow-height-normal) {
      padding: 0.5rem var(--padding);
    }
    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      max-width: 300px;
      position: fixed;
      z-index: 10;
      background: var(--colour-secondary);
      height: 100%;
      top: 0;
      right: 0;
      padding: var(--padding);
      display: none;
    }
  }
  .navigation-bar-top {
    padding: 0.5rem var(--padding) 0.5rem 0;
    background-color: var(--colour-primary);
    display: grid;
    grid-template-columns: 1fr 4.7fr;
    grid-area: nav-bar;
    @media (max-width: $breakpoint-medium) {
      grid-template-columns: min-content auto;
    }

    .navigation-bar-top--home-container {
      padding-left: var(--padding);
      min-width: 17.5438vw; // 17rem;
      height: 100%;
      .navigation-bar-top--home {
        background-color: var(--colour-home-button-background);
        border: 1px solid var(--colour-header-input-borders);
        color: var(--colour-home-button-text);
        box-shadow: var(--shadow);
        text-transform: uppercase;
        column-gap: 1rem;
        height: 100%;
        position: relative;
        overflow: hidden;
        span {
          margin-top: 0.2rem;
        }
        .svg * {
          transition: all 0.2s ease-out;
          fill: var(--colour-home-button-text);
        }
        .svg {
          @media (max-width: $breakpoint-medium) {
            min-width: 1.5rem;
          }
        }
        @media (max-width: $breakpoint-medium) {
          padding-left: 1rem;
          padding-right: 1rem;
          > span {
            display: none;
          }
        }
        @media (max-width: $breakpoint-mobile) {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          display: none;
        }
      }
      .navigation-bar-top--home:hover {
        color: var(--colour-home-button-background);
        .svg * {
          fill: var(--colour-home-button-background);
        }
        filter: brightness(1);
      }
      .navigation-bar-top--home::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        transition: all 0.2s linear;
        background-color: var(--colour-home-button-text);
        z-index: -1;
        opacity: 0;
      }
      .navigation-bar-top--home:hover::before {
        height: 100%;
        opacity: 1;
      }
      @media (max-width: $breakpoint-medium) {
        min-width: 0;
      }
      @media (max-width: $breakpoint-tablet) {
      }
    }
    .navigation-bar-top--items {
      display: flex;
      align-items: stretch;
      justify-content: flex-start; //space-between;
      gap: 1rem;
      .header-top--logo-mobile {
        background-color: var(--colour-content-background);
        border-radius: var(--border-radius-small);
        padding: 2px;
        flex-shrink: 1;
        align-self: stretch;
        margin-left: var(--padding);
        align-items: center;
        display: flex;

        @media (max-width: $breakpoint-mobile) {
          margin-left: 0;
        }
        > * {
          display: flex;
          align-items: center;
          height: 100%;
        }
        img {
          display: block;
          width: auto;
          height: 100%;
          width: 25vw;
          height: auto;
          aspect-ratio: 4 / 1;
          object-fit: contain;
          object-position: center center;
        }
      }
      .navigation-bar-top--menu {
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        margin-left: var(--padding-tight);
        padding: 0.25rem var(--padding) 0.25rem 0; // calc(var(--padding) + 2rem);
        transition: padding 0.2s ease-out;
        position: relative;
        @media (max-width: $breakpoint-medium) {
          padding-left: 0;
          padding-right: 0;
          > *:first-child {
            display: none;
          }
        }
        @media (max-width: $breakpoint-tablet) {
          margin-left: 0;
          flex-grow: 0;
          > * {
            flex-grow: 1;
          }
          > *:nth-child(2) {
            padding-left: 0;
          }
          * > .svg {
            width: 2rem;
          }
        }
        > * {
          white-space: nowrap;
          position: relative;
          border: 0;
          background: transparent;
          color: var(--colour-primary-text);
          text-decoration: none;
          border-right: 2px solid var(--colour-primary-text);
          display: flex;
          align-items: center;
          padding: 0 1rem;
          text-transform: uppercase;
          font-weight: bold;
          column-gap: 0.5rem;
          cursor: pointer;
          @media (max-width: $breakpoint-mobile) {
            flex-direction: column;
            justify-content: flex-end;
          }
        }
        > button::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 0.5rem;
          background-color: var(--colour-secondary);
          bottom: -1.25rem;
          left: 0;
          transform: scaleY(0);
          transform-origin: top center;
          transition: all 0.2s ease-out;
          @media (max-width: $breakpoint-tablet) {
            display: none;
          }
        }
        > button:hover {
          filter: none;
        }
        > button:hover::after {
          transform: scaleY(1);
        }
        > button.active::after {
          background-color: var(--colour-background);
          transform: scaleX(0.2) scaleY(1);
          bottom: calc(0rem - 0.75rem - 1px);
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        }
        > *:first-child {
          padding-left: 0;
          cursor: default;
          border-right: 0;
        }
        > *:last-child {
          border-right: 0;
        }
        > * > .svg {
          max-height: 1.5rem;
        }
        > * > .svg * {
          fill: var(--colour-primary-text);
        }
        > span {
          cursor: default;
        }
        span {
          margin-top: 0.2rem;
        }
      }
      .navigation-bar-top--help {
        background-color: transparent;
        color: var(--colour-help-button-text);
        border: 2px solid var(--colour-help-button-text);
        align-self: stretch;
        column-gap: 1rem;
        box-shadow: var(--shadow);
        position: relative;
        overflow: hidden;
        > .svg * {
          transition: all 0.2s ease-out;
          fill: var(--colour-help-button-text);
        }
        @media (max-width: $breakpoint-medium) {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          > svg {
            display: none;
          }
        }
        @media (max-width: $breakpoint-tablet) {
          display: none;
        }
      }
      .navigation-bar-top--help::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        transition: all 0.2s linear;
        background-color: var(--colour-home-button-background);
        z-index: -1;
        opacity: 0;
      }
      .navigation-bar-top--help:hover {
        color: var(--colour-home-button-text);
        filter: brightness(1);
        > .svg * {
          fill: var(--colour-home-button-text);
        }
      }
      .navigation-bar-top--help:hover::before {
        height: 100%;
        opacity: 1;
      }
      .navigation-bar-top--search-container {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        background-color: var(--colour-input-header-background);
        border: 1px solid var(--colour-header-input-borders);
        border-radius: var(--border-radius-small);
        width: calc(100px + 8vw);
        overflow: hidden;
        > .svg {
          max-height: none;
          max-width: 1.5rem;
          width: 100%;
          margin-left: 0.5rem;
        }
        > .svg * {
          fill: var(--colour-search-icon) !important;
        }
        > input {
          border: 0;
          padding: 0 0 0 1rem;
          background-color: transparent;
          width: 100%;
        }
        > input:focus {
          outline: transparent;
        }
        @media (max-width: $breakpoint-tablet) {
          display: none;
        }
      }

      .toggle-switch {
        filter: none;
        .toggle-switch--switch {
          background-color: var(--colour-input-header-background);
          border-color: #fff;
        }
        .toggle-switch--switch::before {
          background-color: var(--colour-secondary-against-white);
        }
        .toggle-switch.active {
          .toggle-switch--switch {
            background-color: var(--colour-input-background);
          }
          .toggle-switch--switch::before {
            background-color: var(--colour-secondary-against-white);
          }
        }
        .toggle-switch--label {
          color: var(--colour-primary-text);
        }
        .svg * {
          fill: var(--colour-primary-text) !important;
          stroke: var(--colour-primary-text) !important;
        }
        @media (max-width: $breakpoint-tablet) {
          display: none;
        }
      }
      .header-top--profile-menu {
        .toggle-switch {
          @media (max-width: $breakpoint-tablet) {
            display: flex;
          }
        }
      }
      .navigation-bar-top--mobile-btn {
        background: transparent;
        border: 0;
        padding: 0 0.5rem 0 0;
        cursor: pointer;
        display: none;
        > .svg {
          max-height: 2rem;
          * {
            fill: var(--colour-primary-text);
          }
        }
        @media (max-width: $breakpoint-tablet) {
          display: inline-block;
        }
      }
    }
    input {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .drawer-overlay {
      z-index: 999998;
    }
    .drawer-container {
      z-index: 999999;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    grid-template-areas: "nav-bar header-items";
    grid-template-columns: auto min-content;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: var(--shadow);
    .header-top {
      .header-top--logo,
      .header-top--profile-btn > *:nth-child(2),
      .header-top--profile-btn > *:nth-child(3),
      .header-top--signout-btn {
        display: none;
      }
    }
    .button {
      padding: 0.5rem;
    }
  }
}
header.header__admin {
  background: var(--colour-secondary);
  > .header-top .header-top--items .header-top--profile-btn {
    color: #fff;
    > .svg > * {
      fill: #fff;
    }
  }
  .navigation-bar-top {
    display: flex;
    justify-content: flex-end;
  }
}
:root.theme-high-contrast {
  .navigation-bar-top--home-container {
    @media (max-width: $breakpoint-large) {
      min-width: 0;
    }
  }
}
:root.theme-dark {
  .navigation-bar-top {
    .toggle-switch--switch {
      border: 1px solid #fff;
    }
    .toggle-switch--switch::before {
      background-color: var(--colour-secondary-against-black);
    }
    .toggle-switch.active {
      .toggle-switch--switch::before {
        background-color: var(--colour-secondary-against-black);
      }
    }
  }
}
